import React, { useState, useEffect } from 'react';

import jsCookie from 'js-cookie'

import Loader from './Loader';

import { getBitlyUrl } from '../client/utils/bitlyUrl';
import copyToClipboard from '../lib/copyToClipboard';
import { userActivity } from 'clientUtils';

async function copyLink(e) {
  e.preventDefault()
  const windowUrl = window.location.href;
  const bitlyUrl = await getBitlyUrl(windowUrl);
  copyToClipboard(null, bitlyUrl ? bitlyUrl : windowUrl);
}

async function emailShare(e) {
  e.preventDefault()
  let bodyLink = window.location.href;
  const bitlyUrl = await getBitlyUrl(bodyLink);

  if (bitlyUrl) {
    bodyLink = bitlyUrl;
  }

  const url = `mailto:?subject=Dapatkan%20Penawaran%20-%20Lifepal&body=${encodeURIComponent(
    `Silahkan kunjungi tautan berikut:\n\n${bodyLink}`
  )}`;

  window.open(url, '_blank');
}

function socialShareGroup(
  hasDownloadOption,
  userMode,
  handleDownload,
  isLoading,
  category,
  sendWAMessage,
  sendingTemplateMsg,
  agentData,
) {
  return (
    <ul className="mt-2 d-flex p-0 list-unstyled" style={{ fontSize: 20 }}>
      {
        userMode && (
          <>
            <li className="mr-3">
              <a href="/" onClick={emailShare} target="_blank" style={{ color: '#1d66dd' }}>
                <span className="icon ion-md-mail" />
              </a>
            </li>
            <li className="mr-3">
              {
                !sendingTemplateMsg ? (
                  <a
                    href="/"
                    style={{ color: '#25D366' }}
                    onClick={(event) => {
                      event.preventDefault();
                      userActivity.track(userActivity.constants.compareWaClick, {
                        'Insurance Category': category,
                        'Lead id': localStorage.getItem('queueId'),
                      });
                      sendWAMessage();
                    }}
                  >
                    <span className="icon ion-logo-whatsapp" />
                  </a>
                ) : (
                  <Loader color="primary" />
                )
              }
            </li>
          </>
        )
      }
      <li className="mr-3">
        <a
          href="/"
          style={{ color: '#1d66dd' }}
          onClick={(event) => {
            copyLink(event);
            userActivity.track(userActivity.constants.compareCopyLink, {
              'Insurance Category': category,
              'Lead id': localStorage.getItem('queueId'),
            });
          }}
        >
          <span className="icon ion-ios-copy" />
        </a>
      </li>
      {
        (
          hasDownloadOption
          && !userMode
        ) && (
          <li
            onClick={(event) => {
              event.persist();
              event.stopPropagation();
              handleDownload();

              userActivity.track(userActivity.constants.comparePdfDownload, {
                'Insurance Category': category,
                'Lead id': localStorage.getItem('queueId'),
              });
            }}
          >
            {
              !isLoading ? (
                <a href="#" style={{ color: '#1d66dd' }}>
                <img
                  src="/static/images/icons/download.svg"
                  style={{
                    height: "15px",
                    width: "25px"
                  }}
                  alt="download_icon"
                />
                </a>
              ) : (
                <Loader color="primary" />
              )
            }
          </li>
        )
      }
    </ul>
  )
}

const SocialShare = (props) => {
  const {
    hasDownloadOption,
    handleDownload,
    isLoading,
    category,
    sendWAMessage,
    sendingTemplateMsg,
    agentData,
  } = props;
  const [userMode, setUserMode] = useState(false);

  useEffect(() => {
    if (jsCookie.get('mode') === 'user' || !jsCookie.get('mode')) {
      setUserMode(true);
    }
  }, []);

  return (
    <div>
      <div style={{ bottom: 0 }} className="d-none d-lg-block position-absolute">
        {
          socialShareGroup(
            hasDownloadOption, userMode, handleDownload,
            isLoading, category, sendWAMessage,
            sendingTemplateMsg, agentData,
          )
        }
      </div>

      <div
        className="d-lg-none position-absolute"
        style={{ right: '1rem', top: '50%', transform: 'translateY(-50%)' }}
      >
        {
          socialShareGroup(
            hasDownloadOption, userMode, handleDownload,
            isLoading, category, sendWAMessage,
            sendingTemplateMsg, agentData,
          )
        }
      </div>
    </div>
  )
}
export default SocialShare
