import React from 'react'
import PropTypes from 'prop-types'
import 'cleave.js/dist/addons/cleave-phone.id'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import './LeadForm.sass'
import Quote from '../Quote'

class LeadForm extends React.PureComponent {
  render = () => {
    const { isModalOpen, toggle } = this.props

    return (
      <Modal isOpen={isModalOpen} toggle={toggle} className="USP--Modal">
        <ModalHeader toggle={toggle}>
          Lindungi Kendaraan Anda Sekarang!
        </ModalHeader>
        <ModalBody className="USP--Form">
          <Quote toggle={toggle} styles={{ boxShadow: 'none', padding: '0px' }} />
        </ModalBody>
      </Modal>
    )
  }
}

LeadForm.propTypes = {
  isModalOpen: PropTypes.bool,
  toggle: PropTypes.func
}

export default LeadForm
