import React from "react";
import { Avatar, Button } from "@lifepal/lifepal-design-system";
import './CallScheduledConsultation.sass';
import { HEADSET_ICON, MASCOT_CIRCLE_AVATAR_ICON } from "../../shared/constants";

const CallScheduledConsultation = (props) => {
  const { onClick, category } = props;
  const isAuto = ['auto'].includes(category);
  return (
    <div className="ScheduledConsultation__container">
      <div className="mid">
        <img className="icon" src={MASCOT_CIRCLE_AVATAR_ICON} alt="" />
        <span>{isAuto ? 'Mau voucher hingga 2,5 juta? Jadwalkan konsultasi!' : 'Dapatkan asuransi yang paling pas buat kamu!'}</span>
      </div>
      <Button color="primary" onClick={onClick} isBlock>
        <img alt="call me" loading="lazy" src={HEADSET_ICON} />
        <span>Jadwalkan Konsultasi</span>
      </Button>
    </div>
  );
};

export default CallScheduledConsultation;