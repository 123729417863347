import React from 'react'
import PropTypes from 'prop-types'
import { Accordion } from './../components'
import classnames from 'classnames'

class FAQ extends React.Component {
  state = {
    isOpen: false,
  }

  toggleDetails = () => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isOpen } = this.state;
    const { items, indexOpen, mainTitle, subTitle, itemType, itemScope } = this.props;

    const hasTitle = mainTitle || subTitle;
    return (
      <div>
        <div className={hasTitle && 'pt-3'}>
          <div className={classnames(hasTitle && 'p-0', 'container custom__width')}>
            <div className="row">
              {
                hasTitle && (
                  <div className="col-lg-12 col-12 mb-3 text-left" style={{ width: '100%' }}>
                    <h2 className="mb-1 faq__main--title" style={{ fontWeight: "500", color: "#333333" }}>{mainTitle}</h2>
                    <div className={classnames('slide', { 'slide-open': isOpen })} style={{ position: 'relative', overflow: 'hidden' }}>
                      <p style={{ lineHeight: "24px", color: "#5e5e5e", margin: "0px" }}>
                        {subTitle}
                      </p>
                    </div>
                  </div>
                )
              }
              <div
                className={classnames(
                  hasTitle && 'mb-4',
                  'col-lg-12 col-12 text-left'
                )}
                itemType={itemType}
                itemScope={itemScope}
                style={!hasTitle ? { paddingRight: 0, paddingLeft: 0 } : {}}
              >
                <Accordion open={indexOpen}>
                  {items && items.map((each, key) => (
                    <Accordion.Item key={key} schemaOrgQuestions={{
                      itemScope: true,
                      itemProp: 'mainEntity',
                      itemType: 'https://schema.org/Question'
                    }}>
                      <Accordion.Header schemaOrgProps={{ itemProp: 'name' }}>
                        {each.header}
                      </Accordion.Header>
                      <Accordion.Body schemaOrgAnswers={{
                        itemScope: true,
                        itemProp: 'acceptedAnswer',
                        itemType: 'https://schema.org/Answer'
                      }} schemaOrgProps={{ itemProp: 'text' }}>
                        {each.body}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .slide {
            -webkit-transition: max-height 0.3s ease-out;
            -o-transition: max-height 0.3s ease-out;
            transition: max-height 0.3s ease-out;
          }

          .slide > div {
            position: absolute;
            bottom: 0;
            height: 30px;
            width: 100%;
            background: linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,100));
          }
          
          .slide-open {
            max-height: 250px;
            -webkit-transition: max-height 0.3s ease-in;
            -o-transition: max-height 0.3s ease-in;
            transition: max-height 0.3s ease-in;
          }
        `}</style>
      </div>
    )
  }
}

FAQ.propTypes = {
  mainTitle: PropTypes.string,
  subTitle: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    header: PropTypes.string,
    body: PropTypes.any
  })),
  indexOpen: PropTypes.number,
  itemType: PropTypes.string,
  itemScope: PropTypes.bool
}

export default FAQ
