import React, { Component } from "react";
import { FormGroup, Alert } from "reactstrap";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "../Loader";
import { errorMessage } from "../../helper";
import Cleave from "cleave.js/react";
import { cleanPhoneNumber, isPhoneNumberValid } from "../../lib/phoneNumber";
import cookies from "js-cookie";
import { UserAuth } from "../../lib/api";
import "../../styles/pages/profile.scss";

const UserService = new UserAuth();

class FormProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPasswordType: "password",
      newPasswordType: "password",
    };
  }
  render() {
    const { touched, errors, isSubmitting, setFieldValue, status } = this.props;
    const setInputTypeOldPassword = () =>
      this.setState({
        oldPasswordType:
          this.state.oldPasswordType === "password" ? "text" : "password",
      });
    const setInputTypeNewPassword = () =>
      this.setState({
        newPasswordType:
          this.state.newPasswordType === "password" ? "text" : "password",
      });
    return (
      <div id="login-container" className="profile-form-container">
        <Form>
          <FormGroup>
            {errors.all ? (
              <Alert color="danger">{errors.all}</Alert>
            ) : status ? (
              <Alert color="success">Profil Anda berhasil di ubah.</Alert>
            ) : (
              <div />
            )}

            <FormGroup>
              <Field
                name="fullName"
                className="profile-input"
                required
                placeholder="Nama Anda"
              />
              {errorMessage(touched, errors, "fullName")}
            </FormGroup>

            <FormGroup>
              <Field name="phone">
                {({ field }) => (
                  <div className="input-phone-group">
                    <div className="input-phone-prepend">
                      <span className="input-phone-prepend">+62</span>
                    </div>
                    <Cleave
                      {...field}
                      options={{
                        delimiter: " ",
                        blocks: [3, 4, 4],
                        numericOnly: true,
                      }}
                      className="profile-input blue-auto-w"
                      placeholder="Nomor Telepon"
                      onChange={(e) => {
                        setFieldValue(
                          `phone`,
                          cleanPhoneNumber(e.target.value)
                        );
                      }}
                    />
                  </div>
                )}
              </Field>
              {errorMessage(touched, errors, "phone")}
            </FormGroup>

            <Field
              name="email"
              type="email"
              className="profile-input"
              placeholder="Email"
            />
            {errorMessage(touched, errors, "email")}
          </FormGroup>

          <FormGroup>
            <Field name="password1">
              {({ field }) => (
                <div className="form-password-wrapper">
                  <Cleave
                    {...field}
                    type={this.state.oldPasswordType}
                    placeholder="Password Lama"
                    className="profile-input blue"
                  />
                  <span className="form-show" onClick={setInputTypeOldPassword}>
                    {this.state.oldPasswordType === "password"
                      ? "Show"
                      : "Hide"}
                  </span>
                </div>
              )}
            </Field>
            {errorMessage(touched, errors, "password1")}
          </FormGroup>

          <FormGroup>
            <Field name="password2">
              {({ field }) => (
                <div className="form-password-wrapper">
                  <Cleave
                    {...field}
                    type={this.state.newPasswordType}
                    placeholder="Password Baru"
                    className="profile-input blue"
                  />
                  <span className="form-show" onClick={setInputTypeNewPassword}>
                    {this.state.newPasswordType === "password"
                      ? "Show"
                      : "Hide"}
                  </span>
                </div>
              )}
            </Field>
            {errorMessage(touched, errors, "password2")}
          </FormGroup>

          {errors.password &&
            errors.password.length > 0 &&
            errors.password.map((message) => (
              <Alert color="danger">{message}</Alert>
            ))}

          <button
            type="submit"
            className="form-profile-btn"
            disabled={isSubmitting}
          >
            {isSubmitting ? <Loader /> : "Ubah Profile"}
          </button>
        </Form>
      </div>
    );
  }
}

const EnhancedForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => {
    const { full_name, phone_number, email, password1, password2 } = props.user;

    return {
      email: email || "",
      fullName: full_name || "",
      phone: phone_number || "",
      password1: password1 || "",
      password2: password2 || "",
    };
  },
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Email not valid"),
    fullName: Yup.string().required("Wajib diisi"),
    phone: Yup.string()
      .required("Wajib diisi")
      .test("phone", "Nomor Telepon tidak valid.", (value = "") =>
        isPhoneNumberValid(value)
      ),
    password2: Yup.string().matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
      "Kata sandi minimal 8 karakter, menggunakan huruf besar, huruf kecil, angka, dan minimal 1 karakter simbol."
    ),
  }),
  handleSubmit: async (
    values,
    { setErrors, setValues, setSubmitting, setStatus, props }
  ) => {
    setSubmitting(true);
    setStatus("");

    // Check password
    if (values.password1 && values.password1 === values.password2) {
      setErrors({
        password: "Password lama tidak boleh sama dengan Password baru",
      });
      setSubmitting(false);
      return;
    }

    const token = cookies.get("token");
    const fullName = values.fullName.split(" ");
    let lastName = "";

    if (fullName.length > 1) {
      lastName = fullName.pop();
    }
    const firstName = fullName.join(" ");
    const changeUserInfo =
      firstName !== props.user.first_name ||
      lastName !== props.user.last_name ||
      values.phone !== props.user.phone_number ||
      values.email !== props.user.email;
    const changePassword = values.password1 && values.password2;

    try {
      // let a user change their password first before changing the profile
      if (changePassword) {
        await UserService.changePassword(
          {
            old_password: values.password1,
            new_password: values.password2,
            confirm_password: values.password2,
          },
          `Bearer ${token}`
        );
      }

      if (changeUserInfo) {
        await UserService.udpateUserInfo(
          {
            first_name: firstName,
            last_name: lastName,
            phone_number: values.phone,
            email: values.email,
          },
          `Bearer ${token}`,
          props.user.id
        );
      }

      setStatus("success");
      setSubmitting(false);
      values.password1 = "";
      values.password2 = "";
      setValues(values);
    } catch (error) {
      console.log(error);
      const errorResponse = error.response.data;

      if (errorResponse.new_password) {
        setErrors({ password: errorResponse.new_password });
      } else if (errorResponse.data === "old password not valid") {
        setErrors({ password: "Password lama tidak valid" });
      } else {
        setErrors({ all: "Nomor telepon sudah terdaftar." });
      }

      setSubmitting(false);
    }
  },
});

export default EnhancedForm(FormProfile);
