import React from 'react';
import Router from 'next/router';

import Cookie from 'js-cookie';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { format } from 'date-fns';

import { utils } from 'react-modern-calendar-datepicker';

import { Button, ModalBody } from 'reactstrap';

import { Dropdown, Label, Input } from '../../components/Form';
import { LPDatePicker } from '../../client/modules/search/components/DatePicker/DatePicker';

import { dayLib, scheduleLib } from '../../lib/scheduler';

import { DateUtil } from '../../client/utils/date';

import './CallBackForm.sass';

class CallBackForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      reasonScheduler: '',
      dayScheduler: props.initialDayScheduler || new Date(),
      timeScheduler: props.initialTimeScheduler ? props.initialTimeScheduler : '',
      scheduleReasons: [
        {
          label: 'Pelanggan tidak mengangkat telepon',
          value: 'Pelanggan tidak mengangkat telepon',
        },
        {
          label: 'Pelanggan ingin dihubungi kembali',
          value: 'Pelanggan ingin dihubungi kembali',
        },
        {
          label: 'Ingin membandingkan lebih banyak',
          value: 'Ingin membandingkan lebih banyak',
        },
        {
          label: 'Pelanggan tidak membawa KTP',
          value: 'Pelanggan tidak membawa KTP',
        }
      ],
      isRescheduled: false,
      notes: '',
      automaticSchedulingReasons: [
        'Nomor tidak valid',
        'Pelanggan tidak mengangkat telepon',
        'Pelanggan tidak membawa KTP',
        'Akan membayar nanti'
      ],
      autoRescheduleTime: {
        'Nomor tidak valid': 30,
        'Pelanggan tidak mengangkat telepon': 30,
        'Pelanggan tidak membawa KTP': 720,
        'Akan membayar nanti': 720,
      }
    }
  }

  componentDidMount = () => {
    if (this.props.isLeadManagement) {
      if (!this.props.useSimpleLeadStatus) {
        const invalidReason = [{
          label: 'Nomor tidak valid',
          value: 'Nomor tidak valid',
        }];
        this.setState({
          scheduleReasons: [...invalidReason, ...this.state.scheduleReasons]
        });
      }
      if (this.props.leadStatus === 'Sale Unpaid') {
        const specialReasons = [{
          label: 'Akan membayar nanti',
          value: 'Akan membayar nanti',
        }];

        this.setState({
          scheduleReasons: [...specialReasons, ...this.state.scheduleReasons]
        });
      }
    } else {
      const specialReasons = [{
        label: 'Akan membayar nanti',
        value: 'Akan membayar nanti',
      }];

      this.setState({
        scheduleReasons: [...this.state.scheduleReasons, ...specialReasons]
      });
    }
    setTimeout(() => {
      this.getCallbackReasonList();
      this.setState({ reasonScheduler: this.state.scheduleReasons[0].value });
    }, 1000);
  };

  getSelectedDate = () => {
    const { dayScheduler, timeScheduler } = this.state;

    let day = dayScheduler;
    if (typeof dayScheduler === 'string') {
      day = dayLib[dayScheduler];
    }

    return `${format(new Date(day), 'yyyy/MM/dd')} ${timeScheduler}`;
  }

  getSchedulePayload = () => {
    const { reasonScheduler } = this.state;

    const formattedDate = this.getSelectedDate();

    return {
      callback_reason: reasonScheduler,
      next_call_date_time: new Date(formattedDate).toISOString(),
    };
  }

  // @todo remove this method, bcs there is new update schedule from queue
  updateOldSchedule = () => {
    const { initialOrderIdScheduler, dispatch } = this.props;
    const token = Cookie.get('token');

    const payload = {
      ...this.getSchedulePayload(),
      orderId: initialOrderIdScheduler
    };

    dispatch({ type: 'UPDATE_SCHEDULE', payload: { token, schedule: payload } });
  }

  handleLeftButton = async () => {
    const { isRescheduled } = this.state;
    const { updateSchedule, toggle, type } = this.props;

    if (type) {
      if (!isRescheduled) {
        const payload = this.getSchedulePayload();
        updateSchedule(payload, true);
      }
      setTimeout(() => {
        Router.push('/agents/leads');
      }, 2000);
    } else {
      toggle();
    }
  }

  rescheduleSubmitHandler = async () => {
    const {
      timeScheduler,
      dayScheduler,
      automaticSchedulingReasons,
      reasonScheduler,
      notes,
      autoRescheduleTime,
    } = this.state;
    const { updateSchedule, noInstruction, type } = this.props;

    if (automaticSchedulingReasons.includes(reasonScheduler)) {
      const date = new Date();
      const nextCallDate = DateUtil.dateAdd(date, 'minute', autoRescheduleTime[reasonScheduler]);

      const payload = {
        callback_reason: reasonScheduler,
        next_call_date_time: new Date(nextCallDate).toISOString(),
      };

      updateSchedule(payload, null);
      this.setState({ isRescheduled: true });
      return;
    }

    if (!timeScheduler || !dayScheduler || !notes) {
      this.setState({ errorMessage: 'Lengkapi jadwal input diatas.' });
      setTimeout(() => this.setState({ errorMessage: '' }), 3000);
      return;
    }

    const selectedDate = dayjs(this.getSelectedDate());
    const timeDiff = selectedDate.diff(new Date, 'minute');
    if (timeDiff < 0) {
      this.setState({ errorMessage: 'Jam sudah berlalu' });
      setTimeout(() => this.setState({ errorMessage: '' }), 3000);
      return;
    }

    if (type && noInstruction) {
      this.setState({ errorMessage: 'Pelanggan ini tidak mempunyai nomor Whatsapp.' });
      setTimeout(() => this.setState({ errorMessage: '' }), 3000);
    } else {
      const payload = this.getSchedulePayload();
      updateSchedule(payload, null, notes);
      this.setState({ isRescheduled: true });
    }
  }

  // this is just a dummy trigger button for datepicker
  renderCustomInput = ({ ref }) => {
    return (
      <button
        id="custom__input"
        style={{
          opacity: 0,
          position: 'fixed',
          top: 0,
          left: 0,
        }}
        ref={ref}
      >
        Custom datepicker input
      </button>
    )
  }

  getCallbackReasonList = () => {
    const { isLeadManagement, isCheckout, isApplication } = this.props;
    if (isLeadManagement) {
      return;
    } else if (isApplication) {
      const filteredReasonsForSaveAplikasi = ['Nomor tidak valid', 'Pelanggan tidak mengangkat telepon'];
      this.setState({
        scheduleReasons: this.state.scheduleReasons.filter(
          reason => !filteredReasonsForSaveAplikasi.includes(reason.label)
        )
      });
    } else if (isCheckout) {
      const filteredReasonsForCancelAplikasi = [
        'Nomor tidak valid',
        'Pelanggan tidak mengangkat telepon',
        'Pelanggan tidak membawa KTP'
      ];
      this.setState({
        scheduleReasons: this.state.scheduleReasons.filter(
          reason => !filteredReasonsForCancelAplikasi.includes(reason.label)
        )
      });
    }
  };

  render() {
    const { type } = this.props;

    const {
      timeScheduler,
      dayScheduler,
      reasonScheduler,
      automaticSchedulingReasons,
      errorMessage,
    } = this.state;

    return (
      <ModalBody>
        <div className="text-head mb-3">
          {type && (<span className="lifepal-check_round mr-2" />)}
          <span className="title">
            {!type ? 'Atur Jadwal' : 'Order Berhasil Dibuat'}
          </span>
        </div>
        <div className="callBackForm">
          <div className="row">
            <div className="col-md-12">
              <Label>Pilih Alasan kenapa pelanggan belum bisa membayar</Label>
            </div>
            <div className="col-md-12">
              <Dropdown
                value={reasonScheduler}
                zIndex={2}
                useRS
                options={this.state.scheduleReasons}
                callback={(value) => this.setState({ reasonScheduler: value })}
                placeholder="- Pilih alasan"
                isSearch={false}
              />
            </div>
          </div>
          {
            (
              !automaticSchedulingReasons.includes(reasonScheduler) &&
              reasonScheduler
            ) && (
              <>
                <div className="row">
                  <div className="col-md-8">
                    <Label>Atur Hari</Label>
                    <Dropdown
                      zIndex={1}
                      useRS
                      callback={(value) => {
                        this.setState({ dayScheduler: value },
                          () => {
                            if (this.state.dayScheduler === 'Pilih Tanggal') {
                              document.getElementById('custom__input').focus();
                            }
                          })
                      }}
                      options={scheduleLib}
                      placeholder="- Atur Hari"
                      value={dayLib[dayScheduler]
                        ? dayjs(dayLib[dayScheduler]).format('DD/MM/YYYY')
                        : dayjs(dayScheduler).format('DD/MM/YYYY')}
                      isSearch={false}
                    />
                    <LPDatePicker
                      emitDOB={(date) => {
                        const formattedDate = date.split('/').reverse();
                        this.setState({ dayScheduler: formattedDate });
                      }}
                      placeholderText="DD/MM/YYYY"
                      minDate={utils().getToday()}
                      renderInput={this.renderCustomInput}
                      emitOnChange
                    />
                  </div>
                  <div className="col-md-4">
                    <Label>Atur Jam</Label>
                    <Input
                      value={timeScheduler}
                      onChange={(e) => this.setState({ timeScheduler: e.target.value })}
                      time
                      placeholder="- Atur Jam"
                      style={{
                        width: '110px'
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Label>Catatan</Label>
                    <Input
                      name="Tulis Catatan Disini"
                      onChange={(event) => {
                        event.stopPropagation();
                        this.setState({ notes: event.target.value });
                      }}
                      textarea="true"
                    />
                  </div>
                </div>
              </>
            )
          }
          {errorMessage && (
            <div className="error-validation my-2">
              <p className="has-text-centered color-lp-red">{errorMessage}</p>
            </div>
          )}

          <div className={classNames('d-flex', 'justify-content-end', 'mt-3')}>
            <Button onClick={this.handleLeftButton} className="btnBack mr-2">
              {type ? 'Selesaikan Order' : 'Kembali'}
            </Button>
            <Button onClick={this.rescheduleSubmitHandler} className="btnSubmit">
              {type ? 'Kirim Instruksi' : 'Simpan'}
            </Button>
          </div>
        </div>
      </ModalBody>
    )
  }
}

export default CallBackForm;