import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import { gql, useLazyQuery } from '@apollo/client';

import { idr } from '../shared/currency';
import { getPaymentTerm } from '../lib/payment';
import { Switch } from './Switch/Switch';

const PaymentDetails = ({
  term,
  premium,
  productDiscount,
  plan,
  newPrice,
  voucherDiscount,
  voucherInfo,
  toggleCashbackForm,
  setToggleCashbackForm,
  setFieldValue,
  isMitra,
  orderId,
}) => {
  const total = premium - (premium * productDiscount);
  const matchPriceDiscount = newPrice ? total - newPrice : 0;

  const GET_CASHBACK_QUERY = gql`
    query getOrderCashback($orderId: Uuid!) {
      cashback(orderId: $orderId) {
        amount
      }
    }`;

  const [cashback, setCashback] = useState({});
  const [handleGetCashbackOrder] = useLazyQuery(GET_CASHBACK_QUERY);


  const fetchCashbackInformation = async (orderId) => {
    try {
      const { data, error } = await handleGetCashbackOrder({
        variables: {
          orderId: orderId
        }
      });

      if (error) throw error;
      setCashback(data.cashback);
    } catch (error) {
      setCashback({});
    }
  }

  const getDiscountInformation = () => {
    const percentOff = get(voucherInfo, 'discountPercentage', 0);
    return percentOff > 0 ? `${percentOff}%` : '';
  }

  const getPaymentTermDiscount = () => {
    const multiYearPremium = premium * plan.product.term / 12;
    const selectedPaymentTerm = getPaymentTerm(plan.product.term)[term];

    let discount = multiYearPremium * productDiscount

    if (selectedPaymentTerm && selectedPaymentTerm.counter) {
      discount = discount / selectedPaymentTerm.counter;
    }

    return Math.ceil(discount);
  }

  useEffect(() => {
    if (voucherInfo && voucherInfo.voucherCode && orderId) {
      fetchCashbackInformation(orderId);
      return;
    }

    if (voucherInfo && voucherInfo.cashbackAmount > 0 && !orderId) {
      setCashback({
        amount: voucherInfo.cashbackAmount,
        cashbackPercentage: voucherInfo.cashbackPercentage || 0,
      });
      return;
    }

    setCashback({});
  }, [voucherInfo]);

  return (
    <>
      <div className="charges__description">
        <span className="heading-sm-m">Harga Premi Checkout</span>
        <span className="pos-right heading-sm-m">{idr(premium)}</span>
      </div>
      {productDiscount > 0 && (
        <div className="charges__description">
          <span className="heading-sm-r">Disc. Jangka Tagihan</span>
          <span className="pos-right heading-sm-r" />
          <span className="pos-right text-error">- {idr(getPaymentTermDiscount())}</span>
        </div>
      )}
      {voucherDiscount > 0 && (
        <div className="charges__description">
          <span>Potongan {getDiscountInformation()}</span>
          <span className="pos-right" />
          <span className="pos-right text-error">- {idr(voucherDiscount)}</span>
        </div>
      )}
      {matchPriceDiscount > 0 && (
        <div className="charges__description">
          <span>Penyesuaian Harga</span>
          <span className="pos-right" />
          <span className="pos-right text-error">- {idr(matchPriceDiscount)}</span>
        </div>
      )}
      {plan.items.charges.map((item, index) => (item.is_active && item.is_primary_charge) &&
        (
          <div className="charges__description" key={index}>
            <span>{item.name}</span>
            <span className="pos-right">{idr(item.value)}</span>
          </div>
        )
      )}
      {cashback && cashback.amount > 0 && (
        <>
          <div className="charges__description">
            <span>Cashback  {cashback && cashback.cashbackPercentage > 0 && `${cashback.cashbackPercentage}%`}</span>
            <span className="pos-right green heading-xs-r">{idr(cashback.amount)}</span>
          </div>
          {
            !isMitra && (
              <div className="charges__description">
                <span>{toggleCashbackForm ? 'Saya ingin mendapatkan cashback' : 'Tidak ingin menerima cashback'}</span>
                <span className="pos-right">
                  <Switch
                    defaultStatus={false}
                    emitSwitchStatus={() => {
                      setToggleCashbackForm(!toggleCashbackForm);
                      if (setFieldValue) {
                        setFieldValue('withCashbackInformation', !toggleCashbackForm);
                      }
                    }}
                  />
                </span>
              </div>
            )
          }
        </>
      )}
    </>
  );
}

export default PaymentDetails;
