import { localRest } from '../../client';

export async function getBitlyUrl(url) {
  const bitly = await localRest(`/bitly`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    data: {
      'long_url': url,
    },
  });

  return bitly.link;
}
