import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import { Input } from '../../../../../components/Form';

import './RatingAndReason.module.scss';

const RATING_MAP = {
  '0': 'Belum Ada Penilaian',
  '1': 'Sangat Buruk',
  '2': 'Buruk',
  '3': 'Cukup Baik',
  '4': 'Baik',
  '5': 'Sangat Baik',
};

const RatingAndReason = ({ index, data, setRatingAndReason }) => {
  const [selectedStar, setSelectedStar] = useState('0');

  useEffect(() => {
    if (data.rating) {
      setSelectedStar(data.rating);
    }
  }, [data.rating]);

  return (
    <div className="rating__reason">
      <div className="rating__reason--question">
        {data.question}
      </div>
      <div className="rating__reason--star" onMouseLeave={() => setSelectedStar(data.rating)}>
        {
          [...new Array(5)].map((_, i) => (
            <i
              key={i}
              className={classNames(
                'icon ion-ios-star',
                i + 1 <= Number(selectedStar) && 'active',
                !data.isPrefilled && 'clickable'
              )}
              onClick={() => !data.isPrefilled && setRatingAndReason(index, 'rating', String(i + 1))}
              onMouseEnter={() => !data.isPrefilled && setSelectedStar(String(i + 1))}
            />
          ))
        }
        <span>- {RATING_MAP[data.rating] || 'Belum Ada Penilaian'}</span>
      </div>
      <div className="rating__reason--field">
        {
          (data.answer && data.isPrefilled)
            ? <p className="mb-0">{data.answer}</p>
            : (
              <Input
                textarea={true}
                value={data.answer}
                placeholder="Alasan terhadap penilaian"
                onChange={(event) => setRatingAndReason(index, 'answer', event.target.value)}
                rows="2"
              />
            )
        }
      </div>
    </div>
  );
};

export default RatingAndReason;