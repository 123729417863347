import React from 'react'

/**
 * @constant {Array.<object>} faqKesehatan faq list for kesehatan individu and keluarga page
 */
const faqKesehatan = [
  {
    header: 'Apakah orangtua saya ditanggung oleh asuransi saya?',
    body: (
      <p>
        Tidak. Anda tidak bisa menambahkan tanggungan ke paket asuransi Anda,
        termasuk orangtua Anda. Tapi dengan paket keluarga, Anda bisa
        menambahkan tanggungan dewasa.
      </p>
    )
  },
  {
    header: 'Bisakah saya membeli asuransi untuk orang yang diabetes?',
    body: (
      <p>
        Asuransi kesehatan biasanya tidak menanggung perawatan penyakit yang
        timbul dari kondisi diabetes, karena cukup mahal. Tapi dalam beberapa
        kasus, Anda bisa meningkatkan asuransi kesehatan untuk menanggung biaya
        medis timbul dari kondisi diabetes.
      </p>
    )
  },
  {
    header:
      'Bisakah saya membeli asuransi untuk orang dengan Hipertensi atau Tekanan Darah?',
    body: (
      <p>
        Jika Anda menderita Tekanan darah atau Hipertensi, pastikan paket Anda
        menanggung resiko berhubungan dengan kedua kondisi ini. Setelah Anda
        menyelesaikan tes medis dan terbukti memiliki komplikasi kesehatan ini,
        Anda akan diklasifikasikan dan biaya dalam asuransi kesehatan Anda akan
        dihitung dengan baik.
      </p>
    )
  },
  {
    header: 'Bisakah saya meningkatkan total tanggungan maksimum paket saya?',
    body: (
      <p>
        Saat memperbarui paket, Anda bisa meningkatkan tanggungan. Tapi ingatlah
        bahwa Anda tidak akan bisa meningkatkan nilai asuransi jika telah
        melakukan klaim di polis sebelumnya.
      </p>
    )
  },
  {
    header:
      'Apakah saya mendapat perawatan gigi saat membeli asuransi kesehatan?',
    body: (
      <p>
        Paket kesehatan dasar tidak termasuk perawatan gigi, yang akan
        ditanggung dalam paket perawatan gigi. Yang ditanggung di paket
        kesehatan dasar Lifepal adalah perawatan dan operasi diperlukan setelah
        kecelakaan atau darurat. Tetapi, di Lifepal Anda bisa memperbarui paket
        kesehatan dasar dengan tanggungan gigi dengan premi tambahan yang
        ringan.
      </p>
    )
  },
  {
    header: 'Apakah saya perlu membeli polis asuransi kesehatan internasional?',
    body: (
      <p>
        Jika sering ke luar negeri, maka paket asuransi yang valid internasional
        (atau di negara yang paling sering dikunjungi) adalah ide yang bagus.
        Tetapi, ini biasanya memiliki biaya lebih banyak dari paket domestik
        dasar. Beberapa paket kesehatan internasional menanggung hanya selama
        perjalanan dan mungkin ide yang bagus untuk ditanggung dan menghemat
        pada waktu yang sama.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan juga menanggung perawatan Ayurvedic?',
    body: (
      <p>
        Beberapa penyedia asuransi telah mulai menyediakan tanggungan untuk
        perawatan Ayurvedik di paket kesehatan mereka. Tetapi, paket ini masih
        cukup langka dan biasanya biaya premi lebih tinggi.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan juga menanggung perawatan katarak?',
    body: (
      <p>
        Dalam beberapa kasus, penyedia asuransi kesehatan menyediakan tanggungan
        untuk perawatan katarak di polis asuransi kesehatan mereka. Tetapi,
        selalu periksa pengecualian, biasanya karena periode tunggu dan batas
        klaim.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan juga menanggung demam berdarah?',
    body: (
      <p>
        Kami menawarkan paket spesial dengan hanya Rp 50.000 dan menanggung Anda
        terhadap demam berdarah selama setahun. Ini juga disebut produk mikro
        yang menanggung Anda hanya untuk demam berdarah dan tambahan bagus untuk
        paket kesehatan dasar Anda. Tanpa pemeriksaan medis untuk mendaftar
        paket ini dan usia tidak mempengaruhi biaya premi.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan juga menanggung perawatan kehamilan?',
    body: (
      <p>
        Sebagian besar asuransi kesehatan juga menanggung perawatan kehamilan.
        Tapi, Anda biasanya perlu membayar premi lebih tinggi untuk menyertakan
        tanggungan kehamilan ke paket dasar Anda. Untuk detil, mohon isi
        formulir untuk mendapat harga langsung untuk paket kesehatan Anda di
        Lifepal.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan menanggung penyakit kritis?',
    body: (
      <p>
        Banyak penyedia asuransi menawarkan paket yang menanggung perawatan
        medis penyakit kritis tertentu. Paket ini memenuhi kebutuhan penyakit
        kritis dan juga bisa ditemukan di Lifepal.
      </p>
    )
  },
  {
    header: 'Apakah asuransi kesehatan menanggung biaya popok dewasa?',
    body: (
      <p>
        Polis di Indonesia biasanya tidak menyuport biaya popok dewasa, walaupun
        sebagian produk inkontinensia dewasa sudah termasuk di paket kesehatan
        Anda.
      </p>
    )
  },
  {
    header:
      'Apakah asuransi kesehatan juga menyediakan pemeriksaan medis sebelum membeli?',
    body: (
      <p>
        Pemeriksaan medis sebelumnya diminta oleh sebagian perusahaan asuransi,
        sebagai serangkaian tes yang Anda perlukan untuk menentukan kondisi
        medis saat ini dan penyakit sebelumnya. Polis Lifepal tidak memerlukan
        pemeriksaan medis apapun.
      </p>
    )
  },
  {
    header: 'Bagaimana saya membatalkan polis asuransi saya?',
    body: (
      <p>
        Anda bisa membatalkan polis asuransi Anda dengan menelepon langsung
        penyedia asuransi Anda. Jika Anda klien Lifepal, Anda bisa selalu
        menelepon layanan Lifepal untuk menanyakan detil prosedur ini; mereka
        akan memastikan Anda mendapat semua informasi diperlukan untuk membuat
        proses ini semulus mungkin. Untuk diskusi dengan penyedia asuransi,
        pastikan Anda menyiapkan nomor polis dan bukti identitas dengan Anda.
      </p>
    )
  },
  {
    header: 'Bagaimana mengklaim NCB saya?',
    body: (
      <p>
        Anda bisa menggunakan bonus ini baik sebagai bonus kumulatif atau pada
        waktu pembaruan atau porting polis asuransi kesehatan.
      </p>
    )
  },
  {
    header: 'Bagaimana mendapat tanggungan kesehatan untuk orangtua saya?',
    body: (
      <p>
        Jika ingin membeli asuransi kesehatan untuk orangtua, Anda perlu membeli
        asuransi kesehatan individu bagi mereka atau mencari paket keluarga yang
        memungkinkan menambah orangtua sebagai tanggungan. Paket medis ini
        spesifik karena mereka menanggung orang berusia 55-60+ tahun.
      </p>
    )
  },
  {
    header: 'Bagaimana meningkatkan tanggungan paket saya?',
    body: (
      <p>
        Jika ingin meningkatkan tanggungan untuk paket Lifepal ini sangat mudah.
        Yang perlu dilakukan adalah menghubungi tim concierge dan mereka akan
        langsung memberitahu jika bisa melakukannya. Mereka lalu memberi semua
        instruksi untuk mengumpulkan permintaan.
      </p>
    )
  },
  {
    header:
      'Bagaimana memilih asuransi kesehatan yang tepat untuk orangtua saya?',
    body: (
      <p>
        Saat membeli asuransi kesehatan untuk orangtua, Anda harus
        mempertimbangkan usia mereka, kondisi medis saat ini dan penyakit yang
        ada dengan serius. Lalu, Anda harus memastikan memberikan tanggungan
        yang cukup karena biaya medis bagi pasien senior biasanya lebih tinggi.
      </p>
    )
  },
  {
    header:
      'Bagaimana memindah asuransi saya dari satu penanggung ke yang lain?',
    body: (
      <>
        <p>
          Menurut OJK, Anda bisa memindah polis Anda saat ini ke penyedia baru
          dan mengubahnya sesuai kebutuhan medis Anda. Anda bisa menghubungi tim
          Lifepal dalam 45 hari sebelum polis saat ini berakhir untuk memindah
          produk apapun yang ditawarkan di situs kami. Berikut yang akan kami
          lakukan setelah menerima permintaan Anda:
        </p>
        <ol>
          <li>
            Mengirim formulir pemindahan polis dan meminta semua dokumen relevan
            siap diperiksa.
          </li>
          <li>
            Mengirim dokumen ke penanggung baru, sehingga mereka bisa mengecek
            detilnya.
          </li>
          <li>
            Penanggung baru akan memindah polis asuransi kesehatan ke portal
            OJK.
          </li>
          <li>Penanggung baru akan membuat proposal dan memprosesnya.</li>
          <li>Kami akan memberi Anda proposal mereka dalam 15 hari.</li>
        </ol>
      </>
    )
  },
  {
    header: 'Bagaimana mengklaim non tunai untuk perawatan medis saya?',
    body: (
      <p>
        Saat Anda mendapat perawatan di salah satu rumah sakit yang bekerjasama
        dengan penyedia asuransi Anda, Anda bisa sering memanfaatkan
        pengembalian non tunai. Untuk melakukannya, yang perlu Anda lakukan
        adalah menunjukkan kartu polis ke pegawai administrasi dan emngisi detil
        formulir pra-opname. Formulir lalu akan diteliti untuk keabsahan.
      </p>
    )
  },
  {
    header: 'Jika saya perokok, akankah premi saya naik?',
    body: (
      <p>
        Jika Anda merokok, Anda cenderung dibebankan premi lebih tinggi karena
        terpapar resiko medis lebih tinggi.
      </p>
    )
  },
  {
    header: 'Apakah BPJS, asuransi kesehatan Pemerintah, cukup untuk saya?',
    body: (
      <p>
        Di Indonesia, banyak yang tidak mampu menginvestasi asuransi kesehatan.
        Inilah mengapa OJK di tahun 2015 mengadakan asuransi kesehatan, BPJS,
        wajib bagi semua majikan untuk membayar karyawan mereka. Berkat ini,
        jutaan orang di Indonesia menerima tanggungan kesehatan yang amat
        diperlukan untuk pertama kali. Paketnya sangat terjangkau dan memberi
        perawatan dasar dengan sedikit pengecualian (seperti penyakit kritis).
        Tapi, mereka yang mampu membeli asuransi pribadi, masih lebih memilih
        membayar ekstra (sebanyak Rp 50.000/tahun) untuk mendapat layanan
        kualitas lebih tinggi saat mereka paling membutuhkannya.
      </p>
    )
  },
  {
    header: 'Apakah mungkin untuk menghemat di asuransi kesehatan?',
    body: (
      <p>
        Ya, jika Anda membeli asuransi kesehatan, Anda sudah menghemat karena
        perawatan medis sering kali mahal. Anda juga bisa menghemat dengan
        memilih polis yang tepat bagi Anda dan inilah saat Lifepal menyuport
        Anda. Berinvestasi langsung dalam polis melalui Lifepal, untuk
        menghindari komisi agen tidak perlu. Anda bahkan bisa mendapat manfaat
        pajak saat membeli asuransi kesehatan.
      </p>
    )
  },
  {
    header: 'Apakah itu paket kesehatan dasar dan mengapa saya memerlukannya?',
    body: (
      <p>
        Paket kesehatan dasar hanya menanggung Anda untuk perawatan dibawah
        rawat inap. Ini berarti Anda diopname setidaknya sehari. Ini adalah
        paket paling dasar yang harus dimiliki setiap orang untuk selalu
        menerima perawatan terbaik saat mereka paling membutuhkannya.
      </p>
    )
  },
  {
    header:
      'Apakah paket kesehatan kompleks dan mengapa saya harus membelinya?',
    body: (
      <p>
        Paket kompleks adalah satu atau beberapa tingkat lebih tinggi dari paket
        dasar karena memberi tanggungan tambahan sesuai kebutuhan pemegang
        polis. Anda bisa membeli paket ini di Lifepal untuk mendapat rawat
        jalan, gigi, perawatan kehamilan atau manfaat lain yang tidak ditanggung
        paket dasar.
      </p>
    )
  },
  {
    header: 'Apakah itu penyakit yang sudah ada?',
    body: (
      <p>
        Ada beberapa penyakit, disebut yang sudah ada, yang ditanggung polis
        hanya setelah periode tunggu tertentu. Ini disebut sudah ada karena
        pemegang polis sudah menderitanya dari waktu mereka membeli polis.
      </p>
    )
  },
  {
    header: 'Apakah paket yang menanggung kanker?',
    body: (
      <p>
        Paket asuransi kanker ada di kategori paket asuransi kritis dan dijual
        biasanya sebagai tambahan asuransi kesehatan. Ini menanggung resiko yang
        berhubungan dengan kanker dan perwujudannya. Jarang disertakan dalam
        paket dasar karena cukup mahal karena biaya perawatan biasanya sangat
        tinggi.
      </p>
    )
  },
  {
    header: 'Tanggungan apa yang saya dapat sebagai wanita?',
    body: (
      <p>
        Wanita kini memiliki akses ke paket kesehatan seperti pria. Paket
        Lifepal memenuhi baik pria atau wanita dan premi diatur dengan sesuai.
        Sebagai wanita, Anda juga bisa meningkatkan paket kesehatan Anda dengan
        produk asuransi lainnya yang dibuat khusus untuk Anda seperti kanker
        payudara, kanker ovarian, kanker serviks dan kehamilan yang menanggung
        komplikasi saat kehamilan dan melahirkan.
      </p>
    )
  },
  {
    header: 'Apakah arti rasio pengambilan klaim dan mengapa ini penting?',
    body: (
      <p>
        Anda bisa menghitung rasio pengambilan klaim penyedia asuransi dengan
        membagi total nilai klaim dibayar atau diambil dengan total klaim
        dilaporkan dalam setahun. Rasio ini sering tersedia untuk semua penyedia
        asuransi dan memberikan Anda ide kemampuan membayar klaim tepat waktu.
      </p>
    )
  },
  {
    header: 'Apakah arti co-pay?',
    body: (
      <p>
        Co-pay adalah klausa dalam polis Anda sesuai yang diperlukan untuk
        membagi beban klaim dan membayar sebagian tagihan, bahkan jika .
        Untungnya, paket Lifepal tidak memiliki klausa co-pay.
      </p>
    )
  },
  {
    header:
      'Apakah arti pengembalian uang non tunai dalam polis asuransi kesehatan?',
    body: (
      <p>
        Jika Anda mendapat perawatan, yang ditanggung dalam polis, di salah satu
        rumah sakit yang bekerjasama dengan penyedia asuransi Anda, Anda akan
        bisa memanfaatkan perawatan dan boleh pulang tanpa membayar apapun dari
        saku Anda.
      </p>
    )
  },
  {
    header: 'Apakah arti NCB?',
    body: (
      <p>
        NCB, bonus tanpa klaim, adalah manfaat yang ditawarkan penanggung jika
        pemegang polis tidak melakukan klaim dalam satu tahun polis. Bonus bisa
        ditingkatkan dalam nilai asuransi atau pengurangan premi.
      </p>
    )
  },
  {
    header:
      'Apakah arti pemindahan dan bagaimana saya memindah paket asuransi saya?',
    body: (
      <>
        <p>
          Dengan asuransi kesehatan, memungkinkan untuk memindah polis saat ini
          dari satu penyedia ke yang lain. Prosedur ini disebut porting. Berikut
          langkah untuk memindah asuransi Anda:
        </p>
        <ol>
          <li>
            Anda perlu mendaftar pemindahan dalam 45 hari sebelum polis saat ini
            berakhir.
          </li>
          <li>
            Anda harus mengisi formulir dari polis porting dan memberikan semua
            dokumen diperlukan.
          </li>
          <li>
            Penanggung baru perlu mengecek detil untuk memungkinkan pemindahan
            dalam tujuh hari.
          </li>
          <li>
            Informasi ini lalu akan diberikan oleh penanggung ke portal OJK.
          </li>
          <li>Proposal lalu akan dibuat oleh penanggung.</li>
          <li>
            Jika semua selesai menurut aturan OJK, Anda akan diberikan proposal
            penanggung baru dalam 15 hari.
          </li>
          <li>
            Tapi, jika pendaftaran ditolak, penanggung baru harus menerima
            permintaan Anda dengan otomatis.
          </li>
        </ol>
      </>
    )
  },
  {
    header: 'Apakah arti periode tunggu dalam asuransi?',
    body: (
      <p>
        Periode tunggu adalah waktu Anda harus menunggu sebelum tanggungan bisa
        dimulai. Anda tidak bisa mengumpulkan klaim sebelum periode tunggu
        selesai.
      </p>
    )
  },
  {
    header: 'Fitur apa yang dimiliki asuransi kesehatan yang bagus?',
    body: (
      <>
        <p>Paket asuransi yang baik harus:</p>
        <ol>
          <li>Dirancang sesuai kebutuhan.</li>
          <li>Terjangkau dan efektif secara biaya.</li>
          <li>Dapat diperbarui dibawah keadaan yang tepat.</li>
          <li>Ada dimana-mana dengan rawat inap non tunai bagus.</li>
          <li>Mudah dan cepat dengan prosedur klaim.</li>
          <li>Diulas positif oleh pelanggan.</li>
        </ol>
      </>
    )
  },
  {
    header:
      'Apakah yang terjadi setelah saya berhasil mengklaim asuransi saya?',
    body: (
      <p>
        Saat Anda berhasil memasukkan klaim, penanggung akan mengembalikan uang
        Anda sesuai syarat dan ketentuan polis Anda. Tapi, jika yang ditanggung
        meninggal, calon akan mendapatkan manfaatnya.
      </p>
    )
  },
  {
    header:
      'Apakah yang terjadi pada polis saya jika saya tidak membayar premi?',
    body: (
      <p>
        Perusahaan asuransi biasanya memberi Anda masa tenggang antara satu dan
        tiga bulan setelah kadaluwarsa. Setelah periode ini, polis asuransi Anda
        akan lewat dan Anda tidak lagi bisa memanfaatkannya.
      </p>
    )
  },
  {
    header: 'Apakah itu TPA (Administrasi Pihak Ketiga)?',
    body: (
      <p>
        Kami menyebut TPA atau Administrator Pihak Ketiga agen yang mengatur
        proses pengambilan klaim atas nama penanggung.
      </p>
    )
  },
  {
    header: 'Apakah itu biaya sewa kamar dan mengapa saya harus berhati-hati?',
    body: (
      <p>
        Sewa kamar adalah servis paling dasar yang ditanggung dalam paket
        kesehatan dan menjelaskan berapa uang yang akan dikembalikan pada Anda
        atas menyewa kamar di rumah sakit saat Anda diopname. Batas ini
        mempengaruhi premi polis Anda, tetapi juga berapa yang harus Anda
        keluarkan dari saku Anda, jika biaya kamar lebih tinggi dari batas paket
        Anda.
      </p>
    )
  },
  {
    header:
      'Apakah perbedaan antara penyakit kritis (CI) dan paket asuransi kesehatan?',
    body: (
      <p>
        Paket asuransi kesehatan menanggung semua perawatan medis umum dengan
        beberapa pengecualian. Beberapa pengecualian ini seperti perawatan
        penyakit kritis yang biasanya sangat mahal. Itulah mengapa paket CI ada,
        untuk memberi Anda kemungkinan menanggung diri Anda terhadap sesuatu
        yang tidak disertakan dalam paket asuransi kesehatan Anda. Penyakit
        kritis populer adalah kanker, serangan jantung, dll.
      </p>
    )
  },
  {
    header: 'Apakah itu Jaringan Rumah Sakit dalam asuransi kesehatan?',
    body: (
      <p>
        Setiap penyedia asuransi bekerja dengan jaringan rumah sakit. Ini adalah
        rumah sakit dimana Anda bisa memanfaatkan pengembalian uang non tunai.
        Dengan begitu, Anda tidak perlu membayar dari saku untuk rawat inap dan
        perawatan sehari-hari ditanggung dalam polis.
      </p>
    )
  },
  {
    header:
      'Apakah yang terbaik antara asuransi kesehatan dan kecelakaan pribadi?',
    body: (
      <>
        <p>
          Asuransi kecelakaan pribadi dan asuransi kesehatan adalah produk yang
          sangat berbeda. Berikut daftar perbedaan utama antara keduanya:
        </p>
        <ul>
          <li>
            Asuransi kesehatan menanggung semua biaya media saat rawat inap.
            Yang dimana, paket kecelakaan pribadi tidak menanggung biaya media,
            tidak juga yang timbul dari kecelakaan.
          </li>
          <li>
            Paket asuransi kesehatan juga menanggung biaya pra dan pasca rawat
            inap untuk beberapa hari, sementara paket kecelakaan pribadi tidak.
          </li>
          <li>
            Paket kecelakaan pribadi menanggung kematian kecelakaan. Calon akan
            menerima 100% manfaat. Sebagian besar paket asuransi kesehatan dasar
            tidak menyediakan tanggungan kematian.
          </li>
          <li>
            Paket kecelakaan pribadi menyediakan tanggungan terhadap kecacatan
            yang disebabkan kecelakaan, sementara sebagian besar paket asuransi
            kesehatan tidak.
          </li>
        </ul>
      </>
    )
  },
  {
    header: 'Paket apa yang bisa dibeli tanpa pra pemeriksaan medis?',
    body: (
      <p>
        Semua asuransi kesehatan di Lifepal tidak memerlukan pemeriksaan medis
        sebelumnya.
      </p>
    )
  },
  {
    header:
      'Apakah yang terbaik? Paket individu atau paket asuransi kesehatan keluarga?',
    body: (
      <p>
        Bergantung pada yang diperlukan. Jika ingin menanggung seluruh keluarga
        pada harga lebih nyaman, paket keluarga adalah yang diperlukan. Jika
        memilih untuk tetap fleksibel dengan paket, maka Anda mungkin memilih
        paket individu untuk tiap anggota keluarga. Paket keluarga biasanya
        menyediakan nilai asuransi lebih tinggi dan memiliki premi lebih rendah;
        tapi, akan tidak terlalu fleksibel karena memiliki lebih banyak orang
        yang bergantung.
      </p>
    )
  },
  {
    header: 'Apakah proses memperbarui asuransi saya?',
    body: (
      <p>
        Anda bisa memperbarui asuransi kesehatan online atau offline. Untuk
        pembaruan offline, Anda harus menghubungi perusahaan asuransi setidaknya
        30 hari sebelum kadaluwarsa dan membuat permintaan pembaruan. Jika ada
        perubahan dalam kondisi kesehatan, Anda harus memberi tahu saat
        pembaruan. Untuk pembaruan baru, proses di Lifepal jauh lebih mudah.
        Anda hanya perlu masuk dengan kredensial Lifepal dan melakukan
        pembayaran berikutnya langsung di situs. Untuk perubahan kondisi
        kesehatan, bisa memberi tahu tim kami sebelum pembaruan.
      </p>
    )
  },
  {
    header:
      'Saat membeli asuransi kesehatan, akankah saya juga mendapat pemeriksaan medis?',
    body: (
      <p>
        Dalam beberapa kasus, perusahaan asuransi meminta pemeriksaan medis saat
        proses aplikasi untuk menilai kondisi kesehatan saat ini sebelum Anda
        berinvestasi dalam polis. Lifepal tidak memiliki persyaratan ini.
      </p>
    )
  },
  {
    header: 'Akankah anak-anak saya ditanggung asuransi saya?',
    body: (
      <p>
        Tidak, untuk menanggung anak-anak Anda perlu membeli paket khusus untuk
        keluarga, atau Anda bisa membeli tanggungan individu untuk masing-masing
        anak. Tapi, dalam beberapa kasus, Anda bisa meningkatkan polis untuk
        menanggung anak-anak juga. Hak ini berakhir saat anak-anak menjadi
        terlalu tua dan harus membeli asuransi individu sendiri.
      </p>
    )
  },
  {
    header: 'Akankah istri saya ditanggung asuransi saya?',
    body: (
      <p>
        Tidak, untuk menanggung istri Anda perlu membeli tanggungan individu.
        Tapi, sebagian besar paket perusahaan menyediakan manfaat asuransi
        kesehatan untuk pegawai yang juga menanggung anggota keluarga mereka.
        Jika membeli paket keluarga atau paket asuransi tertentu, Anda bisa
        memperluas tanggungan ke istri, atau juga dengan meningkatkannya.
      </p>
    )
  }
]

/**
 * @constant {Array.<object>} faqJiwa faq list for jiwa page
 */
const faqJiwa = [
  {
    header: 'Apakah klaim asuransi jiwa sah dalam kematian kecelakaan?',
    body: (
      <p>
        Kematian kecelakaan ditanggung oleh asuransi jiwa; dan klaim yang
        berhubungan akan diterima penanggung. Tapi ada beberapa pengecualian,
        contohnya: kecelakaan disebabkan oleh mengemudi dibawah pengaruh
        obat-obatan atau alkohol, kecelakaan terencana atau disebabkan tanpa
        ijin mengemudi sah akan membuat klaim tidak sah.
      </p>
    )
  },
  {
    header: 'Apakah serangan teroris berlaku dalam paket jiwa Anda?',
    body: (
      <p>
        Kematian karena serangan teroris biasanya ditanggung dibawah paket jiwa,
        tetapi selalu tergantung pada penanggung.
      </p>
    )
  },
  {
    header: 'Bisakah saya membatalkan asuransi saya?',
    body: (
      <>
        <p>
          Kadang seseorang tidak memilih asuransi dengan bijak dan berakhir
          dengan polis yang tak memenuhi kebutuhan. Dalam kasus ini, biasanya
          mereka berpikir untuk berhenti membayar premi, menyerahkan (atau
          membatalkan) polis dan mendapat semua atau sebagian premi kembali.
        </p>
        <p>
          Beberapa polis, termasuk yang dijual di Lifepal, memiliki nilai
          penyerahan. Ini adalah nilai yang diterima orang yang diasuransikan
          jika ia memutuskan untuk membatalkan polis sebelum akhir periode.
          Memungkinkan halnya bahwa penanggung akan mengurangi dari nilai
          penyerahan.
        </p>
      </>
    )
  },
  {
    header: 'Bisakah saya mengganti pewaris asuransi jiwa saya?',
    body: (
      <p>
        Bisa. Kapanpun Anda membeli polis di Lifepal, Anda bisa menambahkan
        beberapa pewaris dan menggantinya kemudian. Biasanya, penyedia asuransi
        tidak memerlukan dokumentasi apapun untuk membuat perubahan, tetapi
        sebagian dapat meminta Anda memberikan bukti ID.
      </p>
    )
  },
  {
    header: 'Bisakah saya menanggung anak-anak atau pasangan saya?',
    body: (
      <p>
        Bisa. Anda harus membeli polis untuk masing-masing anak dan pasangan
        jika mau menanggung mereka terhadap kematian alami dan kecelakaan. Anda
        tidak bisa memperpanjang tanggungan pada mereka bahkan jika mereka
        adalah keluarga.
      </p>
    )
  },
  {
    header:
      'Bisakah saya berganti paket jika memiliki paket lebih baik dari milik saya?',
    body: (
      <p>
        Sayangnya, tidak memungkinkan untuk berganti dari satu paket ke paket
        lain bahkan jika Anda telah menemukan paket yang menanggung Anda lebih
        baik dari yang saat ini.
      </p>
    )
  },
  {
    header: 'Apakah asuransi jiwa menanggung kematian alami?',
    body: (
      <p>
        Tentu saja, kematian alami harus ditanggung oleh polis jiwa apapun.
        Polis jiwa Anda harus selalu menanggung kematian oleh penyakit atau
        kecelakaan, pada syarat dan ketentuan tertentu.
      </p>
    )
  },
  {
    header: 'Apakah premi meningkat selama periode polis?',
    body: (
      <p>
        Setelah polis dikeluarkan, premi akan selalu sama terlepas dari inflasi
        atau deflasi.
      </p>
    )
  },
  {
    header:
      'Bagaimana saya bisa yakin keluarga berhasil mengklaim asuransi jiwa saya?',
    body: (
      <>
        <p>
          Membuat keluarga siap mengklaim polis Anda sangat penting dan ini
          dimulai dari Anda. Tepat di awal, saat membeli polis, Anda harus
          mengisi formulir proposal dengan informasi benar.
        </p>
        <p>
          Jika Anda diperlukan untuk melalui tes medis, Anda harus
          menyelesaikannya sesuai standar dan memberikan semua hasil dalam
          formulir yang diminta penanggung. Anda bisa selalu mendapat saran dari
          tim Lifepal Anda.
        </p>
      </>
    )
  },
  {
    header: 'Bagaimana memilih penanggung terbaik?',
    body: (
      <p>
        Lifepal memilih perusahaan asuransi tepat untuk Anda berdasarkan dua
        faktor: Harga terjangkau dan Reputasi; Hal ini bergantung pada citra
        merek, rasio pengambilan klaim dan kualitas layanan selama ini.
      </p>
    )
  },
  {
    header:
      'Bagaimana menggolongkan perokok atau non perokok sebelum membeli paket asuransi online?',
    body: (
      <>
        <p>
          Penyedia asuransi membedakan perokok dalam tiga grup: perokok pilihan
          (seseorang yang fit), perokok tipikal (dengan masalah kesehatan kecil)
          dan perokok table rated (dengan kondisi fisik buruk).
        </p>
        <p>
          Masing-masing penyedia asuransi mungkin memiliki definisi berbeda
          tentang perokok dan bukan perokok sehingga lebih baik halnya jika Anda
          mengecek ulang langsung dengan mereka.
        </p>
      </>
    )
  },
  {
    header: 'Bagaimana penanggung menghitung premi paket jiwa saya?',
    body: (
      <>
        <p>
          Ini adalah faktor yang dipertimbangkan penanggung untuk menghitung
          premi asuransi Anda:
        </p>
        <ul>
          <li>Usia</li>
          <li>Jenis kelamin</li>
          <li>Nilai Asuransi</li>
          <li>Kondisi Kesehatan</li>
          <li>Periode polis</li>
          <li>Frekuensi pembayaran premi</li>
          <li>Pembayaran yang dipilih</li>
          <li>Premi Anda tidak berubah selama durasi penuh polis</li>
        </ul>
      </>
    )
  },
  {
    header: 'Berapa lama untuk mengambil klaim?',
    body: (
      <>
        <p>
          Hal ini bergantung pada penanggung Anda. Selalu cek rasio pengambilan
          rasio penanggung Anda sebelum membeli polis, untuk memastikan pewaris
          akan mendapat pengambilan klaim tidak repot.
        </p>
        <p>
          Biasanya, pengambilan klaim memakan waktu 8 hingga 15 hari dan
          bergantung pada kondisi yang berlaku atas klaim medis Anda.
        </p>
      </>
    )
  },
  {
    header: 'Berapa lama asuransi jiwa saya bertahan?',
    body: (
      <p>
        Ini pertanyaan yang memerlukan pertimbangan. Selalu berpikir dua kali
        apa Anda memerlukan polis yang menanggung hingga usia 70, Anda mungkin
        tidak perlu karena pasti sudah memenuhi tujuan finansial Anda pada saat
        itu. Inilah mengapa sebagian besar menanggung hingga usia 60.
      </p>
    )
  },
  {
    header: 'Berapa biaya rata-rata asuransi jiwa?',
    body: (
      <p>
        Sulit menjawab pertanyaan seumum ini karena premi polis asuransi
        bervariasi tergantung pada berbagai faktor seperti usia, gender, gaya
        hidup, penyakit yang sudah ada, masa polis yang dipilih dan nilai
        diasuransikan, dll.
      </p>
    )
  },
  {
    header:
      'Lebih baik untuk membeli satu tanggungan jiwa atau memisahnya ke beberapa polis?',
    body: (
      <p>
        Tidak disarankan untuk membagi polis karena menjadi lebih rumit untuk
        mendapat tanggungan yang diinginkan. Namun, sebagian orang memiliki
        kebutuhan berbeda dan memilih untuk membeli beberapa polis daripada
        tanggungan berbeda dibawah berbagai durasi.
      </p>
    )
  },
  {
    header:
      'Apakah penting memasukkan tambahan ke paket jiwa? Yang mana yang harus dibeli?',
    body: (
      <>
        <p>
          Anda bisa memasukkan tambahan ke tanggungan jiwa dasar untuk
          menyesuaikan polis untuk situasi tertentu seperti kematian kecelakaan,
          penyakit kritis, cacat permanen, dll.
        </p>
        <p>
          Contohnya, jika memasukkan tambahan kematian kecelakaan, Anda akan
          mendapat tanggungan ganda untuk kematian kecelakaan untuk premi yang
          hanya kurang lebih 20% lebih tinggi. Tanyakan tim Lifepal tentang
          semua tambahan yang ada dan yang paling sesuai kebutuhan.
        </p>
      </>
    )
  },
  {
    header: 'Apakah bisa mengganti polis setelah dikeluarkan?',
    body: <p>Anda tidak bisa mengubah polis Anda setelah dikeluarkan.</p>
  },
  {
    header:
      'Apakah saya bisa mendapatkan uang saya kembali setelah akhir periode?',
    body: (
      <p>
        Polis jiwa seperti yang ditawarkan Lifepal menjamin pengembalian premi
        penuh atau sebagian pada akhir periode. Ini terjadi hanya jika pemegang
        polis tidak menyerahkan polis sebelum periode berakhir.
      </p>
    )
  },
  {
    header: 'Apakah pembayaran asuransi jiwa dikenai pajak?',
    body: (
      <p>
        Tidak, semua yang didapatkan oleh pewaris setelah kematian pemegang
        polis adalah bebas pajak.
      </p>
    )
  },
  {
    header: 'Apakah ada peluang premi saya akan berubah di masa depan?',
    body: (
      <p>
        Tidak, setiap polis jiwa harus menjaga premi di tingkat sama mulai polis
        dikeluarkan.
      </p>
    )
  },
  {
    header: 'Haruskah Anda memisah polis saya ke dua penanggung berbeda?',
    body: (
      <>
        <p>
          Ini adalah topik yang bisa diperdebatkan, karena sebagian orang
          berkata lebih nyaman sementara yang lain berkata tidak. Yang berkata
          dipisah mengatakan ini bisa membantu memisahkan resiko, sementara yang
          menolak pemisahan berkata lebih efektif biayanya untuk memegang satu
          polis.
        </p>
        <p>Berikut manfaat pemisahan:</p>
        <ul>
          <li>
            Fleksibilitas: Anda juga bisa membeli beberapa polis sesuai
            kebutuhan Anda.
          </li>
          <li>
            Kebebasan menyerahkan: Karena keperluan berubah seiring waktu, Anda
            mungkin ingin menyerahkan polis untuk menyimpan uang di premi masa
            depan, saat Anda merasa resiko finansial telah berkurang.
          </li>
          <li>
            Lebih banyak manfaat: Karena kompetisi tinggi di pasar, penyedia
            asuransi membuat polis sangat menarik yang menyertakan manfaat
            berbeda. Anda mungkin merasa ketinggalan sesuatu jika hanya memegang
            satu
          </li>
          <li>
            Jatuh tempo berbeda: Dengan membeli satu polis 30 tahun dan satu 15
            tahun, Anda bisa mendapat premi yang lebih sesuai dengan anggaran
            Anda.
          </li>
          <li>
            Beberapa pewaris: Anda mungkin tidak ingin terikat dalam memisah
            jumlah secara proporsional pada semua pewaris Anda. Karenanya,
            dengan memiliki beberapa polis, Anda bisa memberikan masing-masing
            ke pewaris berbeda di keluarga Anda.
          </li>
        </ul>
        <p>Berikut keterbatasan dari pemisahan:</p>
        <ul>
          <li>
            Dokumentasi yang dobel: Rumit untuk mengurus dokumentasi dari
            penyedia asuransi berbeda pada waktu yang sama.
          </li>
          <li>
            Lebih banyak tes medis: Anda mungkin perlu menjalani beberapa tes
            medis, seperti yang diperlukan oleh penanggung berbeda.
          </li>
          <li>
            Lebih banyak pembayaran premi: Anda akan harus mengatur pembayaran
            berbeda ke perusahaan berbeda dan ini tidak praktis dalam jangka
            panjang.
          </li>
          <li>
            Klaim dobel: Pengambilan klaim tidak pernah menyenangkan untuk
            pewaris Anda, tak perlu dikatakan, akan menjadi lebih merepotkan
            jika pewaris Anda harus mengklaim beberapa kali dari penyedia
            asuransi berbeda. Mengakibatkan dobel proses tindak lanjut.
          </li>
          <li>
            Premi Lebih Tinggi: Anda tidak akan pernah bisa membayar premi lebih
            rendah dengan menggabungkan dua polis atau lebih.
          </li>
          <li>
            Ketinggalan diskon: Penanggung biasanya menawarkan Anda diskon jika
            Anda mengasuransikan nilai lebih. Memisahkan polis membuat Anda
            tidak bisa mendapat diskon tertentu dari penanggung.
          </li>
        </ul>
      </>
    )
  },
  {
    header: 'Apakah itu paket asuransi jiwa dengan premi bisa dikembalikan?',
    body: (
      <p>
        Paket asuransi jiwa dengan premi yang bisa kembali (atau manfaat jatuh
        tempo) berbeda dari paket biasa karena, jika pemegang polis melewati
        periode, maka ia akan menerima semua atau sebagian premi yang
        dibayarkan.
      </p>
    )
  },
  {
    header:
      'Apakah itu penanggung dengan Rasio Pengambilan Klaim (CSR) terbaik?',
    body: (
      <>
        <p>
          Kami tidak menyarankan untuk menilai penyedia asuransi hanya dari
          Rasio Pengambilan Klaim (CSR), karena bisa dipengaruhi berbagai
          alasan. Misalnya, jumlah klaim diterima termasuk klaim curang dan
          keliru.{' '}
        </p>
        <p>
          Dengan demikian, penanggung mungkin memiliki CSR lebih rendah karena
          menerima lebih banyak klaim curang dan menolaknya. Cara terbaik untuk
          membantu penanggung melakukan pekerjaannya dan efektif dalam memproses
          klaim Anda adalah dengan bekerja dengan Lifepal untuk memberikan semua
          informasi benar tentang diri Anda.
        </p>
      </>
    )
  },
  {
    header: 'Apakah dokumen diperlukan untuk membeli paket jiwa?',
    body: (
      <p>
        Berikut daftar informasi diperlukan untuk membeli asuransi jiwa online:
        pendapatan, usia, alamat - domisili dan korespondensi, identitas, foto.
      </p>
    )
  },
  {
    header: 'Apakah pengecualian paling umum dalam asuransi jiwa?',
    body: (
      <>
        <p>
          Sebagian besar polis jiwa tidak menanggung kematian karena bunuh diri
          dan pembunuhan. Kematian kecelakaan karena berada dibawah pengaruh
          alkohol atau narkoba, balapan mobil atau sepeda, petualangan dan
          aktivitas olahraga juga dikecualikan.{' '}
        </p>
        <p>
          Sayangnya, sebagian besar polis jiwa tidak menanggung kematian
          berhubungan dengan kehamilan dan kondisi kesehatan yang ada
          sebelumnya. Asuransi jiwa tidak menanggung jika meninggal di lokasi
          yang tidak ditanggung atau saat menjalankan aktivitas ilegal.
        </p>
      </>
    )
  },
  {
    header: 'Apakah jenis kematian yang biasa ditanggung oleh asuransi jiwa?',
    body: (
      <p>
        Paket jiwa biasanya menanggung kematian sebab alami, kecelakaan atau
        penyakit (syarat dan ketentuan tertentu mungkin berlaku, tergantung
        polis).
      </p>
    )
  },
  {
    header:
      'Apakah yang terjadi jika orang yang diasuransikan meninggal dalam setahun membeli polis? Akankah masih sah?',
    body: (
      <p>
        Ini bergantung pada syarat dan ketentuan yang disebutkan dalam kontrak
        polis dan Anda harus selalu mengklarifikasinya dengan penyedia asuransi
        sebelum membeli paket. Tapi, polis Lifepal menjamin bahwa klaim bisa
        diambil bahkan jika kematian terjadi dalam tahun pertama periode.
      </p>
    )
  },
  {
    header:
      'Apakah yang terjadi jika klaim saya ditolak? Bisakah pewaris saya membuat klaim lain yang sama?',
    body: (
      <p>
        Jangan khawatir, jika klaim ditolak, selalu memungkinkan untuk mengklaim
        ulang kedua kali. Alasan penolakan bisa vervariasi contohnya perbedaan
        tertentu dalam dokumen. pewaris akan mendatangi keluhan ganti rugi
        penyedia asuransi atau pergi langsung ke IRDAI untuk minta menyelesaikan
        klaim.
      </p>
    )
  },
  {
    header:
      'Apakah yang terjadi pada nilai premi saya jika catatan medis menunjukkan hasil jelek?',
    body: (
      <p>
        Sayangnya, kondisi kesehatan Anda bisa mempengaruhi premi yang harus
        Anda bayar. Dengan demikian, jika pemeriksaan medis Anda menunjukkan
        hasil negatif, premi bisa meningkat. Polis Lifepal tidak memerlukan
        pemeriksaan medis, jadi resiko ini tidak ada.
      </p>
    )
  },
  {
    header: 'Apakah yang terjadi saat saya menyerahkan polis sebelum berakhir?',
    body: (
      <p>
        Saat Anda menyerahkan polis sebelum akhir periode, Anda setuju
        kehilangan premi yang sebelumnya Anda bayar dan tidak mendapatkan
        manfaat.
      </p>
    )
  },
  {
    header: 'Apakah yang ditanggung oleh asuransi jiwa?',
    body: (
      <p>
        Asuransi jiwa menanggung resiko kematian pemegang polis. Setelah
        kematian alami atau kecelakaan pemegang polis dalam syarat & ketentuan
        polis, penanggung akan membayar pewaris jumlah bulat yang disetujui
        dengan pemegang polis saat pembelian.
      </p>
    )
  },
  {
    header:
      'Apakah pendekatan terbaik untuk menghitung berapa asuransi untuk dibeli?',
    body: (
      <>
        <p>
          Memperkirakan nilai bisa menjadi menakutkan, tetapi Anda bisa selalu
          meminta bantuan tim Lifepal. Perhitungan biasanya berdasarkan
          parameter seperti investasi saat ini dan masa depan, obyektif
          finansial, pemasukan dan ekspenditur yang ada.{' '}
        </p>
        <p>
          Anda bisa menemukan metode perhitungan online seperti kalkulator Nilai
          Hidup Manusia (HLV) dan pengali pemasukan. Lifepal bisa membantu
          menggunakan alat-alat tersebut.
        </p>
      </>
    )
  },
  {
    header: 'Berapa nilai asuransi yang saya perlukan untuk paket jiwa saya?',
    body: (
      <p>
        Untuk menghitung nilai diasuransikan, Anda bisa menggunakan formula
        sederhana ini yang akan memberikan perkiraan yang bagus: Pemasukan
        Tahunan x 10 + Pinjaman/Kewajiban
      </p>
    )
  },
  {
    header: 'Apakah itu Unit Link dan haruskah saya membelinya?',
    body: (
      <>
        <p>
          Unit Link adalah gabungan investasi dan asuransi. Gabungan ini akan
          memiliki komponen tabungan bersama dengan manfaat jumlah bulat.{' '}
        </p>
        <p>
          Jika Unit Link berjalan baik di pasar, pewaris Anda akan menerima
          pengembalian nilai diasuransikan, jika Anda meninggal sebelum polis
          berakhir.{' '}
        </p>
        <p>
          Jika Anda melewati periode, Anda masih akan mendapat kembali dalam
          bentuk bonus. Tetapi, saat Unit Link berkinerja buruk, Anda akan
          kehilangan uang yang Anda investasikan.
        </p>
      </>
    )
  },
  {
    header:
      'Apakah yang harus saya pertimbangkan saat berinvestasi asuransi jiwa?',
    body: (
      <>
        <p>
          Membeli asuransi jiwa biasanya adalah keputusan dibuat untuk menjamin
          dukungan untuk keluarga setelah Anda meninggal. Ini adalah situasi
          yang rumit dan memerlukan pertimbangan dalam hal:
        </p>
        <ul>
          <li>
            <b>Tanggungan</b>. Dapatkan cukup untuk menanggung keluarga Anda di
            masa depan. Jangan berkompromi untuk menghemat premi.
          </li>
          <li>
            <b>Rasio Pengambilan Klaim</b>. Beli dari penanggung yang tepat yang
            akan menerima saat diperlukan.
          </li>
          <li>
            <b>Inflasi</b>. Saat Anda memperkirakan tanggungan yang diperlukan,
            selalu ingatlah inflasi.
          </li>
          <li>
            <b>Fitur tambahan</b>. Tambahan bisa melengkapi polis Anda dan
            memberikan Anda ketenangan pada harga yang terjangkau.
          </li>
        </ul>
      </>
    )
  },
  {
    header: 'Apakah yang akan saya dapat dari jatuh tempo paket jiwa saya?',
    body: (
      <>
        <p>
          Yang Anda dapatkan di akhir paket adalah sama dengan nilai asuransi
          yang Anda pilih saat membeli paket.{' '}
        </p>
        <p>
          Perhitungan nilai asuransi tidak pernah mudah dan bergantung pada
          berbagai faktor: berapa banyak tanggungan, keperluan investasi,
          keterjangkauan, gaya hidup keluarga Anda dan pendidikan anak-anak
          Anda.{' '}
        </p>
        <p>
          Idealnya, Anda harus menganalisa keperluan Anda dengan menambahkan
          biaya hidup pewaris dan mengurangi investasi yang dapat dijual.
        </p>
      </>
    )
  },
  {
    header:
      'Saat saya meninggal, bagaimana pewaris saya mengklaim paket jiwa saya?',
    body: (
      <>
        <p>
          Hal pertama untuk dilakukan adalah memberi tahu pewaris bahwa Anda
          membeli paket jiwa dan bahwa ia adalah orang yang harus mengklaim saat
          waktunya tiba. Berikut langkah untuk membuat klaim:
        </p>
        <ol>
          <li>
            Setelah kematian Anda, pewaris akan harus menghubungi perusahaan
            asuransi.
          </li>
          <li>
            Pewaris harus memberikan semua dokumen termasuk dokumen polis asli,
            catatan rumah sakit dan medis, akta kematian, formulir klaim dan
            detil rekening banknya.
          </li>
          <li>
            Ingat bahwa pewaris harus berkomunikasi dengan perusahaan asuransi
            melalui email untuk melacak pembicaraan.
          </li>
        </ol>
      </>
    )
  },
  {
    header:
      'Saat saya mendapat tawaran tandingan pada paket jiwa saya, apa prosedur untuk mengurangi tanggungan?',
    body: (
      <>
        <p>
          Jika Anda mendapat tawaran tandingan dari penyedia asuransi Anda, Anda
          memiliki beberapa pilihan:
        </p>
        <ol>
          <li>Menerima tanggungan apa adanya dengan ekstra premi.</li>
          <li>Mengurangi periode paket.</li>
          <li>Mengurangi tanggungan.</li>
        </ol>
      </>
    )
  },
  {
    header: 'Kapan saya harus membeli paket?',
    body: (
      <p>
        Dalam Asuransi, tidak pernah terlalu awal untuk berinvestasi dalam masa
        depan. Jika membeli paket pada usia muda, Anda akan mendapat premi lebih
        rendah. Anda juga tidak memiliki terlalu banyak kewajiban saat muda;
        karenanya, tidak perlu mengasuransikan nilai yang besar.
      </p>
    )
  },
  {
    header:
      'Mengapa manfaat kematian diperlukan jika sudah ditanggung oleh paket jiwa?',
    body: (
      <p>
        Walaupun kematian kecelakaan ditanggung dibawah paket jiwa, memiliki
        tanggungan manfaat kematian kecelakaan tambahan akan menjamin pewaris
        Anda dibayar lebih.
      </p>
    )
  },
  {
    header: 'Mengapa asuransi jiwa sangat penting?',
    body: (
      <>
        <p>
          Asuransi jiwa melindungi keluarga Anda secara finansial terhadap
          resiko kematian. Ini sangat penting, terutama jika Anda adalah tulang
          punggung.{' '}
        </p>
        <p>
          Paket jiwa akan menjamin tanggungan orang yang diasuransikan mendapat
          cukup uang untuk pemulihan dari kehilangan pendapatan mendadak. Diatas
          itu semua, asuransi jiwa juga memberi Anda manfaat pajak dan memakan
          biaya secara relatif kurang dari sebagian besar produk asuransi di
          pasaran.
        </p>
      </>
    )
  },
  {
    header: 'Mengapa paket jiwa lebih baik dari paket lainnya?',
    body: (
      <>
        <p>
          Alasan utama mengapa asuransi jiwa lebih baik dari asuransi lainnya
          adalah karena mudah dimengerti. Jangkauannya jelas karena menjaga Anda
          dan keluarga dari ketidakpastian. Berikut daftar alasan mengapa
          asuransi jiwa jauh lebih baik:
        </p>
        <ul>
          <li>
            Mudah dimengerti, sementara produk Unit Link rumit dan beresiko
          </li>
          <li>
            Paket proteksi murni, tidak seperti Unit Link atau paket serupa yang
            juga memiliki komponen investasi
          </li>
          <li>
            Premi biasanya sangat terjangkau dan tidak pernah berubah selama
            jangka waktu
          </li>
          <li>
            Sangat fleksibel karena memungkinkan Anda untuk memilih periode
            hingga 35 tahun
          </li>
          <li>
            Bisa dilengkapi dengan tambahan seperti pengabaian premi, kematian
            kecelakaan, dll.
          </li>
          <li>
            Menawarkan jaring keamanan finansial untuk keluarga Anda, tanpa
            biaya untuk mereka
          </li>
          <li>
            Menjamin Anda akan mendapat uang yang ingin Anda simpan daripada
            yang bisa Anda simpan
          </li>
        </ul>
      </>
    )
  },
  {
    header:
      'Mengapa perokok harus membayar premi lebih dari mereka yang tidak merokok?',
    body: (
      <p>
        Merokok bisa mempengaruhi biaya medis dan juga premi asuransi Anda.
        Biasanya, polis jiwa bertanya apakah Anda telah merokok dalam 12 bulan
        terakhir; jika iya, resiko Anda berbeda dan premi akan lebih tinggi
        untuk perokok.
      </p>
    )
  }
]

export { faqKesehatan, faqJiwa }
