import React, { useState } from 'react';

import AgentCommission from '../../client/modules/agents/components/AgentCommission';

import { idr } from '../../shared/currency';
import { AGENT_CONSTANTS } from 'client/modules/agents/constants/agent.constants';

import { Button } from '@lifepal/lifepal-design-system';

import './CheckoutBox.scss';

const CheckoutBox = ({
  showDeleteButton,
  showButtons,
  showLeads,
  disableButton,
  handleSubmit,
  handleDelete,
  handleSaveLeads,
  submitText,
  token,
  plan,
  totalAmount,
  errorMessage,
  additionalFee,
  isOutstandingPaymentMode,
  outstandingPaymentsAmount,
  isTermOneTime,
  agentCommissionPercentage,
  hasPaymentPending = false,
  hasOrder,
  hideFairBreakdown = false,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <div className="checkout__box">
      {errorMessage && (<p className="color-lp-red">{errorMessage}</p>)}
      {
        !hideFairBreakdown && (
          totalAmount && (
            <div className="checkout__box--info">
              <span className="heading-sm-m mb-0">Total Tagihan</span>
              <span className="ml-2 heading-sm-m" style={{ color: '#1D66DD' }}>
                {
                  isOutstandingPaymentMode
                    ? idr(outstandingPaymentsAmount)
                    : `${idr(totalAmount.amount + additionalFee)} ${!isTermOneTime ? `/ ${totalAmount.period}` : ''}`
                }
              </span>
            </div>
          )
        )
      }

      {
        (!hideFairBreakdown && plan && totalAmount) && (
          <AgentCommission
            plan={plan}
            role={AGENT_CONSTANTS.getAgentByToken(token)}
            totalPremium={totalAmount.amount}
            paymentTerm={totalAmount.period}
            agentCommissionPercentage={agentCommissionPercentage}
          />
        )
      }

      {showButtons && (
        <div className="checkout__box--buttons">
          {showDeleteButton && (
            <Button
              variant="danger"
              size="md"
              onClick={handleDelete}
              type="button"
              disabled={(hasOrder) ? hasPaymentPending ? false : true : false}
            >
              <i className="ion-md-trash" />
            </Button>
          )}
          <Button
            type="submit"
            variant="primary"
            size="md"
            onClick={() => handleSubmit(setSubmitting)}
            disabled={(
              (isOutstandingPaymentMode && !outstandingPaymentsAmount)
                ? true
                : disableButton
            )}
            isLoading={isSubmitting}
            loaderColor="#FFFFFF"
          >
            {submitText}
          </Button>
        </div>
      )}
      {showLeads && (
        <Button
          onClick={handleSaveLeads}
          type="submit"
          variant="primary"
          size="md"
        >
          Simpan Leads
        </Button>
      )}
    </div>
  );
}

export default CheckoutBox;