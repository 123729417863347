import React from 'react';
import classNames from 'classnames';

const BenefitGroup = (props) => {
  const { benefitGroups } = props;

  return benefitGroups.map((benefitGroup) => {
    return benefitGroup.benefits.map((benefit, key) => {
      if (benefit.coverage.key_benefit) {
        let benefitValue = "";
        const timeFrameLimit = `${benefit.coverage.time_frame} ${benefit.coverage.time_limitation}`;

        if (benefit.coverage.as_billed) {
          benefitValue = `Sesuai tagihan ${timeFrameLimit}`;
        } else {
          benefitValue = `${benefit.coverage.value_text} ${timeFrameLimit}`;

          if (benefit.coverage.value_amount) {
            benefitValue =
              benefit.coverage.currency === "USD"
                ? `${usd(benefit.coverage.value_amount)} ${timeFrameLimit}`
                : `${idr(benefit.coverage.value_amount)} ${timeFrameLimit}`;
          }
        }

        return (
          <div className="MainItem--InfoItem" key={key}>
            <span className="MainItem--InfoTitle heading-xs-r">
              <i className="lifepal-check_round" style={{ color: "#4bd0a9" }} />
              {benefit.name}
            </span>
            <span
              className={classNames(
                "MainItem--InfoValue heading-xs-m",
                benefit.coverage.value_text && "capitalize"
              )}
            >
              {benefitValue}
            </span>
          </div>
        );
      }
    });
  });
};

export default BenefitGroup;
