export const dayLib = {
  'Hari Ini': new Date(),
  'Besok': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1),
  'Lusa': new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 2),
  'Pilih Tanggal': new Date()
};

export const scheduleLib = [
  {
    label: 'Hari Ini',
    value: 'Hari Ini',
  },
  {
    label: 'Besok',
    value: 'Besok'
  },
  {
    label: 'Lusa',
    value: 'Lusa',
  },
  {
    label: 'Pilih Tanggal',
    value: 'Pilih Tanggal',
  }
];

export const pendingReasons = [
  {
    label: 'Dalam rapat',
    value: 'Dalam rapat',
  },
  {
    label: 'Masih mengemudi',
    value: 'Masih mengemudi',
  },
  {
    label: 'Ingin membandingkan lebih banyak',
    value: 'Ingin membandingkan lebih banyak',
  },
  {
    label: 'Belum punya mobil',
    value: 'Belum punya mobil',
  },
  {
    label: 'Tidak punya KTP dengan saya',
    value: 'Tidak punya KTP dengan saya',
  },
  {
    label: 'Akan membayar nanti',
    value: 'Akan membayar nanti',
  }
];