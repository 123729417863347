import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import Cleave from 'cleave.js/react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  Button
} from 'reactstrap';

import { cleanPhoneNumber, isPhoneNumberValid } from '../../../lib/phoneNumber';
import utm from '../../../lib/utm';
import { sendTracker } from '../../../lib/tracker';
import { Kronos } from '../../../lib/api';

import 'cleave.js/dist/addons/cleave-phone.id';
import './LeadForm.sass';

const kronosService = new Kronos();

class LeadFormModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    category: PropTypes.string,
    quoteLead: PropTypes.string.isRequired
  }

  _handleCurrencyInput = (e, name, func) => {
    let value = e.target.rawValue
    if (isNaN(value) || value.length === 0) value = '0'
    func(name, value)
  }

  render() {
    const { isOpen, toggle, category, quoteLead } = this.props

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="LeadForm--Modal">
        <ModalHeader toggle={toggle}>
          Dapatkan Perlindungan Anda Sekarang!
        </ModalHeader>
        <ModalBody className="LeadForm--Form">
          <Formik
            initialValues={{
              gender: '',
              age: '',
              budget: '',
              isSalary: false,
              salary: '',
              phone: ''
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)

              try {
                const utmLeads = utm.getAll() || {}
                const { phone, gender, age, budget, salary } = values
                const formattedPhone = phone.replace(/\s+/g, '')

                const result = await kronosService.setLeadsData({
                  phone: formattedPhone,
                  budget: parseInt(values.isSalary ? salary : budget, 10),
                  gender,
                  age: Number(age),
                  utm: utmLeads,
                  category
                });

                // Add field value to cookie
                cookie.set(
                  'QUOTE_FORM',
                  JSON.stringify({ ...values, category: category }),
                  {
                    expires: new Date(
                      new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                    )
                  }
                )

                utm.clear()

                if (!result.isExist)
                  sendTracker(
                    'event',
                    'lead-submit',
                    'quote-lead-submit',
                    quoteLead
                  )

                // await sendTapfiliate(formattedPhone)
              } catch (e) {
                console.log('Failed to send phone number')
              }

              // Clear compare
              cookie.remove('COMPARES')

              toggle()
              toast.info('Terima kasih, kami akan menghubungi anda')
            }}
            validationSchema={Yup.object({
              age: Yup.number().required('Masukkan umur Anda.')
                .min(0, 'Umur harus diatas 0 Tahun')
                .max(80, 'Umur harus dibawah 80 Tahun'),
              isSalary: Yup.bool(),
              gender: Yup.string().required('Silahkan pilih jenis kelamin'),
              budget: Yup.mixed().when('isSalary', {
                is: false,
                then: Yup.number().required('Silahkan masukkan anggaran Anda.')
              }),
              salary: Yup.mixed().when('isSalary', {
                is: true,
                then: Yup.number().required('Silahkan masukkan gaji Anda.')
              }),
              phone: Yup.string()
                .required('Silahkan masukkan nomor telepon')
                .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
            })}
            render={({ values, errors, setFieldValue, isSubmitting }) => (
              <Form>
                <FormGroup tag="fieldset">
                  <legend className="col-form-label">
                    <strong>Jenis Kelamin</strong>
                  </legend>
                  <Field name="gender">
                    {({ field }) => (
                      <FormGroup check inline>
                        <Label check className="radio">
                          <Input type="radio" {...field} value="MALE" />
                          <span>Pria</span>
                        </Label>
                        <Label check className="radio">
                          <Input type="radio" {...field} value="FEMALE" />
                          <span>Wanita</span>
                        </Label>
                      </FormGroup>
                    )}
                  </Field>
                  {errors.gender && (
                    <div className="form-text text-danger">
                      <small>{errors.gender}</small>
                    </div>
                  )}
                </FormGroup>

                <Row>
                  <Col xs={12} lg={6}>
                    <Field name="age">
                      {({ field }) => (
                        <FormGroup>
                          <Label>
                            <strong>Usia Anda</strong>
                          </Label>
                          <Cleave
                            style={{ fontSize: 12 }}
                            className={classNames('form-control', { 'is-invalid': errors.age })}
                            placeholder="Umur Anda"
                            options={{
                              numeral: true,
                              numeralIntegerScale: 2
                            }}
                            {...field}
                          />
                          <FormFeedback>{errors.age}</FormFeedback>
                        </FormGroup>
                      )}
                    </Field>
                  </Col>

                  <Col xs={12} lg={6}>
                    <Field name="phone">
                      {({ field }) => (
                        <>
                          <Label>
                            <strong>Nomor Telepon</strong>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              +62
                            </InputGroupAddon>
                            <Cleave
                              placeholder="No. Telp"
                              options={{
                                delimiter: ' ',
                                blocks: [3, 4, 4],
                                numericOnly: true
                              }}
                              className={classNames(
                                'form-control',
                                errors.phone && 'is-invalid'
                              )}
                              {...field}
                              onChange={e => {
                                setFieldValue(
                                  `phone`,
                                  cleanPhoneNumber(e.target.value)
                                )
                              }}
                            />
                            <FormFeedback>{errors.phone}</FormFeedback>
                          </InputGroup>
                        </>
                      )}
                    </Field>
                  </Col>

                  <Col xs={12}>
                    <Field name="budget">
                      {({ field }) => (
                        <InputGroup
                          className={classNames(
                            'mt-3 mt-lg-0',
                            values.isSalary && 'disabled'
                          )}
                        >
                          <InputGroupAddon addonType="prepend">
                            Rp
                          </InputGroupAddon>
                          <Cleave
                            placeholder="Anggaran Per-Bulan"
                            options={{
                              numeral: true,
                              numeralIntegerScale: 9
                            }}
                            className={classNames(
                              'form-control',
                              errors.budget && 'is-invalid'
                            )}
                            {...field}
                            disabled={values.isSalary}
                            onChange={e =>
                              this._handleCurrencyInput(
                                e,
                                'budget',
                                setFieldValue
                              )
                            }
                          />
                          <FormFeedback>{errors.budget}</FormFeedback>
                        </InputGroup>
                      )}
                    </Field>

                    <Field name="isSalary">
                      {({ field }) => (
                        <FormGroup check className="text-right mb-3 mt-1">
                          <Label check className="checkbox">
                            <Input type="checkbox" {...field} />
                            <span>Saya tidak tahu anggaran saya</span>
                          </Label>
                        </FormGroup>
                      )}
                    </Field>

                    {values.isSalary && (
                      <Field name="salary">
                        {({ field }) => (
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              Rp
                            </InputGroupAddon>
                            <Cleave
                              placeholder="Pendapatan Per-Bulan"
                              options={{
                                numeral: true,
                                numeralIntegerScale: 9
                              }}
                              className={classNames(
                                'form-control',
                                errors.salary && 'is-invalid'
                              )}
                              {...field}
                              onChange={e =>
                                this._handleCurrencyInput(
                                  e,
                                  'salary',
                                  setFieldValue
                                )
                              }
                            />
                            <FormFeedback>{errors.salary}</FormFeedback>
                          </InputGroup>
                        )}
                      </Field>
                    )}
                  </Col>
                </Row>

                <Button
                  color="primary"
                  block
                  className="mt-4 mt-lg-0"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Dapatkan Penawaran
                </Button>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default LeadFormModal
