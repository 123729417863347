import React from 'react'
import './Card.scss'

const Card = (props) => {
	return (
		<div
			className={props.className ? `${props.className} card` : 'card'} onClick={props.onClick}>
			{props.children}
		</div>
	)
}

export { Card }
