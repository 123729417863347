import React from 'react';
import { Container, Navbar } from 'reactstrap';
import classnames from 'classnames';
import Router from 'next/router';
import './Menu.sass';

class MobileNavigation extends React.Component {
  back = (e) => {
    const {
      closeFullScreen,
      setFieldValue,
      name,
      link,
    } = this.props;
    if (!closeFullScreen && !setFieldValue) {
      if (link && link.path && link.alias) {
        // @todo: fix here
        Router.push(link.path, link.alias);
      } else {
        e.preventDefault();
        Router.back();
      }
      return;
    }

    closeFullScreen();

    if (setFieldValue) {
      if (!name) {
        setFieldValue('isFullScreen', false);
      } else {
        setFieldValue(`${name}.isFullScreen`, false);
      }

      window.scrollTo(0, 0);
    }
  }

  render() {
    const { toggleView, showSimpan, agentTheme, isParentPage, isChildrenPage, action, token, className, isMobile } = this.props;

    return (
      <Navbar
        className={classnames('mobileNavBgColor', className, agentTheme && 'agentColor color-white')}
        expand="lg"
        {...!isChildrenPage && { fixed: 'top' }}
      >
        <Container style={{ position: 'relative' }} className="mobileNav d-flex">
          <div className="left">
            {
              !isMobile && (
                <i
                  className={classnames(isParentPage ? 'ion-md-arrow-back' : 'ion-ios-close', agentTheme && 'color-white')}
                  onClick={(e) => !toggleView ? this.back(e) : toggleView(e)}
                />
              )
            }
          </div>
          <p className={classnames(agentTheme && 'color-white', 'heading-sm-b center-item')}>{this.props.title}</p>
          {
            (showSimpan && token) && (
              <p className={classnames(agentTheme && 'color-white', "heading-sm-r box-title button-save right")} onClick={action}>
                Simpan
              </p>
            )
          }
        </Container>
      </Navbar>
    )
  }
}

export default MobileNavigation;
