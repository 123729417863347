import React from 'react';

import get from 'lodash/get';

import { Avatar } from '../../Avatar/Avatar';

import AgentCommission from '../../../client/modules/agents/components/AgentCommission';

import { getPremiumBysupportedPaymentTerm } from 'clientUtils';
import { AGENT_CONSTANTS } from 'client/modules/agents/constants/agent.constants';
import { idr } from '../../../shared/currency';

import './ProductCard.module.scss';

const ProductCard = ({ plan, initialPlan, showPremium, token, agentCommissionPercentage }) => {
  const brand = get(plan, 'product.brand');
  const product = get(plan, 'product');
  const premium = get(plan, 'total_premium', plan.premium);

  const { amount, paymentTerm } = getPremiumBysupportedPaymentTerm(plan);

  return (
    <>
      <Avatar src={get(plan, 'product.brand.logo_url', '')} maxHeight="50px" maxWidth="50px" alt="product-logo" />
      <h2 className="mb-2 heading-sm-m">
        {brand.name} - {product.name} - {plan.name}
      </h2>
      <div className="box-premi">
        {
          showPremium && (
            <div className="product__card">
              <div className="product__card--premium">
                <span>Harga Premi</span>
                <span className="premium__total heading-sm-m">{idr(amount)} / {paymentTerm}</span>
              </div>
              <AgentCommission
                plan={plan}
                role={AGENT_CONSTANTS.getAgentByToken(token)}
                totalPremium={amount}
                paymentTerm={paymentTerm}
                agentCommissionPercentage={agentCommissionPercentage}
              />
              {initialPlan && Object.keys(initialPlan).length > 0 &&
                plan.id === initialPlan.id && (
                  premium !== initialPlan.premium && (
                    <p className="text-error">
                      Ada penyesuaian harga premi karena perubahan jenis kelamin, tanggal lahir, atau benefit tambahan.
                    </p>
                  )
                )}
            </div>
          )
        }
      </div>
    </>
  );
}

export default ProductCard