import './Floating.sass'
import React from 'react'
import classnames from 'classnames'

class Floating extends React.PureComponent { 
  render = () => {
    const { children, compareLength, id, isCompare = false } = this.props;
    return (
      <>
        <div
          className={classnames(
            'FloatingButton',
            'FloatingButton--Opened',
            {'FloatingButton--hasCompare': compareLength > 0}
          )}
          id={id}
        >
          {children}
        </div>
      </>
    )
  }
}

export default Floating;
