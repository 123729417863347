import React, { useState } from "react";

import { Button, Modal, ModalBody } from 'reactstrap';
import { Label, Input } from '../../Form';

import './Rebate.module.scss';

const Rebate = ({ toggleModal, isModalOpen, validateNewPrice, errorMessage }) => {
  const [newPrice, setNewPrice] = useState('');

  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalBody>
        <p className="title-discount">
          Sesuaikan Harga
          <i onClick={toggleModal} className="icon ion-md-close btn-close" />
        </p>
        <Label>Masukkan Harga</Label>

        <div className="rebateModal__wrapper">
          <div className="rebateModal__wrapper--leftCol">
            <Input currency value={newPrice} error={errorMessage} onChange={e => setNewPrice(Number(e.target.rawValue))} />
          </div>
          <div className="rebateModal__wrapper--rightCol">
            <Button
              className="d-flex justify-content-center"
              onClick={() => validateNewPrice(newPrice)} size="lg" color="primary" block
            >
              Sesuaikan
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default Rebate;