import React, { useEffect, useRef, useState } from 'react'
import CallScheduledConsultation from '../../../../../CallScheduledConsultation';
import { Button } from '@lifepal/lifepal-design-system';
import './MainDetail.module.scss';
import { sendDataLayerTracker } from '../../../../../../lib/tracker';
import { CHECK_CIRCLE_SOLID_ICON } from '../../../../../../shared/constants';

export const MainDetail = (props) => {
  const {
    display,
  } = props;

  const [height, setHeight] = useState(0);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const transitionDuration = 500;

  const showDetail = () => {
    setShow(true);
  }

  useEffect(() => {
    if (show) {
      setHeight(ref?.current?.clientHeight);
    }
  }, [show]);

  const hideDetail = () => {
    setHeight(0);
    setTimeout(() => {
      setShow(false);
    }, transitionDuration);
  }

  useEffect(() => {
    if (display) {
      showDetail();
    } else {
      hideDetail();
    }
  }, [display]);

  return (
    <div
      className={`main-item-container`}
      style={{
        height: height + "px",
        transitionDuration: transitionDuration + "ms",
      }}
    >
      <div ref={ref}>
        {
          show && (
            <MainDetailContent {...props} />
          )
        }
      </div>

    </div>
  );
}

const MainDetailContent = (props) => {
  const {
    coverages,
    item,
    onConsultCliked,
    onDetailClick,
    onBuyClick,
    isAgeRequired,
    category,
    classNames,
    onCallmeClicked,
  } = props;

  const handleBuy = () => {
    sendDataLayerTracker({
      event: 'plan_listing_engagement',
      eventCategory: 'plan-listing-engagement',
      eventAction: 'buy-now',
      eventLabel: category,
      insuranceBrand: item?.product?.brand.name,
    });
    onBuyClick();
  }

  return (
    <div
      className="main-item-detail"
    >
      <div>
        {coverages.length > 0 && (
          <div className="detail-coverage">
            <div className="">
              {item.description}
            </div>
            <div className="detail-coverage-list">
              {coverages.map((coverage, keyCoverage) => (
                <div key={keyCoverage} className='detail-coverage-item'>
                  <img src={CHECK_CIRCLE_SOLID_ICON} alt="" />
                  <span>{coverage}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className='consult-panel'>
        <CallScheduledConsultation
          onClick={onCallmeClicked}
          category={category}
        />
      </div>

      <div className="detail-devider" />

      <div className="detail-action">
        {
          !item.is_purchasable ? (
            <>
              <Button color="primary" block onClick={onConsultCliked}>
                Konsultasi Gratis
              </Button>
              <Button style={{ visibility: "hidden" }} />
              <Button style={{ visibility: "hidden" }} />
            </>
          ) : (
            <>
              <Button
                variant="ghost"
                type="button"
                onClick={onDetailClick}
                className='detail-action-btn'
              >
                Lihat Detail
              </Button>
              <Button
                variant="ghost"
                type="button"
                className={`${classNames?.buyButton} detail-action-btn`}
                onClick={handleBuy}
              >
                {isAgeRequired ? "Silahkan pilih umur" : "Beli Langsung"}
              </Button>
            </>
          )
        }
      </div>
    </div>
  );
}