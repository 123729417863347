import React, { useEffect, useState } from "react";
import { withRouter } from "next/router";

import classNames from "classnames";
import PropType from "prop-types";
import Tooltip from "rc-tooltip";
import AgentCommission from "../../../../../../client/modules/agents/components/AgentCommission";

import { idr } from "../../../../../../shared/currency";
import { getPremiumBysupportedPaymentTerm } from "clientUtils";
import { AGENT_CONSTANTS } from "client/modules/agents/constants/agent.constants";
import BenefitGroup from './BenefitGroup';

import "rc-tooltip/assets/bootstrap_white.css";
import "./Main.sass";
import { MainDetail } from "./MainDetail";
import { useMainGroupContext } from "./MainGroup";
import { sendDataLayerTracker, sendTrackerCallMeBack } from "../../../../../../lib/tracker";

const MainItem = (props) => {
  const {
    token,
    item,
    onBuyClick,
    onCompare,
    onDetailClick,
    onAutoFocusAgeClick,
    toggleModal,
    canCompare,
    isAutoMoto,
    isModal,
    callMeHandler,
    router: { query },
    agentCommissionPercentage,
  } = props;
  const { activeId, getUniqueId, setActiveId } = useMainGroupContext();
  const [show, setShow] = useState(false);
  const [uniqueId, setUniqueId] = useState();

  useEffect(() => {
    getUniqueId && setUniqueId(getUniqueId());
  }, [getUniqueId]);

  useEffect(() => {
    if (activeId && activeId !== uniqueId && show) {
      setShow(false);
    }
  }, [activeId]);

  const toggleOpen = () => {
    if (!show){
      sendDataLayerTracker({
        event: 'plan_listing_engagement',
        eventCategory: 'plan-listing-engagement',
        eventAction: 'see-policy',
        eventLabel: query.category,
        insuranceBrand: item?.product?.brand.name,
      });
    }
    setActiveId && setActiveId(show ? undefined : uniqueId);
    setShow(!show);
  }

  // Check age
  let isAgeRequired = false;
  const coverageKeys = Object.keys(item.product.attributes);
  const coverages = [];


  if (item.shouldFilterByAge && !query.age) isAgeRequired = true;

  coverageKeys.forEach((key) => {
    const attributeValue = item.product.attributes[key];

    if (attributeValue === "No") return;

    if (attributeValue === "Yes") {
      coverages.push(key);
      return;
    }

    if (attributeValue === "Optional") {
      coverages.push(`${key} (Opsional)`);
      return;
    }

    if (key !== "Cicilan") {
      coverages.push(`${key} ${attributeValue}`);
      return;
    }
  });

  coverages.sort((a, b) => {
    if (a.includes("Opsional") && !b.includes("Opsional")) {
      return 1;
    } else if (!a.includes("Opsional") && b.includes("Opsional")) {
      return -1;
    } else {
      return 0;
    }
  });

  // Check if the premi  can buy
  let isPremiCanBuy = true;
  let cantBuyLabel = "";

  const premiumData = getPremiumBysupportedPaymentTerm(item, true);

  const pricingText = () => {
    if (!isAutoMoto) {
      return idr(
        item.before_price < premiumData.amount && item.before_price > 0
          ? item.before_price
          : premiumData.amount
      );
    }

    if ([46, 7].includes(item.product.brand.id)) {
      return idr(
        item.before_price < premiumData.amount && item.before_price > 0
          ? item.before_price * 0.95
          : premiumData.amount * 0.95
      );
    } else {
      return idr(
        item.before_price < premiumData.amount && item.before_price > 0
          ? item.before_price * 0.75
          : premiumData.amount * 0.75
      );
    }
  };

  return (
    <div className={classNames("MainItem", !isModal && "MainItem--withBorder")}>
      <header className="MainItem--Header">
        <div className="MainItem--HeaderInfo">
          <div className="product__header--info">
            <div className="product__logo--wrapper">
              <img src={item.product.brand.logo_url} alt="" />
            </div>
          </div>
          <div className="product__header--info plan__premium--details">
            <span className="heading-xs-m w-100">
              {item.product.name} {!isAutoMoto ? `- ${item.name}` : ""}
            </span>
          </div>
          <div className="product__header--info plan__premium--details">
            <div className="heading-sm-b plan__premium--amount">
              <div className="plan__premium--inline">
                {item.before_price > 0 &&
                  premiumData.amount > 0 &&
                  item.before_price !== premiumData.amount && (
                    <span className="before__premium">
                      <span className="strike__through">
                        {idr(
                          item.before_price > premiumData.amount
                            ? item.before_price
                            : premiumData.amount
                        )}{" "}
                        /{item.product.support_monthly ? "Bulan" : "Tahun"}
                      </span>
                      <Tooltip
                        placement="top"
                        overlay="Rata-rata harga premi dipasaran"
                        arrowContent={
                          <div
                            className="rc-tooltip-arrow-inner"
                            style={{ color: "rgba(0, 0, 0, 0.8)" }}
                          />
                        }
                      >
                        <i
                          className="icon ion-md-information-circle ml-2 clickable"
                          style={{ color: "#000" }}
                        />
                      </Tooltip>
                    </span>
                  )}
              </div>
              <div className="plan__payment--terms">
                {pricingText()}
                {" / "}
                {premiumData.paymentTerm.term}
                {isAutoMoto && (
                  <span className="plan__premium--amount_discount">
                    &nbsp;
                    {`(Setelah Diskon ${[46, 7].includes(item.product.brand.id) ? "5%" : "25%"
                      })`}
                    &nbsp;
                  </span>
                )}

                {isAutoMoto && (
                  <p className="plan__copy--call-me">
                    Dapatkan promo extra, klik{" "}
                    <button
                      onClick={() => callMeHandler("callme", "PlanCardLink", item)}
                    >
                      Hubungi Saya!
                    </button>
                  </p>
                )}
              </div>

              <AgentCommission
                plan={item}
                role={AGENT_CONSTANTS.getAgentByToken(token)}
                totalPremium={premiumData.amount}
                paymentTerm={premiumData.paymentTerm}
                agentCommissionPercentage={agentCommissionPercentage}
                isPlanListing
              />
            </div>
          </div>
        </div>
      </header>

      {(item.death_benefits > 0 ||
        (item.benefit_groups &&
          item.benefit_groups.length > 0 &&
          item.death_benefits > 0 &&
          item.death_benefits > 0)) && (
          <div className="MainItem--Info">
            {item.death_benefits > 0 && (
              <div className="MainItem--InfoItem">
                <div className="MainItem--InfoTitle heading-xs-r">
                  <i
                    className="lifepal-check_round"
                    style={{ color: "#4bd0a9" }}
                  />
                  Tunjangan Kematian
                </div>
                <div className="MainItem--InfoValue heading-xs-m">
                  {idr(item.death_benefits)}
                </div>
              </div>
            )}
            {item.benefit_groups.length > 0 &&
              <BenefitGroup benefitGroups={item.benefit_groups} />
            }
          </div>
        )}

      <div className="MainItem--Action__Container">
        <button
          type="button"
          className={show && "active"}
          onClick={toggleOpen}
        >
          <span>Lihat Manfaat Polis</span>
          <i
            className={`${!show ? "lifepal-arrow-down" : "lifepal-arrow-up"}`}
          />
        </button>
        <button
          onClick={onCompare}
          disabled={!canCompare && !item.isSelected}
          className={item.isSelected && "MainItem--Action_compare_active"}
        >
          {item.isSelected && (
            <i
              className="icon ion-md-checkmark"
            ></i>
          )}
          <span>Bandingkan</span>
        </button>
      </div>

      <MainDetail
        display={show}
        category={query.category}
        coverages={coverages}
        item={item}
        onConsultCliked={toggleModal}
        onDetailClick={onDetailClick}
        onCallmeClicked={() => {
          callMeHandler("callme", "PlanCard", item, false)
          sendDataLayerTracker({
            event: 'contact_me',
            eventCategory: 'schedule-consultation',
            eventAction: 'plan-card',
            eventLabel: query.category,
            insuranceBrand: item?.product?.brand.name,  
            scheduledDay: undefined,
            scheduledTime: undefined
          })
        }}
        onBuyClick={
          isAgeRequired
            ? () => {
              window.scrollTo(0, 0);
              onAutoFocusAgeClick();
            }
            : onBuyClick
        }
        isAgeRequired={isAgeRequired}
        classNames={{
          buyButton: !isModal && "MainItem--Action_thirdbtn",
        }}
      />
    </div>
  );
};

MainItem.propTypes = {
  onBuyClick: PropType.func,
  onDetailClick: PropType.func,
  onCompare: PropType.func,
  canCompare: PropType.bool,
  selected: PropType.number,
  item: PropType.shape({
    isSelected: PropType.bool,
    shouldFilterByAge: PropType.bool,
    logo: PropType.string,
    name: PropType.string,
    plan: PropType.string,
    info: PropType.shape({
      premium: PropType.shape({
        dynamic: PropType.bool,
        price: PropType.number,
        type: PropType.string,
      }),
    }),
    promo: PropType.shape({
      title: PropType.string,
      color: PropType.string,
    }),
    description: PropType.string,
    coverages: PropType.arrayOf(PropType.string),
  }),
};

export default withRouter(MainItem);
