import React, { useState } from 'react';

import jsCookie from 'js-cookie';

import { Badge, Button } from '@lifepal/lifepal-design-system';

import dayjs from 'dayjs';
import get from 'lodash/get';

import { Avatar } from '../Avatar/Avatar';
import Modal from '../Modal';
import { CallListView } from '../CallListView/CallListView';
import { QuotationListView } from '../QuotationListView/QuotationListView';
import Loader from '../Loader';
import ScoreCardView from '../../client/modules/agents/components/ScoreCardView/ScoreCardView';

import idr from '../../lib/currencyIdr';
import { policyStatus, leadStatus } from '../../shared/enums';
import { isEmpty } from '../../client/utils/isEmpty';
import { formatMobileNumber } from '../../lib/phoneNumber';
import { QUOTATION_STATUS_MAP, ORDER_STATUS_MAP } from '../../client/utils/quotationType';

import { NextServerAPI } from '../../client/utils/coreServerAPI';

const NotesHistoryView = (props) => {
  return (
    <Modal
      isOpen={props.shouldShowNotesHistory}
      title='Catatan'
      toggle={() => props.setShowingNotesHistory(!props.shouldShowNotesHistory)}
      shouldShowAsBottomSheet
      showModalHeader
    >
      <div className="lead__notes--history">
        {
          props.notesList.length > 0 && (
            <ul className="notes__history--list">
              {
                props.notesList.map((notes, index) => (
                  <li className="notes__item" key={index}>
                    <div className="notes__info">
                      <span className="heading-xs-r">
                        {dayjs(notes.created_at).format('DD/MM/YYYY h:mm A')}
                      </span>
                      <span className="heading-xs-r" style={{ color: '#c2c2c2' }}>Oleh</span>
                      <span className="heading-xs-r">
                        {notes.first_name ? `${notes.first_name} ${notes.last_name}` : '-'}</span>
                    </div>
                    <h3 className="heading-sm-r">
                      {notes.note}
                    </h3>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    </Modal>
  )
};

const TableColumn = ({ type, sendPolicy, loading, phoneNumber, getCallHistoryList }) => {

  const getFormattedDuration = (duration) => {
    if (duration) {
      let minutes = Math.floor(duration / 60);
      let seconds = duration - minutes * 60;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;

      return `${minutes} : ${seconds}`;
    }

    return '00:00';
  }

  const scoreCardCell = ({ value, original }) => {
    if (value) {
      const [shouldShowScoreCard, setShowingScoreCard] = useState(false);
      const duration = getFormattedDuration(original.duration);

      return (
        <>
          <Button
            type="button"
            variant="ghost"
            size="md"
            onClick={(event) => {
              event.stopPropagation();
              setShowingScoreCard(true);
            }}
          >
            <Avatar
              src="/static/images/icons/play.svg"
              loading="lazy"
              alt="audio_control"
            />
          </Button>
          {
            shouldShowScoreCard && (
              <ScoreCardView
                callRecordId={original.id}
                audioLink={value}
                date={original.created_at ? dayjs(original.created_at).format('DD/MM/YYYY h:mm A') : '-'}
                duration={duration}
                phoneNumber={original.phone_number || phoneNumber}
                toggleScoreCard={() => {
                  setShowingScoreCard(!shouldShowScoreCard);
                }}
                getCallHistoryList={getCallHistoryList}
              />
            )
          }
        </>
      )
    }
    return `No Recordings found. Pls, report it to tech team!`;
  }

  const headerByType = {
    'orders': () => ([
      {
        Header: 'Tgl. Pembuatan',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <span>{dayjs(value).format('DD/MM/YYYY')}</span>
        ),
      },
      {
        Header: 'Order ID',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'orderId',
      },
      {
        Header: 'Premi',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'premi',
        Cell: ({ value }) => (
          <span>{idr(value)}</span>
        ),
      },
      {
        Header: 'Nama Pelanggan',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'fullName',
      },
      {
        Header: 'Status Aplikasi',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'status',
        Cell: (props) => {
          const { quotationStatus } = props.value;
          const title = QUOTATION_STATUS_MAP[quotationStatus].title;
          const color = QUOTATION_STATUS_MAP[quotationStatus].color;

          return (
            <div className="DataStatus heading-sm-r" style={{ color }}>{title}</div>
          );
        },
      },
      {
        Header: 'Status Order',
        headerClassName: 'Header',
        className: 'Content',
        accessor: 'status',
        Cell: (props) => {
          const { orderStatus } = props.value;
          const title = ORDER_STATUS_MAP[orderStatus].title;
          const color = ORDER_STATUS_MAP[orderStatus].color;

          return (
            <div className="DataStatus heading-sm-r" style={{ color }}>{title}</div >
          );
        },
      },
      {
        Header: 'Status Polis',
        headerClassName: 'Header',
        className: 'Content custom__style',
        accessor: 'policy',
        Cell: (props) => {
          const { value } = props;
          return (
            <>
              {
                (value && value.status)
                  ? <Badge
                    className="badge__wrapper heading-sm-r"
                    title={get(policyStatus, `${value.status}.label`, '-')}
                    status={get(policyStatus, `${value.status}.color`, 'default')}
                  />
                  : <span>-</span>
              }

            </>
          );
        }
      },
      {
        Header: 'Polis Pelanggan',
        headerClassName: 'Header',
        className: 'Content custom__style',
        accessor: 'policy',
        minWidth: 160,
        Cell: ({ value }) => (
          <Button
            className="button__whatsapp"
            size="lg"
            variant="default"
            disabled={loading || isEmpty(value) || (value.status && value.status !== 'POLICY_ISSUED')}
            onClick={() => sendPolicy(value.id)}
          >
            <i className="lifepal-whatsapp" />
          </Button>
        ),
      }
    ]),
    'agentStatusList': () => ([
      {
        Header: 'Nama TSO',
        headerClassName: 'Header table__header--custom table__status--header sticky',
        className: 'table__title--custom sticky sticky table__status--body',
        accessor: 'name',
        minWidth: 200,
      },
      {
        Header: 'Level',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: 'level_label',
      },
      {
        Header: 'Daily Limit',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: 'daily_limit',
      },
      {
        id: 'Assigned Today',
        Header: 'Assigned Today',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.assigned_today ? `${d.assigned_today}` : 0,
        minWidth: 120,
      },
      {
        id: 'Autodial_Status',
        Header: 'Autodial_Status',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style table__status--body highlighted__column',
        accessor: 'autoDialStatus'
      },
      {
        id: 'In Call',
        Header: 'In Call',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style table__status--body highlighted__column',
        accessor: 'calling_status',
        minWidth: 120,
        Cell: ({ value }) => (
          <Badge
            className="active__badge heading-2xs-r"
            title={(value && value === 'ON_CALL') ? 'Ya' : 'Tidak'}
            status={(value && value === 'ON_CALL') ? 'success' : 'default'}
          />
        ),
      },
      {
        Header: 'Contact Rate',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style highlighted__column table__status--body',
        accessor: 'presentRate',
        minWidth: 120,
      },
      {
        id: 'Talk Time',
        Header: 'Talk Time',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style highlighted__column table__status--body',
        minWidth: 120,
        accessor: d => {
          let duration = '00:00:00';
          if (d.total_call_duration) {
            duration = new Date(d.total_call_duration * 1000).toISOString().substr(11, 8);
          }
          return (
            <Badge
              className="active__badge heading-2xs-r"
              title={duration}
              status='default'
            />
          )
        }
      },
      {
        id: 'Total Toggle_On Time',
        Header: 'Total Toggle_On Time',
        minWidth: 150,
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style table__status--body highlighted__column',
        accessor: d => {
          let duration = '00:00:00';
          if (d.total_auto_dial_duration) {
            duration = new Date(d.total_auto_dial_duration * 1000).toISOString().substr(11, 8);
          }
          return (
            <Badge
              className="active__badge heading-2xs-r"
              title={duration}
              status='default'
            />
          )
        }
      },
      {
        id: 'New',
        Header: 'New',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.new_count ? `${d.new_count}` : 0,
      },
      {
        id: 'Sales Unpaid',
        Header: 'Sales Unpaid',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.sales_unpaid ? `${d.sales_unpaid}` : 0,
        minWidth: 120,
      },
      {
        id: 'Tidak Diangkat',
        Header: 'Tidak Diangkat',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.unreachable ? `${d.unreachable}` : 0,
        minWidth: 120,
      },
      {
        id: 'Callback',
        Header: 'Callback',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.call_back ? `${d.call_back}` : 0,
        minWidth: 120,
      },
      {
        id: 'Interest',
        Header: 'Interest',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.interests ? `${d.interests}` : 0,
        minWidth: 120,
      },
      {
        id: 'Suspended',
        Header: 'Suspended',
        headerClassName: 'Header table__header--custom table__status--header',
        className: 'Content custom__style table__status--body',
        accessor: d => d.suspended ? `${d.suspended}` : 0,
        minWidth: 120,
      },
      {
        id: 'Closed Today',
        Header: 'Closed Today',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style highlighted__column table__status--body',
        accessor: d => d.closed_today ? `${d.closed_today}` : 0,
      },
      {
        id: 'Rejected Today',
        Header: 'Rejected Today',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style highlighted__column table__status--body',
        accessor: d => d.rejected_today ? `${d.rejected_today}` : 0,
      },
      {
        id: 'Orders Created',
        Header: 'Orders Created',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style table__status--body highlighted__column',
        accessor: d => d.orders_created ? `${d.orders_created}` : 0,
      },
      {
        id: 'Orders Paid',
        Header: 'Orders Paid',
        headerClassName: 'Header table__header--custom table__status--header highlighted__column',
        className: 'Content custom__style table__status--body highlighted__column',
        accessor: d => d.orders_paid ? `${d.orders_paid}` : 0,
      },
    ]),
    'callList': () => ([
      {
        id: 'Lead ID',
        Header: 'Lead ID',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'table__status--body',
        minWidth: 120,
        accessor: 'lead_id',
        Cell: (props) => {
          return (
            <span>{props.original.lead_id.split('-').shift()}</span>
          );
        }
      },
      {
        id: 'Status',
        Header: 'Status',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style table__status--body',
        accessor: 'lead.status',
        minWidth: 100,
        Cell: ({ value }) => (
          <span
            style={{ color: leadStatus[value] ? leadStatus[value].color : '#333' }}
          >
            {leadStatus[value] ? leadStatus[value].status : value}
          </span>
        ),
      },
      {
        id: 'Durasi',
        Header: 'Durasi',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style table__status--body',
        minWidth: 80,
        accessor: d => getFormattedDuration(d.duration),
      },
      {
        id: 'Waktu Panggilan',
        Header: 'Waktu Panggilan',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style table__status--body',
        minWidth: 120,
        accessor: d => d.created_at ? dayjs(d.created_at).format('DD/MM/YYYY h:mm A') : '-',
      },
      {
        Header: 'Notes History',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style custom__audio--button table__status--body',
        minWidth: 80,
        Cell: (props) => {
          const [shouldShowNotesHistory, setShowingNotesHistory] = useState(false);
          const [notesHistory, setNotesHistory] = useState([]);
          return (
            <>
              <Button
                type="button"
                variant="ghost"
                size="md"
                onClick={async (event) => {
                  event.stopPropagation();
                  if (!shouldShowNotesHistory) {
                    const notesList = await NextServerAPI({
                      path: `/api/leads/notes/${props.original.lead.id}`,
                      method: 'GET',
                      AuthorizationToken: `Bearer ${jsCookie.get('token')}`
                    });
                    setNotesHistory(notesList.data);
                  }
                  setShowingNotesHistory(!shouldShowNotesHistory);
                }}
              >
                <Avatar
                  src="/static/images/icons/reader.svg"
                  loading="lazy"
                  alt="audio_control"
                  height="16px"
                  width="30px"
                />
              </Button>
              { shouldShowNotesHistory && (
                <NotesHistoryView
                  shouldShowNotesHistory={shouldShowNotesHistory}
                  setShowingNotesHistory={setShowingNotesHistory}
                  notesList={notesHistory}
                />
              )
              }
            </>
          )
        },
      },
      {
        id: 'Rating',
        Header: 'Penilaian',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style custom__audio--button table__status--body',
        Cell: ({ original }) => (
          <span><i className="icon ion-ios-star mr-1" />{original.average_rating ? original.average_rating.toFixed(1) : '-'}</span>
        ),
      },
      {
        Header: '',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'Content custom__style custom__audio--button table__status--body',
        accessor: 'download_link',
        minWidth: 120,
        Cell: scoreCardCell,
      }
    ]),
    'recordingList': () => ([
      {
        id: 'Waktu Panggilan',
        Header: 'Waktu Panggilan',
        headerClassName: 'Header table__header--custom',
        className: 'Content custom__style',
        accessor: d => d.created_at ? dayjs(d.created_at).format('DD/MM/YYYY h:mm A') : '-',
      },
      {
        id: 'Durasi',
        Header: 'Durasi',
        headerClassName: 'Header table__header--custom',
        className: 'Content custom__style',
        accessor: d => getFormattedDuration(d.duration),
      },
      {
        id: 'Rating',
        Header: 'Penilaian',
        headerClassName: 'Header table__header--custom',
        className: 'Content custom__style',
        Cell: ({ original }) => (
          <span><i className="icon ion-ios-star mr-1" />{original.average_rating ? original.average_rating.toFixed(1) : '-'}</span>
        ),
      },
      {
        Header: '',
        headerClassName: 'Header table__header--custom',
        className: 'Content custom__style',
        accessor: 'download_link',
        Cell: scoreCardCell,
      },
    ]),
    'leadList': () => ([
      {
        id: 'Lead ID',
        Header: 'Lead ID',
        headerClassName: 'table__header--custom bg-white agent__detail--view',
        className: 'table__status--body',
        accessor: 'id',
        minWidth: 30,
        Cell: (props) => {
          return (
            <span>{props.original.id.split('-').shift()}</span>
          );
        }
      },
      {
        id: 'Customer Name',
        Header: 'Customer Name',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'table__status--body',
        minWidth: 120,
        Cell: (props) => {
          return (
            <span>{props.original.first_name ? `${props.original.first_name} ${props.original.last_name}` : '-'}</span>
          );
        }
      },
      {
        id: 'Status',
        Header: 'Leads Status',
        headerClassName: 'table__header--custom bg-white agent__detail--view',
        className: 'Content custom__style table__status--body',
        accessor: 'status',
        minWidth: 30,
        Cell: ({ value }) => (
          <span
            style={{ color: leadStatus[value] ? leadStatus[value].color : '#333' }}
          >
            {leadStatus[value] ? leadStatus[value].status : value}
          </span>
        ),
      },
      {
        Header: 'Notes History',
        headerClassName: 'table__header--custom bg-white agent__detail--view',
        className: 'Content custom__style table__status--body',
        minWidth: 30,
        Cell: (props) => {
          const [shouldShowNotesHistory, setShowingNotesHistory] = useState(false);
          const [notesHistory, setNotesHistory] = useState([]);
          return (
            <>
              <Button
                type="button"
                variant="ghost"
                size="md"
                onClick={async (event) => {
                  event.stopPropagation();
                  if (!shouldShowNotesHistory) {
                    const notesList = await NextServerAPI({
                      path: `/api/leads/notes/${props.original.id}`,
                      method: 'GET',
                      AuthorizationToken: `Bearer ${jsCookie.get('token')}`
                    });
                    setNotesHistory(notesList.data);
                  }
                  setShowingNotesHistory(!shouldShowNotesHistory);
                }}
                className="notes__history"
              >
                <Avatar
                  src="/static/images/icons/reader.svg"
                  loading="lazy"
                  alt="audio_control"
                  height="16px"
                  width="30px"
                />
              </Button>
              { shouldShowNotesHistory && (
                <NotesHistoryView
                  shouldShowNotesHistory={shouldShowNotesHistory}
                  setShowingNotesHistory={setShowingNotesHistory}
                  notesList={notesHistory}
                />
              )
              }
            </>
          )
        },
      },
      {
        Header: 'Rekaman',
        headerClassName: 'table__header--custom bg-white agent__detail--view',
        className: 'Content custom__style custom__audio--button table__status--body',
        accessor: 'phone_number',
        minWidth: 30,
        Cell: (props) => {
          const [shouldShowCallHistory, setShowingCallHistory] = useState(false);
          const [callHistory, setCallHistory] = useState([]);

          let formattedNumber = '-';
          if (props.original && props.original.phone_number) {
            formattedNumber = formatMobileNumber(props.original.phone_number);
          }

          const getCallHistoryList = async () => {
            const callHistoryList = await NextServerAPI({
              path: `/api/agents/calls/phone-number/${props.original.phone_number}`,
              method: 'GET',
              AuthorizationToken: `Bearer ${jsCookie.get('token')}`
            });
            setCallHistory(callHistoryList.data);
          };

          return (
            <>
              <Button
                type="button"
                variant="ghost"
                size="md"
                onClick={async (event) => {
                  event.stopPropagation();
                  if (!shouldShowCallHistory) {
                    await getCallHistoryList();
                    setShowingCallHistory(!shouldShowCallHistory);
                  }
                }}
              >
                <Avatar
                  src="/static/images/icons/play.svg"
                  loading="lazy"
                  alt="audio_control"
                />
              </Button>
              {
                shouldShowCallHistory && (
                  <CallListView
                    shouldOpenModal={shouldShowCallHistory}
                    toggleModal={() => setShowingCallHistory(!shouldShowCallHistory)}
                    title={formattedNumber}
                    callList={callHistory}
                    getCallHistoryList={getCallHistoryList}
                  />
                )
              }
            </>
          );
        },
      },
      {
        Header: '',
        headerClassName: 'agent__detail--view table__header--custom',
        className: 'table__title--custom Content custom__style table__status--body',
        accessor: 'phone_number',
        minWidth: 120,
        Cell: (props) => {
          const [shouldShowQuotationsHistory, setShowingQuotationsHistory] = useState(false);
          const [quotations, setQuotations] = useState([]);
          const [isLoadingQuotations, setLoadingQuotations] = useState(false);

          const getQuotationsByPhoneNumber = async () => {
            setLoadingQuotations(true);

            const quotations = await NextServerAPI({
              path: `/api/leads/quotations/${props.original.phone_number}`,
              method: 'GET',
              AuthorizationToken: `Bearer ${jsCookie.get('token')}`
            });

            if (quotations && quotations.data && quotations.data.allData) {
              setQuotations(quotations.data.allData);
            }

            setLoadingQuotations(false);
            setShowingQuotationsHistory(!shouldShowQuotationsHistory);
          };

          return (
            <>
              {
                isLoadingQuotations
                  ? <div className="ml-3">
                    <Loader color="primary" />
                  </div>
                  : <div onClick={getQuotationsByPhoneNumber}>
                    <span>Lihat Order</span>
                  </div>
              }
              {
                shouldShowQuotationsHistory && (
                  <QuotationListView
                    toggle={() => setShowingQuotationsHistory(!shouldShowQuotationsHistory)}
                    quotationList={quotations}
                    phoneNumber={props.original.phone_number}
                  />
                )
              }
            </>
          );
        },
      },

    ])
  };

  return headerByType[type]();
}

export default TableColumn;
