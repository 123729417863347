import React from 'react';

import './Shimmer.scss';

export const Shimmer = ({ id, className, offsetConfig }) => (
	<section className={`shimmer ${className}`} id={`shimmer_${id}`} style={offsetConfig ? offsetConfig : {}}>
		<article className="background">
			<div className="a"></div>
			<div className="b"></div>
			<div className="c"></div>
			<div className="d"></div>
			<div className="e"></div>
			<div className="f"></div>
			<div className="g"></div>
			<div className="h"></div>
			<div className="i"></div>
			<div className="j"></div>
			<div className="k"></div>
			<div className="l"></div>
		</article>
	</section>
);

export default Shimmer;
