import React, { useState } from 'react';

import Router from 'next/router';

import classNames from 'classnames';
import dayjs from 'dayjs';

import { Card } from '../Card/Card';

import { Button } from '@lifepal/lifepal-design-system';

import { GenderPlaceholder } from '../../shared/enums';
import { getQuotationType, getPaymentTermLabel, userActivity } from 'clientUtils';
import { getPaymentMethodLabel, getPaymentTerm } from '../../lib/payment';
import currencyIdr from '../../lib/currencyIdr';

import './Policy.scss';

export const Policy = (props) => {
  const {
    quotationInfo,
    sendWAMessage,
    redirectQuotation,
    leadPhoneNumber,
    dispatch,
    token,
    organizationName,
    showActionButtons,
    isTemplateSending,
  } = props;
  const { type, path, displayName, statusColor } = getQuotationType(quotationInfo, leadPhoneNumber);

  const [isQuotationOpen, setQuotationOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const trackUserEvent = (type) => {
    const event = type === 'whatsapp'
      ? userActivity.constants.sendWA
      : userActivity.constants.continueApplication;

    userActivity.track(event, {
      'Insurance Category': quotationInfo.category,
      'Lead id': localStorage.getItem('queueId'),
    });
  }

  const handleSubmit = () => {
    if (type === 'renewal') {
      setSubmitting(true);
      dispatch({
        type: 'DUPLICATE_ORDER',
        payload: {
          token,
          orderHumanId: quotationInfo.orderHumanId
        },
        callback: (response) => {
          Router.push(
            `/checkout/[id]`,
            `/checkout/${response.data.quotationId}`,
            { shallow: true },
          );
          setSubmitting(false);
        },
      });
      return;
    }

    trackUserEvent('application');
    redirectQuotation(quotationInfo);
  }

  let voucherDiscountAmount = 0;
  let discountPercentage = 0;

  if (quotationInfo.orderItems) {
    const voucherDiscount = quotationInfo.orderItems.find(item => item.name.includes('DISCOUNT VOUCHER'));
    const paymentTermDiscount = quotationInfo.orderItems.find(item => item.name === 'Payment term discount');
    const premium = quotationInfo.orderItems.find(item => item.category === 'PREMIUM');

    let beforeVoucherDiscount;
    if (premium && premium.amount) {
      beforeVoucherDiscount = premium.amount;

      if (paymentTermDiscount && paymentTermDiscount.amount) {
        beforeVoucherDiscount += paymentTermDiscount.amount;
      }

      if (quotationInfo.recurringCount && quotationInfo.paymentTerm) {
        const paymentTermMap = getPaymentTerm(quotationInfo.recurringCount);
        beforeVoucherDiscount = beforeVoucherDiscount / paymentTermMap[quotationInfo.paymentTerm].counter;
      }
    }

    if (voucherDiscount && voucherDiscount.amount) {
      voucherDiscountAmount = Math.abs(voucherDiscount.amount);

      if (quotationInfo.recurringCount && quotationInfo.recurringCount <= 12) {
        const paymentTermMap = getPaymentTerm(quotationInfo.recurringCount);
        voucherDiscountAmount = Math.abs(voucherDiscount.amount / paymentTermMap[quotationInfo.paymentTerm].counter);
      }

      discountPercentage = ((Math.abs(voucherDiscountAmount) / beforeVoucherDiscount) * 100).toFixed(1);
    }
  }

  const totalAmount = quotationInfo.executionAmount || quotationInfo.totalAmount;
  const paymentTerm = getPaymentTermLabel(quotationInfo.recurringCount, quotationInfo.paymentTerm, true);
  const paymentTermLabel = (quotationInfo.recurringCount && quotationInfo.recurringCount > 12)
    ? paymentTerm.termWithDue
    : paymentTerm.term;

  let isWhatsappDisabled = false;
  if (quotationInfo.paymentMethod && quotationInfo.paymentProvider) {
    isWhatsappDisabled = (
      quotationInfo.paymentMethod.includes('RECURRING') ||
      (quotationInfo.paymentMethod === 'AUTODEBIT' && quotationInfo.paymentProvider === 'AUTODEBIT')
    );
  }

  return (
    <Card className="policy__info">
      <div
        className={classNames(
          'policy__info--header',
          isQuotationOpen && 'active',
          type === 'expired' && 'expired',
          type === 'renewal' && 'renewal',
        )}
        onClick={() => setQuotationOpen(!isQuotationOpen)}
      >
        <i className={classNames('header__icon', isQuotationOpen ? 'lifepal-arrow-up' : 'lifepal-arrow-down')} />
        <h5 className="header__title heading-sm-m">
          {quotationInfo.mainInsured ? quotationInfo.mainInsured.name : quotationInfo.name}
        </h5>
        <div className="header__info">
          <span className="header__info--time">
            <i className="lifepal-time" />
            <span className="heading-xs-r">
              {dayjs(quotationInfo.createdAt).format('DD/MM/YYYY h:mm A')}
            </span>
          </span>
          <div className="header__info--status" style={{ backgroundColor: statusColor }}>
            <span className="status__policy">{displayName}</span>
          </div>
        </div>
      </div>
      <div className={classNames('policy__info--body', isQuotationOpen && 'active')}>
        <div className="body__info">
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Polis</span>
            <span className="policy__value">
              {
                quotationInfo.productName
                  ? `${quotationInfo.productName} ${quotationInfo.planName ? `- ${quotationInfo.planName}` : ''}`
                  : '-'
              }
            </span>
          </div>
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Premi</span>
            <div className="policy__value">
              <span>
                {
                  (quotationInfo.recurringCount > 12)
                    ? `${currencyIdr((totalAmount) - voucherDiscountAmount)} / ${paymentTermLabel}`
                    : `${currencyIdr(totalAmount)} / ${paymentTermLabel}`
                }
              </span>
              {
                (quotationInfo.executionAmount > 0 && voucherDiscountAmount > 0 && discountPercentage) && (
                  <div className="policy__value--discount">
                    <span className="strikethrough">
                      {
                        (quotationInfo.recurringCount > 12)
                          ? `${currencyIdr(quotationInfo.executionAmount)} / ${paymentTermLabel}`
                          : `${currencyIdr(totalAmount + voucherDiscountAmount)} / ${paymentTermLabel}`
                      }
                    </span>
                    <span className="percent"> ({discountPercentage}%)</span>
                  </div>
                )
              }
            </div>
          </div>
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Order ID</span>
            <span className="policy__value">
              {
                quotationInfo.orderHumanId ? quotationInfo.orderHumanId : '-'
              }
            </span>
          </div>
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Pembayaran</span>
            <span className="policy__value">
              {
                (quotationInfo.paymentMethod && quotationInfo.paymentProvider)
                  ? getPaymentMethodLabel(quotationInfo.paymentMethod, quotationInfo.paymentProvider)
                  : '-'
              }
            </span>
          </div>
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Tertanggung</span>
            <span className="policy__value">
              {
                (
                  quotationInfo.mainInsured &&
                  quotationInfo.mainInsured.name &&
                  quotationInfo.mainInsured.gender
                )
                  ? `
                    ${quotationInfo.mainInsured.name}, 
                    ${GenderPlaceholder[quotationInfo.mainInsured.gender]}, 
                    ${quotationInfo.mainInsured.age} Tahun
                  `
                  : '-'
              }
            </span>
          </div>
          {
            type === 'renewal' && (
              <div className="body__info--policy">
                <span className="policy__key heading-sm-m">Tanggal Kadaluarsa</span>
                <span className="policy__value">
                  {
                    (quotationInfo.policy && quotationInfo.policy.expireDate)
                      ? dayjs(quotationInfo.policy.expireDate).format('DD MMMM YYYY')
                      : '-'
                  }
                </span>
              </div>
            )
          }
          {
            type === 'expired' && (
              <>
                <div className="body__info--policy">
                  <span className="policy__key heading-sm-m">Tanggal Jatuh Tempo</span>
                  <span className="policy__value">
                    {
                      quotationInfo.dueDate
                        ? dayjs(quotationInfo.dueDate).format('DD MMMM YYYY')
                        : '-'
                    }
                  </span>
                </div>
                <div className="body__info--policy">
                  <span className="policy__key heading-sm-m">Tanggal Terakhir Bayar</span>
                  <span className="policy__value">
                    {
                      quotationInfo.lastPaymentAt
                        ? dayjs(quotationInfo.lastPaymentAt).format('DD MMMM YYYY')
                        : '-'
                    }
                  </span>
                </div>
              </>
            )
          }
          <div className="body__info--policy">
            <span className="policy__key heading-sm-m">Dokumen</span>
            <span className="policy__value">
              <div className="policy__value--documents">
                <div
                  className="documents__item"
                  style={
                    quotationInfo.fotoKtp
                      ? { backgroundImage: `url(${quotationInfo.fotoKtp})` }
                      : {}
                  }
                >
                  <div className="documents__item--label">KTP</div>
                  {
                    !quotationInfo.fotoKtp && (
                      <span>Tidak Ada</span>
                    )
                  }
                </div>
                <div
                  className="documents__item"
                  style={
                    quotationInfo.fotoStnk
                      ? { backgroundImage: `url(${quotationInfo.fotoStnk})` }
                      : {}
                  }
                >
                  <div className="documents__item--label">STNK</div>
                  {
                    !quotationInfo.fotoStnk && (
                      <span>Tidak Ada</span>
                    )
                  }
                </div>
              </div>
            </span>
          </div>
        </div>
        {
          showActionButtons && (
            <div className="body__action">
              <Button
                variant="primary"
                size="md"
                disabled={!path}
                type="button"
                className="body__action--button heading-sm-r"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                loaderColor="#FFFFFF"
              >
                {type === 'renewal' ? 'Perbaharui' : 'Lanjutkan Proses'}
              </Button>
              <Button
                variant="default"
                size="md"
                disabled={!path || isWhatsappDisabled}
                type="button"
                isLoading={isTemplateSending}
                className="body__action--button whatsapp heading-sm-r"
                onClick={() => {
                    trackUserEvent('whatsapp');
                    sendWAMessage(quotationInfo);
                  }
                }
              >
                <span className="lifepal-whatsapp" />
                Kirim Pesan
              </Button>
            </div>
          )
        }
      </div>
    </Card>
  );
};

export default { Policy };
