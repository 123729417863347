import React, { useState, useEffect } from 'react';

import classNames from 'classnames';

import './Switch.module.scss';

export const Switch = ({
  emitSwitchStatus,
  defaultStatus,
}) => {

  const [switchStatus, setSwitchStatus] = useState({ status: 'stopped', value: false });
  const [switchClass, setSwitchClass] = useState('switch-off');

  const switchMap = {
    dialing: {
      value: 'switch-on', //yellow
    },
    waiting: {
      value: 'switch-pause', //grey
    },
    connected: {
      value: 'switch-connected', //green
    },
    stopped: {
      value: 'switch-off', //red
    },
  }

  useEffect(() => {
    if (switchStatus && switchStatus.status) {
      setSwitchClass(switchMap[switchStatus.status].value);
    }
  }, [switchStatus]);

  useEffect(() => {
    setSwitchStatus(defaultStatus);
  }, [defaultStatus]);

  useEffect(() => {
    setSwitchStatus(defaultStatus);
  }, []);

  return (
    <div className="d-flex">
      <div
        className={
          classNames(
            'switch',
            switchClass,
          )
        }
      >
        <input
          type="checkbox"
          id="switch__checkbox"
          checked={switchStatus.value}
          onChange={(event) => {
            event.persist();
            setSwitchStatus({
              status: event.target.checked ? 'dialing' : 'stopped',
              value: event.target.checked,
            });
            emitSwitchStatus(event.target.checked);
          }}
        />
        <label htmlFor="switch__checkbox" className="switch__label">
          <span className="switch__circle"></span>
        </label>
      </div>
    </div>
  )
};
