import { Button, Row, Col } from 'reactstrap';
import Router from 'next/router';

import './OVOPayment.scss';

const OVOPayment = ({ kind }) => {
  return (
    <div>
      <div className="text-head mb-3">
        <span className="lifepal-check_round mr-2" />
        <span className="title">Notifikasi Pembayaran Sudah Dikirim</span>
      </div>
      <div className="mb-3 mt-2">
        <Row>
          <Col md={5}>
            <img src="/static/images/ovo.png" alt="ovo__payment" />
          </Col>
          <Col md={7}>
            <p className="mt-2">
              Pembayaran sudah dikirim, <strong>harap cek notifikasi pada kanan atas halaman OVO Anda</strong>
              {' '}untuk dapat menyelesaikan pembayaran.
              </p>
            <Button
              block
              color="primary"
              onClick={() => {
                kind === 'mobil-tax'
                  ? Router.push('/digital-thank-you')
                  : Router.push('/thank-you')
              }}
            >
              Saya Mengerti
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OVOPayment;