import React from 'react';
import { Button, Modal, Row, Col, ModalBody } from 'reactstrap';

import './BlacklistedKTP.scss';

const BlacklistedKTP = ({ toggleModal, isModalOpen, redirectPlanListing }) => {
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
      <ModalBody>
        <Row>
          <Col className="item__position--head" xs={12} sm={2}>
            <img src="/static/images/icons/close-circle.png" alt="close-circle" height="25" width="25" />
          </Col>
          <Col xs={12} sm={10}>
            <p className="heading-lg-b">Asuransi Ini Tidak Dapat Melindungi Tertanggung Ini.</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} sm={4} className="item__position--body">
            <img src="/static/images/blacklisted-ktp.png" alt="close-circle" />
          </Col>
          <Col xs={12} sm={8}>
            <p>
              Kami tidak dapat mengajukan aplikasi ini. Anda dapat memilih produk asuransi kami yang lain melalui tombol dibawah.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} className="item__position--foot">
            <Button block color="primary" outline onClick={toggleModal}>
              Tutup
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button block color="primary" onClick={redirectPlanListing}>
              Lihat Produk Untuk Saya
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

export default BlacklistedKTP;