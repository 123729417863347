import { quotationsRest } from '../../client';

export class Quotations {
  constructor(token) {
    this.token = token;
  }

  async getQuotations(quotationId, buyerPhone) {
    let url = `/quotations/?quotationId=${quotationId}`;

    if (buyerPhone) {
      url += `&buyerPhone=${buyerPhone}`;
    }

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      }
    });
  }

  async postQuotations(payload, buyerPhone) {
    let url = `/quotations/`;

    if (buyerPhone) {
      url += `?buyerPhone=${buyerPhone}`;
    }

    return await quotationsRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
      data: payload,
    });
  }

  async editMember(payload) {
    const url = '/quotations/policy-members/';

    return await quotationsRest(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
      data: payload,
    });
  }

  async deleteMember(personId) {
    const url = `/quotations/policy-members/`;

    return await quotationsRest(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
      data: {
        personId: personId
      }
    });
  }

  async getHistory() {
    const filter = { stages: ["CREATE_QUOTATION", "POLICY_INFO", "DECLARATION", "BUYER_INFO"] };
    const query = JSON.stringify(filter);

    const url = `/quotations/history/?limit=100&filter=${query}`;

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }

  async getQuotationHistory(planId, userId) {
    const filter = {
      "stages": ["CREATE_QUOTATION", "POLICY_INFO", "DECLARATION", "BUYER_INFO"],
      "planId": planId,
    };

    if (userId) {
      filter["createdBy"] = userId;
    }

    const filterQuery = JSON.stringify(filter);
    const url = `/quotations/history/?limit=100&filter=${filterQuery}`;

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }

  async savePerson(data) {
    const url = '/quotations/persons/';

    return await quotationsRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
      data,
    });
  }

// @todo: rename to `getUserProfile` for clarity
  async getPersonByUserId() {
    const url = '/quotations/user/profile/';

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }

  async getCreatedPerson() {
    const url = '/quotations/user/persons/';

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }

  async changeStatus(id, payload) {
    const url = `/quotations/history/?quotationId=${id}`;

    return await quotationsRest(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
      data: payload,
    });
  }

  async validateKtp(identityNumber) {
    const url = `/quotations/validate-ktp/?identityNumber=${identityNumber}`;

    return await quotationsRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }
}
