import React, { useState } from 'react';
import './CollapsibleAccordionBox.sass';

const CollapsibleAccordionBox = ({ policy, key }) => {
  const [open, setOpen] = useState(true);

  return (
    <div className='collapsible__accordion__container' key={`collapsible__accordion__box-${key}`}>
      <div className='title'>
        <h2>{policy.title}</h2>
        <button type='button' onClick={() => setOpen(!open)}>
          <img 
            className={open && 'open'}
            alt="x"
            loading='lazy'
            src='/static/images/icons/close-black.svg' 
          />
        </button>
      </div>
      <div className={`description${open ? '--open' : ''}`}>
        {open && (<span dangerouslySetInnerHTML={{ __html: policy.description }} />)}
      </div>
    </div>
  );
};

export default CollapsibleAccordionBox;
