import React from 'react';

const CardContainer = (props) => {
  return(
    <div className="card__container" style={props.style}>
      {props.children}
    </div>
  )
}

export default CardContainer;