import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button,
  ModalFooter,
} from 'reactstrap'
import Router from 'next/router'


import 'cleave.js/dist/addons/cleave-phone.id'
import currencyIdr from '../../../lib/currencyIdr'

import './Draft.module.scss'

class DraftModal extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    selectedPlan: PropTypes.object,
    phoneNumber: PropTypes.string,
    incompleteData: PropTypes.array,
    proceedWithNewApplication: PropTypes.func,
  }

  continueExistingApp = (quotationId) => {
    const { phoneNumber } = this.props;

    Router.push(
      `/application/[id]?phoneNumber=${phoneNumber}`,
      `/application/${quotationId}?phoneNumber=${phoneNumber}`,
      {shallow: true}
    );
  }

  render() {
    const { isOpen, toggle, selectedPlan, incompleteData, proceedWithNewApplication } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className="LeadForm--Modal">
        <ModalHeader toggle={toggle}>
          Lanjutkan Aplikasi?
        </ModalHeader>
        <ModalBody className="LeadForm--Form">
          <Row className="d-flex align-items-center">
            <Col xs="6" sm="6" md="3">
              <img
                width="100%"
                src={selectedPlan.product && selectedPlan.product.brand.logo_url}
              />
            </Col>
            <Col xs="6" sm="6" md="9">
              <p className="heading-sm-b mb-0">{selectedPlan.product && selectedPlan.product.name} - {selectedPlan.name}</p>
              <p className="mb-0">Premium: {currencyIdr(selectedPlan.premium)}</p>
            </Col>
          </Row>
          <hr />
          <p>Apakah Anda ingin melanjutkan pengajuan dengan polis yang sama?</p>
          <p className="heading-sm-b">Pengajuan Di Polis Yang Sama</p>
          <div style={{ height: '30vh', overflow: 'auto' }}>
            {incompleteData.map(d =>
              <div key={d.quotationId} style={{ borderRadius: 4, border: 'solid 1px #dfdfdf', width: '100%', padding: 20, marginBottom: 10 }}>
                <Row>
                  <Col xs="12" sm="12" md="4">
                    <span>Nama Tertanggung</span>
                    <p className="heading-sm-b mb-0 capitalize">{d.name ? d.name : 'N/A'}</p>
                  </Col>
                  <Col xs="12" sm="12" md="4">
                    <span>Jumlah Peserta</span>
                    <p className="heading-sm-b mb-0">-</p>
                  </Col>
                  <Col xs="12" sm="12" md="4">
                    <Button block color="primary" onClick={() => this.continueExistingApp(d.quotationId)}>Lanjutkan</Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => proceedWithNewApplication()}
            color="default" outline block className="button__submit"
          >
            Saya Ingin Membuat Polis Baru
          </button>
        </ModalFooter>
      </Modal >
    )
  }
}

export default DraftModal
