/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/prefer-default-export */
import { userAuthRest } from '../../client';
import qs from 'query-string';

export class UserAuth {
  async resetPassword(payload, token) {
    const url = '/user/password/reset/';

    return await userAuthRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      data: payload,
    });
  }

  async changePassword(payload, token) {
    const url = '/user/password/change/';

    return await userAuthRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      data: payload,
    });
  }

  async udpateUserInfo(payload, token, userId) {
    const url = `/user/?id=${userId}`;

    return await userAuthRest(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
      data: payload,
    });
  }

  async getUserInfo(userId, token) {
    const url = `/user/?id=${userId}`;

    return await userAuthRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token,
      },
    });
  }

  async logInUser(payload) {
    const url = '/user/login/';

    return await userAuthRest(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: payload,
    });
  }

  async forgotPassword(payload) {
    return await userAuthRest(`/user/password/forgot/?${qs.stringify(payload)}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
    });
  }
}
