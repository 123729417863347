import React from 'react'
import {
  FormGroup,
  InputGroup,
  FormFeedback,
  InputGroupAddon,
  Button
} from 'reactstrap'
import { Formik, Form, Field } from 'formik'
import Cleave from 'cleave.js/react'
import classNames from 'classnames'
import * as Yup from 'yup'
import cookie from 'js-cookie'
import utm from '../../../../../lib/utm'
import { sendTracker } from '../../../../../lib/tracker'
import { InsuranceCategory } from '../../../../../shared/enums'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { cleanPhoneNumber, isPhoneNumberValid } from '../../../../../lib/phoneNumber'
import './Quote.sass'
import { Kronos } from '../../../../../lib/api'

// ? Handle currency input
const handleCurrencyInput = (e, name, func) => {
  let value = e.target.rawValue
  if (value.length === 0 || isNaN(value)) value = '0'
  func(name, value)
}

const kronosService = new Kronos()

const Quote = ({ styles, toggle }) => {
  return (
    <div className="QuoteAuto--container" style={{ ...styles }}>
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          ...styles
        }}
        className="QuoteAuto--Form"
      >
        <Formik
          initialValues={{
            budget: '',
            phone: ''
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(false)

            try {
              const utmLeads = utm.getAll() || {}
              const { phone, budget } = values

              const result = await kronosService.setLeadsData({
                phone,
                budget: parseInt(budget, 10),
                utm: utmLeads,
                category: InsuranceCategory.AUTO_CAR
              });

              // Add field value to cookie
              cookie.set(
                'QUOTE_FORM',
                JSON.stringify({
                  ...values,
                  category: InsuranceCategory.AUTO_CAR
                }),
                {
                  expires: new Date(
                    new Date().getTime() + 1 * 24 * 60 * 60 * 1000
                  )
                }
              )

              utm.clear()

              if (!result.isExist)
                sendTracker(
                  'event',
                  'lead-submit',
                  'quote-lead-submit',
                  InsuranceCategory.AUTO_CAR
                )

              // await sendTapfiliate(phone)
            } catch (e) {
              console.log('Failed to send phone number')
            }

            // Clear compare
            cookie.remove('COMPARES')

            // Clear Lead Form
            resetForm()
            toggle()

            toast.info('Terima kasih, kami akan menghubungi anda')
          }}
          validationSchema={Yup.object({
            budget: Yup.number().required('Silahkan masukkan anggaran Anda.'),
            phone: Yup.string()
              .required('Silahkan masukkan nomor telepon')
              .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
          })}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            isSubmitting
          }) => (
              <Form>
                <FormGroup>
                  <Field name="phone">
                    {({ field }) => (
                      <>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              style={{
                                color: 'white',
                                backgroundColor: '#1d66dd',
                                border: 0
                              }}
                              className="input-group-text"
                            >
                              +62
                          </span>
                          </div>
                          <Cleave
                            {...field}
                            options={{
                              delimiter: ' ',
                              blocks: [3, 4, 4],
                              numericOnly: true
                            }}
                            className={classNames('form-control', {
                              'is-invalid': !!touched.phone && errors.phone
                            })}
                            placeholder="No. Telp"
                            onChange={e => {
                              setFieldValue(
                                `phone`,
                                cleanPhoneNumber(e.target.value)
                              )
                            }}
                          />
                          <FormFeedback>{errors.phone}</FormFeedback>
                        </div>
                      </>
                    )}
                  </Field>
                </FormGroup>

                <FormGroup>
                  <Field name="budget">
                    {({ field }) => (
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                        <Cleave
                          placeholder="Anggaran Per-Bulan"
                          options={{
                            numeral: true,
                            numeralIntegerScale: 9
                          }}
                          className={classNames(
                            'form-control',
                            errors.budget && touched.budget && 'is-invalid'
                          )}
                          {...field}
                          onChange={e =>
                            handleCurrencyInput(e, 'budget', setFieldValue)
                          }
                        />
                        {errors.budget && touched.budget && (
                          <FormFeedback>{errors.budget}</FormFeedback>
                        )}
                      </InputGroup>
                    )}
                  </Field>
                </FormGroup>

                <FormGroup>
                  {' '}
                  <Button
                    color="primary"
                    block
                    className="mt-4 mt-lg-0"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Dapatkan Penawaran
                </Button>
                </FormGroup>
              </Form>
            )}
        />
      </div>
    </div>
  )
}

Quote.propTypes = {
  styles: PropTypes.object
}

export default Quote
