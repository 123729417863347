import React from 'react';

import * as Yup from 'yup';
import { Alert } from 'reactstrap';
import { withFormik, Form, Field } from 'formik';

import { Input } from '@lifepal/lifepal-design-system';

import Loader from '../Loader';

import { isPhoneNumberValid, cleanPhoneNumber } from '../../lib/phoneNumber';
import { UserAuth } from '../../lib/api';

const FormRequestPassword = ({ values, touched, errors, isSubmitting, status, setFieldValue }) => {
  return (
    <div id="login-container">
      <Form>
        <div className="form-group">
          <h2 className="text-center">Lupa Password</h2>
          <p className="text-center">
            Kami akan mengirimkan tautan untuk mengubah password Anda ke
            alamat email atau nomor telepon Anda.
            </p>
          {
            status && (
              <Alert color="success">
                Instruksi untuk mengganti password telah dikirimkan ke{' '}
                {values.emailOrPhoneNumber}, mohon periksa email Anda.
              </Alert>
            )
          }
          {
            errors.all && <Alert color="danger">{errors.all}</Alert>
          }
          <Field name="emailOrPhoneNumber">
            {({ field }) => (
              <>
                <Input
                  type='text'
                  onChange={(event) => {
                    event.persist();
                    let value = event.target.value;
                    if (!isNaN(value)) {
                      value = cleanPhoneNumber(value, true);
                    }
                    setFieldValue('emailOrPhoneNumber', value);
                  }}
                  placeholder="Nomor Telepon atau Email"
                  inputMode='text'
                  name="Nomor Telepon atau Email"
                  disabled={isSubmitting}
                  size="md"
                  error={touched.emailOrPhoneNumber && errors.emailOrPhoneNumber}
                  maxLength="40"
                />
              </>
            )}
          </Field>
        </div>

        <button
          type="submit"
          className="mt-3 btn btn-block btn-primary button-login-submit"
          disabled={isSubmitting}
        >
          {
            isSubmitting ? <Loader /> : 'Kirim Permintaan'
          }
        </button>
      </Form>
    </div>
  );
};

const EnhancedForm = withFormik({
  mapPropsToValues: ({ email }) => ({
    emailOrPhoneNumber: email || '',
  }),
  validationSchema: Yup.object().shape({
    emailOrPhoneNumber: Yup
      .lazy((values) => {
        if (!values) {
          return Yup.string().required('Wajib Diisi');
        } else {
          if (isNaN(values)) {
            return Yup.string().email('Email tidak valid');
          } else {
            return Yup.string().test('emailOrPhoneNumber', 'Nomor Telepon tidak valid', () => isPhoneNumberValid(values, true));
          }
        }
      }),
  }),
  handleSubmit: async (values, { setErrors, setSubmitting, setStatus }) => {
    setSubmitting(true);
    try {
      const userauthService = new UserAuth();
      const result = await userauthService.forgotPassword({
        ...(
          isNaN(values.emailOrPhoneNumber)
            ? { email: values.emailOrPhoneNumber }
            : { phone_number: values.emailOrPhoneNumber.substring(1) }
        )
      });

      if (result && result.status > 400) {
        throw new Error(result.errorMessage);
      }
      setStatus('success');
      setSubmitting(false);
      setTimeout(() => {
        setStatus(null);
      }, 5000);
    } catch (error) {
      if (error.message.includes('not found') > -1) {
        setErrors({ all: 'Nomor telepon atau email tidak ditemukan dalam data kami' });
      } else {
        setErrors({ all: 'Terjadi kesalahan aplikasi' });
      }
      setSubmitting(false);
    }
  },
})

export default EnhancedForm(FormRequestPassword);
