import React, { createContext, useContext, useState } from 'react';
import './MainGroup.scss';

const MainGroupContext = createContext({});
export const useMainGroupContext = () => useContext(MainGroupContext);

/**
 * 
 * @param children ReactNode
 * @returns 
 */
export const MainGroup = (props) => {
  const { children } = props;
  const [activeId, setActiveId] = useState();
  let id = 0;

  const getUniqueId = () => {
    id = id + 1;
    return id.toString();
  }

  return (
    <MainGroupContext.Provider
      value={{
        activeId,
        setActiveId,
        getUniqueId,
      }}
    >
      <div className='main-group'>
        {children}
      </div>
    </MainGroupContext.Provider>
  )
}

export default MainGroup;