import React from 'react'
import PropTypes from 'prop-types'
import { Input, FormFeedback } from 'reactstrap'

// Inline Style for currency
const style = {
  currency: {
    position: 'absolute',
    top: '10px',
    left: 10
  }
}

class Currency extends React.PureComponent {
  static propTypes = {
    handleChange: PropTypes.func,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    errors: PropTypes.string,
    name: PropTypes.string
  }

  state = {
    currentValue: this.props.value || ''
  }

  _el = React.createRef()

  _formatValue = e => {
    const { value } = e.target

    // Remove the dot
    const currentValue = value.replace(/\./g, '').replace(/\D/g, '')

    // Format decimal
    const formattedValue = currentValue.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1.'
    )

    // Set On Change
    this.props.handleChange(formattedValue, currentValue)

    // Change display
    e.target.value = formattedValue
  }

  componentDidMount() {
    const { value } = this.props
    let formattedValue =
      value && value.length > 0
        ? value[0] !== '0'
          ? value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          : this.state.currentValue
        : this.state.currentValue

    // Get input
    const input = this._el.current.querySelector('input')
    input.value = formattedValue
  }

  render() {
    return (
      <div className="position-relative" ref={this._el}>
        <span className="text-muted" style={style.currency}>
          Rp.
        </span>
        <Input
          type="tel"
          name={this.props.name}
          onKeyUp={this._formatValue}
          onKeyDown={this._formatValue}
          defaultValue={this.props.value}
          invalid={this.props.invalid}
          placeholder="0"
          style={{ paddingLeft: 40 }}
          disabled={this.props.disabled}
          pattern="[\d\.]*"
        />
        {this.props.errors && <FormFeedback>{this.props.errors}</FormFeedback>}
      </div>
    )
  }
}
export default Currency
