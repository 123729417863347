import React from 'react';

import Router from 'next/router';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import cookie from 'js-cookie';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Button,
  FormFeedback
} from 'reactstrap';

import { cleanPhoneNumber, isPhoneNumberValid } from '../../../../../lib/phoneNumber';

import utm from '../../../../../lib/utm';
import { sendTracker } from '../../../../../lib/tracker';
import { InsuranceCategory } from '../../../../../shared/enums';
import { Kronos } from '../../../../../lib/api';

// ? Handle currency input
const handleCurrencyAgeInput = (e, name, func) => {
  let value = e.target.rawValue
  if (!value.length || isNaN(value)) value = 0;
  func(name, Number(value))
}

const kronosService = new Kronos()

// ? Kesehatan Form
const HealthForm = ({ quoteForm }) => (
  <Formik
    initialValues={{
      gender: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.gender : '',
      age: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.age : '',
      budget: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.budget : '',
      isSalary:
        quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.isSalary : false,
      salary: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.salary : '',
      phone: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.phone : ''
    }}
    enableReinitialize={true}
    validationSchema={Yup.object({
      gender: Yup.string()
        .oneOf(['MALE', 'FEMALE'])
        .required('Silahkan pilih jenis kelamin.'),
      age: Yup.number()
        .required('Masukkan umur Anda.')
        .min(0, 'Umur harus diatas 0 Tahun')
        .max(60, 'Umur harus dibawah 60 Tahun'),
      isSalary: Yup.bool(),
      budget: Yup.mixed().when('isSalary', {
        is: false,
        then: () => {
          if (window.innerWidth > 991)
            return Yup.number().required('Silahkan masukkan anggaran Anda.')
          else return Yup.number()
        }
      }),
      salary: Yup.mixed().when('isSalary', {
        is: true,
        then: Yup.number().required('Silahkan masukkan gaji Anda.')
      }),
      // phone: Yup.string()
      phone: Yup.string()
        .required('Silahkan masukkan nomor telepon')
        .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
    })}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(false)

      try {
        const utmLeads = utm.getAll() || {}
        const { phone, gender, age, budget, salary } = values

        const result = await kronosService.setLeadsData({
            phone,
            budget: parseInt(values.isSalary ? salary || 0 : budget || 0, 10),
            gender,
            age,
            utm: utmLeads,
            category: InsuranceCategory.HEALTH_INDIVIDU
          });

        // Add field value to cookie
        cookie.set(
          'QUOTE_FORM',
          JSON.stringify({ ...values, category: 'HEALTH_INDIVIDU' }),
          {
            expires: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
          }
        )

        utm.clear()

        if (!result.isExist)
          sendTracker(
            'event',
            'lead-submit',
            'quote-lead-submit',
            InsuranceCategory.HEALTH_INDIVIDU
          )

        // await sendTapfiliate(phone)
      } catch (e) {
        console.log('Failed to send phone number')
      }

      // set default budget 0
      // on mobile detect
      values.budget === '' ? (values.budget = '0') : null

      // Clear compare
      cookie.remove('COMPARES')

      // Redirect
      Router.push({
        pathname: '/asuransi-kesehatan/plans',
        query: values
      })
    }}
    render={({ values, errors, setFieldValue, isSubmitting }) => (
      <Form className="InsuranceQuote--Form">
        <FormGroup tag="fieldset">
          <legend className="col-form-label">
            <strong>Jenis Kelamin</strong>
          </legend>
          <Field name="gender">
            {({ field }) => (
              <FormGroup check inline>
                <Label check className="radio">
                  <Input
                    type="radio"
                    checked={field.value === 'MALE'}
                    {...field}
                    value="MALE"
                  />
                  <span>Pria</span>
                </Label>
                <Label check className="radio">
                  <Input
                    type="radio"
                    checked={field.value === 'FEMALE'}
                    {...field}
                    value="FEMALE"
                  />
                  <span>Wanita</span>
                </Label>
              </FormGroup>
            )}
          </Field>
          {errors.gender && (
            <div className="form-text text-danger">
              <small>{errors.gender}</small>
            </div>
          )}
        </FormGroup>

        <Field name="age">
          {({ field }) => (
            <FormGroup>
              <Cleave
                className={classNames('form-control', { 'is-invalid': errors.age })}
                placeholder="Umur Anda"
                options={{
                  numeral: true,
                  numeralIntegerScale: 2
                }}
                {...field}
                onChange={e =>
                  handleCurrencyAgeInput(e, 'age', setFieldValue)
                }
              />
              <FormFeedback>{errors.age}</FormFeedback>
            </FormGroup>
          )}
        </Field>

        <div className="d-none d-lg-block">
          <Field name="budget">
            {({ field }) => (
              <InputGroup className={values.isSalary ? 'disabled' : ''}>
                <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                <Cleave
                  placeholder="Anggaran Per-Bulan"
                  options={{
                    numeral: true,
                    numeralIntegerScale: 9
                  }}
                  className={classNames(
                    'form-control',
                    errors.budget && 'is-invalid'
                  )}
                  {...field}
                  disabled={values.isSalary}
                  onChange={e =>
                    handleCurrencyAgeInput(e, 'budget', setFieldValue)
                  }
                />
                <FormFeedback>{errors.budget}</FormFeedback>
              </InputGroup>
            )}
          </Field>

          <Field name="isSalary">
            {({ field }) => (
              <FormGroup check className="mb-3 mt-1">
                <Label check className="checkbox">
                  <Input type="checkbox" {...field} />
                  <span>Saya tidak tahu anggaran saya</span>
                </Label>
              </FormGroup>
            )}
          </Field>

          {values.isSalary && (
            <Field name="salary">
              {({ field }) => (
                <InputGroup>
                  <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                  <Cleave
                    placeholder="Pendapatan Per-Bulan"
                    options={{
                      numeral: true,
                      numeralIntegerScale: 9
                    }}
                    className={classNames(
                      'form-control',
                      errors.salary && 'is-invalid'
                    )}
                    {...field}
                    onChange={e =>
                      handleCurrencyAgeInput(e, 'salary', setFieldValue)
                    }
                  />
                  <FormFeedback>{errors.salary}</FormFeedback>
                </InputGroup>
              )}
            </Field>
          )}
        </div>

        <Field name="phone">
          {({ field }) => (
            <div className="input-group">
              <div className="input-group-prepend">
                <span
                  style={{
                    color: 'white',
                    backgroundColor: '#1d66dd',
                    border: 0
                  }}
                  className="input-group-text"
                >
                  +62
                </span>
              </div>
              <Cleave
                {...field}
                options={{
                  delimiter: ' ',
                  blocks: [3, 4, 4],
                  numericOnly: true
                }}
                className={classNames('form-control', {
                  'is-invalid': errors.phone
                })}
                placeholder="No. Telp"
                onChange={e => {
                  setFieldValue(`phone`, cleanPhoneNumber(e.target.value))
                }}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </div>
          )}
        </Field>
        <div className="text-center mt-4">
          <Button color="primary" block type="submit" disabled={isSubmitting}>
            Tampilkan Pilihan
          </Button>
        </div>
      </Form>
    )}
  />
)

export default HealthForm
