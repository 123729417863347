import { toast } from 'react-toastify';

const copyToClipboard = (id, value) => {
  const ids = document.getElementById(id);

  const text = ids ? ids.innerText : '';
  const element = document.createElement('textarea');

  document.body.appendChild(element);

  element.value = text;
  if (!id) {
    element.value = value;
  }

  element.select();

  document.execCommand('copy');
  document.body.removeChild(element);

  let messageToaster = 'Nomor sudah disimpan.';
  if (value) {
    messageToaster = 'Link sudah disimpan';
  }

  toast.info(messageToaster);
};

export default copyToClipboard;