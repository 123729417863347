import getConfig from 'next/config';

import axios from 'axios';

const { HOST } = getConfig().publicRuntimeConfig;

export class Queue {
  constructor(token) {
    this.token = token;
  }

  async getAgentLevel() {
    return await axios(`${HOST}/api/agents/level`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }
}
