import React from 'react'
import classnames from 'classnames'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import './Hero.sass'

import Quote from '../Quote'

const Hero = props => (
  <div
    id="top"
    className={classnames('InsuranceHero', {
      'InsuranceHero-CenterBg': props.isHcp
    })}
    style={{ backgroundImage: `url(${props.heroBackground})`, ...props.style }}
  >
    <Container fluid>
      <Row className="Hero--Container">
        <Col
          xs={12}
          lg={7}
          className={classnames('align-self-center', {
            'isHcp-align-center': props.isHcp
          })}
        >
          <div
            className={classnames(
              { 'InsuranceHero--Center': props.isHcp },
              { 'InsuranceHero--Left': !props.isHcp }
            )}
          >
            <h1 className="text-white">
              {Object.keys(props.selectedBrand).length > 0 ? (
                props.selectedBrand.primaryText
              ) : (
                <>{props.defaultPrimaryText}</>
              )}
            </h1>
            <p className="text-white">
              {Object.keys(props.selectedBrand).length > 0 ? (
                props.selectedBrand.secondaryText
              ) : (
                <>{props.defaultSecondaryText}</>
              )}
            </p>
            {props.slider}
          </div>
        </Col>

        {props.quoteType && (
          <Col xs={12} lg={4} className="align-self-center">
            <div className="InsuranceHero--Right">
              <Quote quoteForm={props.quoteForm} type={props.quoteType} />
            </div>
          </Col>
        )}
      </Row>
    </Container>
  </div>
)
Hero.propTypes = {
  heroBackground: PropTypes.string,
  slider: PropTypes.node,
  selectedBrand: PropTypes.shape({
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string
  }),
  defaultPrimaryText: PropTypes.string,
  defaultSecondaryText: PropTypes.string,
  quoteType: PropTypes.string
}
export default Hero
