import React from 'react';

import Router from 'next/router';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import cookie from 'js-cookie';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Button,
  FormFeedback
} from 'reactstrap';

import { cleanPhoneNumber, isPhoneNumberValid } from '../../../../../lib/phoneNumber';
import { InsuranceCategory } from '../../../../../shared/enums';

import utm from '../../../../../lib/utm';
import { sendTracker } from '../../../../../lib/tracker';
import { Kronos } from '../../../../../lib/api';

// ? Handle currency input
const handleCurrencyAgeInput = (e, name, func) => {
  let value = e.target.rawValue
  if (!value.length || isNaN(value)) value = 0;
  func(name, Number(value))
}

const kronosService = new Kronos();

// ? Jiwa Form
const LifeForm = ({ quoteForm }) => (
  <Formik
    initialValues={{
      age: quoteForm.category === 'LIFE' ? quoteForm.age : '',
      budget: quoteForm.category === 'LIFE' ? quoteForm.budget : '',
      isSalary: quoteForm.category === 'LIFE' ? quoteForm.isSalary : false,
      salary: quoteForm.category === 'LIFE' ? quoteForm.salary : '',
      phone: quoteForm.category === 'LIFE' ? quoteForm.phone : ''
    }}
    enableReinitialize={true}
    validationSchema={Yup.object({
      age: Yup.number()
        .required('Masukkan umur Anda.')
        .min(1, 'Umur harus diatas 1 Tahun')
        .max(69, 'Umur harus dibawah 69 Tahun'),
      isSalary: Yup.bool(),
      budget: Yup.mixed().when('isSalary', {
        is: false,
        then: Yup.number().required('Silahkan masukkan anggaran Anda.')
      }),
      salary: Yup.mixed().when('isSalary', {
        is: true,
        then: Yup.number().required('Silahkan masukkan gaji Anda.')
      }),
      phone: Yup.string()
        .required('Silahkan masukkan nomor telepon')
        .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
    })}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        const utmLeads = utm.getAll() || {}
        const { phone, age, budget, salary } = values

        const result = await kronosService.setLeadsData({
            phone,
            phone,
            budget: parseInt(values.isSalary ? salary : budget, 10),
            age,
            utm: utmLeads,
            category: InsuranceCategory.LIFE
          });

          // Add field value to cookie
        cookie.set(
          'QUOTE_FORM',
          JSON.stringify({ ...values, category: 'LIFE' }),
          {
            // expired 5 minutes
            expires: new Date(new Date().getTime() + 5 * 60 * 1000)
          }
        )

        utm.clear()

        if (!result.isExist)
          sendTracker(
            'event',
            'lead-submit',
            'quote-lead-submit',
            InsuranceCategory.LIFE
          )

        // await sendTapfiliate(phone)
      } catch (e) {
        console.log('Failed to send phone number')
      }

      // Clear compare
      cookie.remove('COMPARES')

      // Redirect
      Router.push({
        pathname: '/asuransi-jiwa/plans',
        query: values
      })
    }}
    render={({ values, errors, setFieldValue, isSubmitting }) => (
      <Form className="InsuranceQuote--Form">
        <Field name="age">
          {({ field }) => (
            <FormGroup>
              <Label>
                <strong>Usia Anda</strong>
              </Label>
              <Cleave
                className={classNames('form-control', { 'is-invalid': errors.age })}
                placeholder="Umur Anda"
                options={{
                  numeral: true,
                  numeralIntegerScale: 2
                }}
                {...field}
                onChange={e =>
                  handleCurrencyAgeInput(e, 'age', setFieldValue)
                }
              />
              <FormFeedback>{errors.age}</FormFeedback>
            </FormGroup>
          )}
        </Field>

        <Field name="budget">
          {({ field }) => (
            <InputGroup className={values.isSalary ? 'disabled' : ''}>
              <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
              <Cleave
                placeholder="Anggaran Per-Bulan"
                options={{
                  numeral: true,
                  numeralIntegerScale: 9
                }}
                className={classNames(
                  'form-control',
                  errors.budget && 'is-invalid'
                )}
                {...field}
                disabled={values.isSalary}
                onChange={e => handleCurrencyAgeInput(e, 'budget', setFieldValue)}
              />
              <FormFeedback>{errors.budget}</FormFeedback>
            </InputGroup>
          )}
        </Field>

        <Field name="isSalary">
          {({ field }) => (
            <FormGroup check className="mb-3 mt-1">
              <Label check className="checkbox">
                <Input type="checkbox" {...field} />
                <span>Saya tidak tahu anggaran saya</span>
              </Label>
            </FormGroup>
          )}
        </Field>

        {values.isSalary && (
          <Field name="salary">
            {({ field }) => (
              <InputGroup>
                <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                <Cleave
                  placeholder="Pendapatan Per-Bulan"
                  options={{
                    numeral: true,
                    numeralIntegerScale: 9
                  }}
                  className={classNames(
                    'form-control',
                    errors.budget && 'is-invalid'
                  )}
                  {...field}
                  onChange={e =>
                    handleCurrencyAgeInput(e, 'salary', setFieldValue)
                  }
                />
                <FormFeedback>{errors.salary}</FormFeedback>
              </InputGroup>
            )}
          </Field>
        )}

        <Field name="phone">
          {({ field }) => (
            <div className="input-group mt-3">
              <div className="input-group-prepend">
                <span
                  style={{
                    color: 'white',
                    backgroundColor: '#1d66dd',
                    border: 0
                  }}
                  className="input-group-text"
                >
                  +62
                </span>
              </div>
              <Cleave
                {...field}
                options={{
                  delimiter: ' ',
                  blocks: [3, 4, 4],
                  numericOnly: true
                }}
                className={classNames('form-control', {
                  'is-invalid': errors.phone
                })}
                placeholder="No. Telp"
                onChange={e => {
                  setFieldValue(`phone`, cleanPhoneNumber(e.target.value))
                }}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </div>
          )}
        </Field>
        <div className="text-center mt-4">
          <Button color="primary" block type="submit" disabled={isSubmitting}>
            Dapatkan Penawaran
          </Button>
        </div>
      </Form>
    )}
  />
)
export default LifeForm
