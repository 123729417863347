import React from 'react';

import Modal from '../Modal';
import { Policy } from '../Policy/Policy';

import { getQuotationType } from 'clientUtils';

import './QuotationListView.module.scss';

export const QuotationListView = ({ toggle, quotationList, phoneNumber }) => {
  return (
    <Modal
      isOpen
      toggle={toggle}
      showModalHeader
      title="Order"
      className="quotations__modal"
    >
      <div className="quotations__modal--body">
        {
          (quotationList && quotationList.length)
            ? quotationList
              .sort((quotation) => {
                const { type } = getQuotationType(quotation, phoneNumber);
                return ['expired', 'renewal'].includes(type) ? -1 : 1;
              })
              .map((quotation, i) => (
                <Policy
                  key={i}
                  quotationInfo={quotation}
                  leadPhoneNumber={phoneNumber}
                />
              ))
            : <span>Order Tidak Ditemukan.</span>
        }
      </div>
    </Modal>
  )
};
