import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './Rating.sass'

const Rating = ({ rating, isLandingBrand }) => {
  if (rating === '0') {
    rating = "3.0"
  }

  let ratingSplit = rating.split('.')
  let ratingRes = parseInt(ratingSplit[0], 10) * 2
  if (parseInt(ratingSplit[1], 10) >= 5) {
    ratingRes += 1;
  }

  if (ratingRes === 1) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star-half"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 2) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 3) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-half"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 4) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 5) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-half"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 6) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 7) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-half"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 8) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 9) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star-half"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else if (ratingRes === 10) {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <i className="icon ion-ios-star"></i>
        <span>{rating} / 5.0</span>
      </div>
    )
  } else {
    return (
      <div className={classnames(!isLandingBrand ? 'rating__star Rating-Brand' : 'rating__star Rating-Brand-Landing')}>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <i className="icon ion-ios-star-outline"></i>
        <span className="heading-xs-r">{rating} / 5.0</span>
      </div>
    )
  }
}

Rating.propTypes = {
  rating: PropTypes.string
}

export default Rating
