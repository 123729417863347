import { ordersRest } from '../../client'
import qs from 'querystring';

export class Orders {
  constructor(token) {
    this.token = token;
  }

  async get(id) {
    const url = `/orders/payment-link/?id=${id}`;

    return await ordersRest(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json;charset=utf-8' }
    });
  }

  async getOrderByQuotationId(quotationId) {
    const url = `/orders/quotation/?quotationId=${quotationId}`;

    return await ordersRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`
      }
    });
  }

  async requestDoku(orderId) {
    const url = `/orders/initiate-payment/`;

    return await ordersRest(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;charset=utf-8' },
      data: { orderId },
    });
  }

  async getPromos() {
    const url = '/orders/installment-promos/';

    return await ordersRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    })
  }

  async getHistory(statuses, published, buyerEmail) {
    const filter = { statuses };
    if (typeof published === 'number') { filter.published = published; }

    const query = JSON.stringify(filter);
    let url = `/orders/history/?limit=100&filter=${query}`;

    if (buyerEmail) { url += `&buyerEmail=${encodeURIComponent(buyerEmail)}`; }

    return await ordersRest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${this.token}`,
      },
    });
  }

  async changePaymentMethod(payload) {
    const url = `/orders/payments/change/`;

    return await ordersRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      data: payload,
    })
  }

  async validatePaymentOTP(payload) {
    const url = `/orders/payments/xendit/validate-otp/`;

    return await ordersRest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      data: payload,
    })
  }
}
