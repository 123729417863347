import React from 'react';

import PropTypes from 'prop-types';
import { Container, Button, Col, Row } from 'reactstrap';

import { Avatar } from '../../../Avatar/Avatar';

import currencyIdr from '../../../../lib/currencyIdr';

import './styles.sass';

class Compare extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array,
    onRemoveItem: PropTypes.func,
    onCompare: PropTypes.func
  }

  render() {
    const { items, onCompare, onRemoveItem, isTravel, isConstantTravelling } = this.props

    if (items.length <= 1) {
      return <></>
    }

    return (
      <div className="plan_b" id="compare__plan--group">
        <Container>
          <Row>
            <Col>
              <div className="pb-0 item-container-mobile">
                <Button color="default" outline block onClick={onCompare}>
                  Lihat Perbandingan
                  <div>{items.length}</div>
                  <img className="next-icon" src="/static/images/icons/chevron-right.svg" alt="back" />
                </Button>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
export default Compare
