import React, { useState, useEffect } from 'react';

import jsCookie from 'js-cookie';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Modal from '../../../../../components/Modal';
import RatingAndReason from '../RatingAndReason/RatingAndReason';

import { NextServerAPI } from '../../../../utils/coreServerAPI';

import { Button, Avatar } from '@lifepal/lifepal-design-system';

import './ScoreCardView.module.scss';

const ScoreCardView = ({
  callRecordId,
  audioLink,
  date,
  duration,
  toggleScoreCard,
  phoneNumber,
  getCallHistoryList,
}) => {
  const [questions, setQuestions] = useState([]);
  const [play, setPlay] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const getCallQuestions = async () => {
    return await NextServerAPI({
      path: `/api/agents/call-questions/`,
      method: 'GET',
      AuthorizationToken: `Bearer ${jsCookie.get('token')}`
    });
  };

  const getCallAnswers = async () => {
    return await NextServerAPI({
      path: `/api/agents/call-answers/${callRecordId}/`,
      method: 'GET',
      AuthorizationToken: `Bearer ${jsCookie.get('token')}`
    });
  }

  const submitCallQuestions = async (payload) => {
    return await NextServerAPI({
      path: `/api/agents/call-answers/`,
      method: 'POST',
      data: payload,
      AuthorizationToken: `Bearer ${jsCookie.get('token')}`
    })
  }

  const setRatingAndReason = (index, key, value) => {
    const clonedQuestions = [...questions];

    const selectedQuestion = clonedQuestions[index];
    selectedQuestion[key] = value;

    setQuestions(clonedQuestions);
  }

  const handleSubmit = async () => {
    const questionIncomplete = questions.find(row => row.answer && row.rating);
    if (!questionIncomplete) {
      toast.error('Pertanyaan Wajib Diisi.');
      return;
    }

    setSubmitting(true);

    const payload = {
      call_record_id: callRecordId,
      answer_list: questions
        .filter(question => question.answer && question.rating)
        .map(question => ({
          question_id: question.id,
          answer: question.answer,
          rating: Number(question.rating),
        })),
    }

    try {
      await submitCallQuestions(payload);
      getCallHistoryList();
      setSubmitting(false);
      toggleScoreCard();
    } catch (error) {
      toast.error('Terjadi Kesalahan.');
      setSubmitting(false);
    }
  }

  const getQuestionsAndAnswers = async () => {
    const callQuestions = await getCallQuestions();
    const callAnswers = await getCallAnswers();

    if (callQuestions && callQuestions.data && callQuestions.data.length) {
      const questionList = callQuestions.data.map(row => {
        const matchAnswer = callAnswers && callAnswers.data.find(answer => answer.question.id === row.id);
        return {
          id: row.id,
          question: row.question_text,
          answer: (matchAnswer && matchAnswer.answer) ? matchAnswer.answer : '',
          rating: (matchAnswer && matchAnswer.rating) ? String(matchAnswer.rating) : '0',
          isPrefilled: !!(matchAnswer && matchAnswer.answer),
        };
      });
      setQuestions(questionList);
    }
  }

  useEffect(() => {
    getQuestionsAndAnswers();
  }, []);

  const isIncomplete = !!questions.find(row => !row.isPrefilled);
  return (
    <Modal
      isOpen
      className="score__card"
      toggle={toggleScoreCard}
    >
      <div className="score__card--header">
        <h1 className="header__title">{phoneNumber}</h1>
        <div className="header__info">
          <p>Waktu Panggilan <span>{date}</span></p>
          <p>Durasi <span>{duration}</span></p>
        </div>
        <Button
          className={classNames('header__button', play && 'stop')}
          variant="ghost"
          size="md"
          onClick={() => setPlay(!play)}
        >
          <Avatar
            src={play ? '/static/images/icons/stop.svg' : '/static/images/icons/play.svg'}
            loading="lazy"
            alt="audio_control"
          />
          {play ? 'Stop Rekaman' : 'Dengarkan Rekaman'}
        </Button>
      </div>
      <div className="score__card--body">
        {
          questions.length
            ? questions.map((data, i) => (
              <RatingAndReason
                key={i}
                index={i}
                data={data}
                setRatingAndReason={setRatingAndReason}
              />
            ))
            : <p>Tidak Ada Pertanyaan</p>
        }
      </div>
      <div className="score__card--footer">
        <Button
          className="footer__left"
          variant="ghost"
          size="lg"
          onClick={toggleScoreCard}
        >
          Kembali
        </Button>
        <Button
          className="footer__right"
          variant="primary"
          size="lg"
          onClick={handleSubmit}
          isLoading={isSubmitting}
          disabled={!isIncomplete}
          loaderColor="#FFFFFF"
        >
          Simpan
        </Button>
      </div>
      {
        play && (
          <audio
            src={audioLink}
            controls
            autoPlay
            className="score__card--audio"
          />
        )
      }
    </Modal >
  )
};

export default ScoreCardView;
