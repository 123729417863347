import React from "react"
import "./SearchField.module.scss";

/**
 * @prop placeholder string
 * @prop value string
 * @prop disabled boolean
 * @prop className string
 * @prop onChange (value: string)=> void
 * 
 * @returns 
 */
export const SearchField = ({
  value, onChange, placeholder, className, disabled
}) => {
  return (
    <div className="search-field-root">
			<input
				value={value}
				type="text"
				className={`search-input ${className}`}
				placeholder={placeholder}
				onChange={(e)=>{
					onChange(e.target.value);
				}}
				disabled={disabled}
			/>
			<div className="search-icon">
	      <img width="20" src="/static/images/icons/search.svg"/>
			</div>
		</div>
  )
}
