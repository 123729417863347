import React, { useState } from 'react';
import Router from 'next/router';
import * as Yup from 'yup';
import { Alert } from 'reactstrap';
import { Form, Formik } from 'formik';
import decode from 'jsonwebtoken/decode';
import cookie from 'js-cookie';
import { toast } from 'react-toastify/';
import { Input } from '@lifepal/lifepal-design-system';

import { validateAgentRole, userActivity } from 'clientUtils';
import Loader from '../Loader';
import { useAuth } from "../../providers/Auth";
import { errorMessage } from '../../helper';
import { UserAuth } from '../../lib/api';

import { AGENT_CONSTANTS } from '../../client/modules/agents/constants/agent.constants';

const FormChangePassword = (props) => {
  const { hash, status, query, password1, password2 } = props;
  const { setAuthenticated, setMe, setAuthToken, setAgent, setTSOSupervisor } = useAuth();

  const handleAuthAfterReset = () => {
    let location = '/';
    const me = decode(hash);

    if (hash && (!me.groups.includes('internal-agents') || me.organization.name !== 'Lifepal')) {
      setMe(me);
      setAuthToken(hash);
      cookie.set('token', hash);
      if (validateAgentRole(me.groups)) {
        cookie.set('mode', 'agent');
        setAgent(true);
        if (AGENT_CONSTANTS.getAgentByToken(hash).isTSOSupervisor) {
          setTSOSupervisor(true);
          location = '/agents/status';
        } else {
          userActivity.init(hash);
          setTSOSupervisor(false);
          location = '/agents';
        }
      } else {
        setAgent(false);
        if (query && query.next) location = query.next;
      }
      setAuthenticated(true);
      Router.push(location);
    }
  }

  return (
    <div id="login-container">
      <Formik
        initialValues={{
          password1: password1 || '',
          password2: password2 || '',
          hash,
        }}
        validationSchema={Yup.object().shape({
          password1: Yup.string()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/, 'Kata sandi minimal 8 karakter, menggunakan huruf besar, huruf kecil, angka, dan minimal 1 karakter simbol.')
            .required('Password harus diisi'),
          password2: Yup.string()
            .required('Konfirmasi password harus diisi.')
            .test('password2', 'Konfirmasi password tidak sesuai.', function (val) {
              const { password1 } = this.parent;
              if (!val || (val && val.length <= 0)) {
                return true;
              }

              if (val === password1) {
                return true;
              }

              return false;

            })
        })}
        onSubmit={async (values, { setErrors, setSubmitting, setStatus }) => {
          const token = values.hash;

          try {
            const UserService = new UserAuth();
            await UserService.resetPassword({
              new_password: values.password1,
              confirm_password: values.password2,
            }, `Bearer ${values.hash}`);
            toast.info('berhasil mengatur ulang kata sandi');
            setStatus('success');
            handleAuthAfterReset();
            setSubmitting(false);
          } catch (error) {
            if (error.response && error.response.data) {
              const keys = Object.keys(error.response.data);

              if (
                error.response.data.code
                && error.response.data.code === 'token_not_valid'
              ) {
                setErrors({
                  all:
                    'Token sudah tidak valid, silahkan ulangi permintaan lupa password',
                });
                setSubmitting(false);
                return;
              }

              if (
                error.response.data[keys[keys.length - 1]][0].includes(
                  'is not similar',
                )
              ) {
                setErrors({ all: 'Password baru dengan password konfirmasi tidak sama' });
              } else {
                setErrors({ all: 'Terjadi kesalahan pada input password' });
              }
            } else {
              setErrors({ all: 'Terjadi kesalahan aplikasi' });
            }
            setSubmitting(false);
          }
        }}
        render={({ errors, isSubmitting, touched, setFieldValue }) => (
          <Form>
            <div className="form-group">
              <h2 className="text-center">
                {query && query.type === 'new' ? 'Buat Password Baru' : 'Ganti password'}
              </h2>
              {status && (
                <Alert color="success">
                  Password berhasil diubah!
                </Alert>
              )}
              {errors.all && (
                <div className="alert alert-danger">{errors.all}</div>
              )}
              <p />
              <label>Masukkan password baru Anda </label>
              <Input
                name="password1"
                type="password"
                inputMode='text'
                placeholder=''
                size='md'
                error={errors && errors.password1}
                onChange={(e) => setFieldValue('password1', e.target.value)}
              />
              {errorMessage(touched, errors, 'password1')}
            </div>
            <div className="mt-3 form-group form-input-box">
              <label>Masukkan kembali password baru Anda </label>
              <Input
                name='password2'
                type='password'
                inputMode='text'
                placeholder=''
                size='md'
                error={errors && errors.password2}
                onChange={(e) => setFieldValue('password2', e.target.value)}
              />
            </div>
            <input type="hidden" name="hash" value={hash} />
            <button
              type="submit"
              className="mt-3 btn btn-block btn-primary button-login-submit"
              disabled={isSubmitting}
            >
              {' '}
              {isSubmitting ? <Loader /> : query && query.type === 'new' ? 'Simpan' : 'Perbarui Password'}
            </button>
          </Form>
        )}
      />
    </div>
  );
};

export default FormChangePassword;