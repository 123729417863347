import React from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';

import { Shimmer } from '../../../../Shimmer/Shimmer';

import './List.sass';

const getProductListItems = (
  items,
  renderItem,
  totalPages,
  currentPage,
  loadMoreHandler,
  shouldLazyLoad,
  isLoading,
  isABTest
) => {

  const paginationHandler = () => {
    setTimeout(() => {
      document.getElementById(`shimmer_0`) &&
        window.scrollTo({ top: document.getElementById(`shimmer_0`).offsetTop, behavior: 'smooth' });
    }, 100);
    loadMoreHandler();
  };

  return (
    <>
      <ul className="product__list hide-scrollbar">
        {
          items.length
            ? (
              items.map((plan, key) => (
                <li className="plan__listing--product" key={key} id={`plan__${key}`}>{renderItem(plan, key)}</li>
              )
              )
            ) : (<></>)
        }
      </ul>
      {
        (!isLoading && !shouldLazyLoad && !items.length) && (
          <div className="text-center mb-4">Data Polis tidak ditemukan.</div>
        )
      }
      {
        (isLoading || shouldLazyLoad) && (
          Array(4).fill('').map((shimmer, index) => <Shimmer id={index} key={index} />)
        )
      }
      {
        (!isLoading && !shouldLazyLoad) && (currentPage < totalPages) ? (
          <button className={classNames('button-faded-orange load__more--btn heading-sm-r', isABTest && 'load__more--btn_space')} onClick={paginationHandler}>
            Lihat Lebih Banyak Produk
            <span style={{ marginLeft: '10px' }}>
              <img src="/static/images/icons/dropdown-down.svg" alt="@down__icon" />
            </span>
          </button>
        ) : (<div className='product__item--end' />)
      }
    </>
  );
}

const ProductList = ({
  isLoading,
  total,
  items,
  renderItem,
  currentPage,
  totalPages,
  loadMoreHandler,
  shouldLazyLoad,
  isABTest
}) => (
  <div className="ProductList">
    <div className="ProductList--Header">{total}</div>
    {
      getProductListItems(
        items, renderItem, totalPages,
        currentPage, loadMoreHandler, shouldLazyLoad, isLoading, isABTest
      )
    }
  </div>
)

ProductList.propTypes = {
  isLoading: PropType.bool,
  items: PropType.arrayOf(
    PropType.shape({
      imgUrl: PropType.string,
      title: PropType.string,
      plan: PropType.string,
      info: PropType.shape({
        premium: PropType.shape({
          dynamic: PropType.bool,
          price: PropType.number,
          type: PropType.string
        }),
        maxCoverage: PropType.oneOfType([PropType.number, PropType.string]),
        coverageDay: PropType.number
      }),
      promo: PropType.shape({
        title: PropType.string,
        color: PropType.string
      }),
      description: PropType.string,
      coverages: PropType.arrayOf(PropType.string)
    })
  ),
  renderItem: PropType.func,
  total: PropType.node
}
export default ProductList
