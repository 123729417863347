import React, { useState } from "react";

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import { toast } from 'react-toastify';

import { Button } from '@lifepal/lifepal-design-system/build/src/components/Button';

export const LPDatePicker = (props) => {
	const {
    date, emitDOB, showButton,
    maxDate, minDate, calendarPicker,
    range, placeholder, emitOnChange,
    calendarPosition, submitButtonText, renderInput
  } = props;
  const [selectedDay, setSelectedDay] = useState(date || null);

  const formatSelectedDateInView = () => {
    if (!selectedDay) return '';
    return `${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`;
  };

  if (calendarPicker) {
    const [selectedDayRange, setSelectedDayRange] = useState(range);

    const formatSelectedRangeInView = () => {
      if (!selectedDayRange.from || !selectedDayRange.to) return '';
      return `${selectedDayRange.from.day}/${selectedDayRange.from.month}/${selectedDayRange.from.year} - ${selectedDayRange.to.day}/${selectedDayRange.to.month}/${selectedDayRange.to.year}`;
    }

    const travelDateHandler = (travelDateRange) => {
      if (!travelDateRange || !travelDateRange.from || !travelDateRange.to) {
        toast.error('Input Wajib Diisi');
        return;
      }
      if (emitDOB && travelDateRange && Object.keys(travelDateRange.from).length && Object.keys(travelDateRange.to).length) {
        const startDay = `${(travelDateRange.from.day).toString().length === 1 ? `0${travelDateRange.from.day}` : `${travelDateRange.from.day}`}`;
        const startMonth = `${(travelDateRange.from.month).toString().length === 1 ? `0${travelDateRange.from.month}` : `${travelDateRange.from.month}`}`;

        const returnDay = `${(travelDateRange.to.day).toString().length === 1 ? `0${travelDateRange.to.day}` : `${travelDateRange.to.day}`}`;
        const returnMonth = `${(travelDateRange.to.month).toString().length === 1 ? `0${travelDateRange.to.month}` : `${travelDateRange.to.month}`}`;

        emitDOB({
          range: travelDateRange,
          start: `${startDay}/${startMonth}/${travelDateRange.from.year}`,
          end: `${returnDay}/${returnMonth}/${travelDateRange.to.year}`
        });
      }
    };

    return (
      <>
        <DatePicker
          value={selectedDayRange}
          onChange={(range) => {
            setSelectedDayRange(range);
            if (emitOnChange) {
              if (range && range.from && range.to) {
                travelDateHandler(range);
              }
            }
          }}
          shouldHighlightWeekends
          maximumDate={maxDate ? maxDate : undefined}
          minimumDate={minDate ? minDate : undefined}
          colorPrimary="#1D66DD"
          colorPrimaryLight="#f2f7ff"
          inputClassName="custom__date--picker"
          calendarSelectedDayClassName="selected__day"
          formatInputText={formatSelectedRangeInView}
          inputPlaceholder={placeholder}
          calendarPopperPosition={calendarPosition || "bottom"}
        />
        {
          showButton && (
            <div className="dob__action--block">
              <Button
                variant="primary"
                size="md"
                type="button"
                style={{
                  marginTop: '10px'
                }}
                onClick={() => travelDateHandler(selectedDayRange)}
              >
                Selanjutnya
              </Button>
            </div>
          )
        }
      </>
    );
  }

  return (
    <>
      <DatePicker
        value={selectedDay}
        onChange={(date) => {
          setSelectedDay(date);
          if (emitOnChange && Object.keys(date).length) {
            emitDOB(`${date.day}/${date.month}/${date.year}`);
          }
        }}
        inputPlaceholder={placeholder || 'Pilih Tanggal Lahir'}
        maximumDate={maxDate ? maxDate : undefined}
        minimumDate={minDate ? minDate : undefined}
        formatInputText={formatSelectedDateInView}
        shouldHighlightWeekends
        colorPrimary="#1D66DD"
        inputClassName="custom__date--picker"
        calendarSelectedDayClassName="selected__day"
        calendarPopperPosition={calendarPosition || "bottom"}
        renderInput={renderInput}
      />
      {
        showButton && (
          <div className="dob__action--block">
            <Button
              variant="primary"
              size="md"
              type="button"
              style={{
                marginTop: '10px'
              }}
              onClick={() => {
                if (!selectedDay) {
                  toast.error('Input Wajib Diisi');
                  return;
                }
                if (emitDOB && Object.keys(selectedDay).length) {
                  emitDOB(`${selectedDay.day}/${selectedDay.month}/${selectedDay.year}`);
                }
              }}
            >
              { submitButtonText ? submitButtonText : 'Selanjutnya' }
            </Button>
          </div>
        )
      }
    </>
  );
}

export default LPDatePicker;
