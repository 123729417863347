import React, { useState } from 'react';

import Router from 'next/router';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Button,
  FormFeedback
} from 'reactstrap';

import QuoteFormHealth from '../../Insurance/Quote/Form/HealthForm';
import QuoteFormLife from '../../Insurance/Quote/Form/LifeForm';

import utm from '../../../../lib/utm';
import { sendTracker } from '../../../../lib/tracker';
import { InsuranceCategory } from '../../../../shared/enums';
import cookie from 'js-cookie';
import { cleanPhoneNumber } from '../../../../lib/phoneNumber';
import { Kronos } from '../../../../lib/api';

import './Quote.sass';

// ? Handle currency input
const handleCurrencyInput = (e, name, func) => {
  let value = e.target.rawValue
  if (isNaN(value) || value.length === 0) value = '0'
  func(name, value)
}
const kronosService = new Kronos();

// ? Kesehatan Form
const HealthForm = ({ quoteForm }) => (
  <Formik
    initialValues={{
      gender: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.gender : '',
      age: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.age : '',
      budget: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.budget : '',
      isSalary:
        quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.isSalary : false,
      salary: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.salary : '',
      phone: quoteForm.category === 'HEALTH_INDIVIDU' ? quoteForm.phone : ''
    }}
    enableReinitialize={true}
    validationSchema={Yup.object({
      gender: Yup.string()
        .oneOf(['MALE', 'FEMALE'])
        .required('Silahkan pilih jenis kelamin.'),
      age: Yup.number().required('Masukkan umur Anda.'),
      isSalary: Yup.bool(),
      budget: Yup.mixed().when('isSalary', {
        is: false,
        then: Yup.number().required('Silahkan masukkan anggaran Anda.')
      }),
      salary: Yup.mixed().when('isSalary', {
        is: true,
        then: Yup.number().required('Silahkan masukkan gaji Anda.')
      }),
      phone: Yup.string()
        .required('Silahkan masukkan nomor telepon')
        .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
    })}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        const utmLeads = utm.getAll() || {}
        const { phone, gender, age, budget, salary } = values

        const result = await kronosService.setLeadsData({
            phone,
            budget: parseInt(values.isSalary ? salary : budget, 10),
            gender,
            age,
            utm: utmLeads,
            category: InsuranceCategory.HEALTH_INDIVIDU
          });

        // Add field value to cookie
        cookie.set(
          'QUOTE_FORM',
          JSON.stringify({
            ...values,
            category: InsuranceCategory.HEALTH_INDIVIDU
          }),
          {
            expires: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
          }
        )

        utm.clear()

        if (!result.isExist)
          sendTracker(
            'event',
            'lead-submit',
            'quote-lead-submit',
            InsuranceCategory.HEALTH_INDIVIDU
          )

        // await sendTapfiliate(phone)
      } catch (e) {
        console.log('Failed to send phone number')
      }

      // Clear compare
      cookie.remove('COMPARES')

      // Redirect
      Router.push({
        pathname: '/asuransi-kesehatan/plans',
        query: values
      })
    }}
    render={({ values, errors, setFieldValue, isSubmitting }) => (
      <Form className="Quote--Form">
        <FormGroup tag="fieldset">
          <legend className="col-form-label">
            <strong>Jenis Kelamin</strong>
          </legend>
          <Field name="gender">
            {({ field }) => (
              <FormGroup check inline>
                <Label check className="radio">
                  <Input
                    type="radio"
                    checked={field.value === 'MALE'}
                    {...field}
                    value="MALE"
                  />
                  <span>Pria</span>
                </Label>
                <Label check className="radio">
                  <Input
                    type="radio"
                    checked={field.value === 'FEMALE'}
                    {...field}
                    value="FEMALE"
                  />
                  <span>Wanita</span>
                </Label>
              </FormGroup>
            )}
          </Field>
          {errors.gender && (
            <div className="form-text text-danger">
              <small>{errors.gender}</small>
            </div>
          )}
        </FormGroup>

        <Field name="age">
          {({ field }) => (
            <FormGroup>
              <Input
                type="number"
                placeholder="Umur Anda"
                invalid={errors.age && true}
                min="21"
                max="60"
                {...field}
              />
              <FormFeedback>{errors.age}</FormFeedback>
            </FormGroup>
          )}
        </Field>

        <Field name="budget">
          {({ field }) => (
            <InputGroup className={values.isSalary ? 'disabled' : ''}>
              <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
              <Cleave
                placeholder="Anggaran Per-Bulan"
                options={{
                  numeral: true
                }}
                className={classNames(
                  'form-control',
                  errors.budget && 'is-invalid'
                )}
                {...field}
                disabled={values.isSalary}
                onChange={e => handleCurrencyInput(e, 'budget', setFieldValue)}
              />
              <FormFeedback>{errors.budget}</FormFeedback>
            </InputGroup>
          )}
        </Field>

        <Field name="isSalary">
          {({ field }) => (
            <FormGroup check className="mb-3 mt-1">
              <Label check className="checkbox">
                <Input type="checkbox" {...field} />
                <span>Saya tidak tahu anggaran saya</span>
              </Label>
            </FormGroup>
          )}
        </Field>

        {values.isSalary && (
          <Field name="salary">
            {({ field }) => (
              <InputGroup>
                <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                <Cleave
                  placeholder="Pendapatan Per-Bulan"
                  options={{
                    numeral: true
                  }}
                  className={classNames(
                    'form-control',
                    errors.salary && 'is-invalid'
                  )}
                  {...field}
                  onChange={e =>
                    handleCurrencyInput(e, 'salary', setFieldValue)
                  }
                />
                <FormFeedback>{errors.salary}</FormFeedback>
              </InputGroup>
            )}
          </Field>
        )}

        <Field name="phone">
          {({ field }) => (
            <div className="input-group mt-3">
              <div className="input-group-prepend">
                <span
                  style={{
                    color: 'white',
                    backgroundColor: '#1d66dd',
                    border: 0
                  }}
                  className="input-group-text"
                >
                  +62
                </span>
              </div>
              <Cleave
                {...field}
                options={{
                  delimiter: ' ',
                  blocks: [3, 4, 4],
                  numericOnly: true
                }}
                className={classNames('form-control', {
                  'is-invalid': errors.phone
                })}
                placeholder="No. Telp"
                onChange={e => {
                  setFieldValue(`phone`, cleanPhoneNumber(e.target.value))
                }}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </div>
          )}
        </Field>
        <div className="text-center mt-4">
          <Button color="primary" block type="submit" disabled={isSubmitting}>
            Tampilkan Pilihan
          </Button>
        </div>
      </Form>
    )}
  />
)

// ? Jiwa Form
const LifeForm = ({ quoteForm }) => (
  <Formik
    initialValues={{
      age: quoteForm.category === 'LIFE' ? quoteForm.age : '',
      budget: quoteForm.category === 'LIFE' ? quoteForm.budget : '',
      isSalary: quoteForm.category === 'LIFE' ? quoteForm.isSalary : false,
      salary: quoteForm.category === 'LIFE' ? quoteForm.salary : '',
      phone: quoteForm.category === 'LIFE' ? quoteForm.phone : ''
    }}
    enableReinitialize={true}
    validationSchema={Yup.object({
      age: Yup.number().required('Masukkan umur Anda.'),
      isSalary: Yup.bool(),
      budget: Yup.mixed().when('isSalary', {
        is: false,
        then: Yup.number().required('Silahkan masukkan anggaran Anda.')
      }),
      salary: Yup.mixed().when('isSalary', {
        is: true,
        then: Yup.number().required('Silahkan masukkan gaji Anda.')
      }),
      phone: Yup.string()
        .required('Silahkan masukkan nomor telepon')
        .test('phone', 'Nomor Telepon tidak valid.', (value = '') => isPhoneNumberValid(value))
    })}
    onSubmit={async (values, { setSubmitting }) => {
      setSubmitting(true)

      try {
        const utmLeads = utm.getAll() || {}
        const { phone, age, budget, salary } = values

        const result = await kronosService.setLeadsData({
            phone,
            budget: parseInt(values.isSalary ? salary : budget, 10),
            age,
            utm: utmLeads,
            category: InsuranceCategory.LIFE
          });

        // Add field value to cookie
        cookie.set(
          'QUOTE_FORM',
          JSON.stringify({ ...values, category: 'LIFE' }),
          {
            expires: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)
          }
        )

        utm.clear()

        if (!result.isExist)
          sendTracker(
            'event',
            'lead-submit',
            'quote-lead-submit',
            InsuranceCategory.LIFE
          )

        // await sendTapfiliate(phone)
      } catch (e) {
        console.log('Failed to send phone number')
      }

      // Clear compare
      cookie.remove('COMPARES')

      // Redirect
      Router.push({
        pathname: '/asuransi-jiwa/plans',
        query: values
      })
    }}
    render={({ values, errors, setFieldValue, isSubmitting }) => (
      <Form className="Quote--Form">
        <Field name="age">
          {({ field }) => (
            <FormGroup>
              <Label>
                <strong>Usia Anda</strong>
              </Label>
              <Input
                type="number"
                placeholder="Umur Anda"
                invalid={errors.age && true}
                min="21"
                max="60"
                {...field}
              />
              <FormFeedback>{errors.age}</FormFeedback>
            </FormGroup>
          )}
        </Field>

        <Field name="budget">
          {({ field }) => (
            <InputGroup className={values.isSalary ? 'disabled' : ''}>
              <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
              <Cleave
                placeholder="Anggaran Per-Bulan"
                options={{
                  numeral: true
                }}
                className={classNames(
                  'form-control',
                  errors.budget && 'is-invalid'
                )}
                {...field}
                disabled={values.isSalary}
                onChange={e => handleCurrencyInput(e, 'budget', setFieldValue)}
              />
              <FormFeedback>{errors.budget}</FormFeedback>
            </InputGroup>
          )}
        </Field>

        <Field name="isSalary">
          {({ field }) => (
            <FormGroup check className="mb-3 mt-1">
              <Label check className="checkbox">
                <Input type="checkbox" {...field} />
                <span>Saya tidak tahu anggaran saya</span>
              </Label>
            </FormGroup>
          )}
        </Field>

        {values.isSalary && (
          <Field name="salary">
            {({ field }) => (
              <InputGroup>
                <InputGroupAddon addonType="prepend">Rp</InputGroupAddon>
                <Cleave
                  placeholder="Pendapatan Per-Bulan"
                  options={{
                    numeral: true
                  }}
                  className={classNames(
                    'form-control',
                    errors.salary && 'is-invalid'
                  )}
                  {...field}
                  onChange={e =>
                    handleCurrencyInput(e, 'salary', setFieldValue)
                  }
                />
                <FormFeedback>{errors.salary}</FormFeedback>
              </InputGroup>
            )}
          </Field>
        )}

        <Field name="phone">
          {({ field }) => (
            <div className="input-group mt-3">
              <div className="input-group-prepend">
                <span
                  style={{
                    color: 'white',
                    backgroundColor: '#1d66dd',
                    border: 0
                  }}
                  className="input-group-text"
                >
                  +62
                </span>
              </div>
              <Cleave
                {...field}
                options={{
                  delimiter: ' ',
                  blocks: [3, 4, 4],
                  numericOnly: true
                }}
                className={classNames('form-control', {
                  'is-invalid': errors.phone
                })}
                placeholder="No. Telp"
                onChange={e => {
                  setFieldValue(`phone`, cleanPhoneNumber(e.target.value))
                }}
              />
              <FormFeedback>{errors.phone}</FormFeedback>
            </div>
          )}
        </Field>
        <div className="text-center mt-4">
          <Button color="primary" block type="submit" disabled={isSubmitting}>
            Tampilkan Pilihan
          </Button>
        </div>
      </Form>
    )}
  />
)

const Quote = ({ quoteForm }) => {
  const [type, setType] = useState('health')

  return (
    <div className="Quote">
      <div className="Quote--Tab">
        <button
          className={type === 'health' ? 'active' : ''}
          onClick={() => setType('health')}
        >
          Kesehatan
        </button>
        <button
          className={type === 'life' ? 'active' : ''}
          onClick={() => setType('life')}
        >
          Jiwa
        </button>
      </div>

      <div
        className="Quote--Content"
        id={type === 'health' ? 'HealthQuote' : 'LifeQuote'}
      >
        {type === 'health' ? (
          <QuoteFormHealth quoteForm={{ ...quoteForm }} />
        ) : (
            <QuoteFormLife quoteForm={{ ...quoteForm }} />
          )}
      </div>
    </div>
  )
}
export default Quote
