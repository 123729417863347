import { productsRest } from '../../client'
import qs from 'query-string'

export class Products {
  constructor(token) {
    this.token = token
  }

  async getPlans(planId, member, year, name, additionalBenefits, sumInsured, term, token) {
    const queryObj = {
      planId,
      ...member && { member },
      ...year && { year },
      ...name && { name },
      ...additionalBenefits && { additional_benefits: additionalBenefits },
      ...sumInsured && { sum_insured: Math.trunc(sumInsured) },
      ...term && { term },
    };

    return await productsRest(`/products/plans/?${qs.stringify(queryObj)}`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        ...(token && { Authorization: `bearer ${token}` }),
      },
    });
  }

  async getAdditionalBenefits(planId, year, name) {
    const queryObj = {
      planId,
      name,
      year,
    };

    return await productsRest(`/products/additional-benefits/?${qs.stringify(queryObj)}`, {
      method: 'GET',
    })
  }

  // @todo: VVI: Shift the query string making logic here rather than the components
  async getPlansCategory(payload) {
    const url = `/products/plans/?${payload}`

    return await productsRest(url, {
      method: 'GET',
    })
  }

  async getProduct(productId) {
    return await productsRest(`/products/${productId}/`, {
      method: 'GET',
    })
  }

  async getDeclaration(productId) {
    return await productsRest(`/products/${productId}/declarations/`, {
      method: 'GET',
    })
  }

  async getBrands(category) {
    return await productsRest(`/products/auto/brands/?category=${category}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    })
  }

  async getModels(brandId, category) {
    return await productsRest(`/products/auto/models/?${qs.stringify({ brandId, category })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    })
  }

  async getTypes(modelId) {
    return await productsRest(`/products/auto/types/?${qs.stringify({ modelId })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    })
  }

  async getYears(typeId) {
    return await productsRest(`/products/auto/years/?${qs.stringify({ typeId })}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      }
    })
  }
}
