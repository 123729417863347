import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Badge,
  Collapse,
  Button
} from 'reactstrap'
import Loader from './Loader'
import { idr } from '../shared/currency'
import map from 'lodash/map'
import dayjs from 'dayjs'
import 'dayjs/locale/id'
import cookie from 'js-cookie'

const statusLib = {
  INCOMPLETE: {
    status: 'Mengisi Pernyataan',
    action: 'Isi Pernyataan',
    type: 1,
  },
  PROCESSES: {
    status: 'Menunggu Pembayaran',
    action: 'Lakukan Pembayaran',
    type: 2,
  },
  NOT_PUBLISHED: {
    status: 'Belum Terbit',
    action: 'Lihat Informasi',
    type: 3,
  },
  POLICY_ISSUED: {
    status: 'Polis Saya',
    action: 'Lihat Polis',
    type: 4,
  }
};

// Application Cards
class Application extends PureComponent {
  static propTypes = {
    data: PropTypes.object,
    onAppSubmit: PropTypes.func,
    onAdditionalSubmit: PropTypes.func,
  }

  state = {
    isLoading: false
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true })
    const result = await this.props.onAppSubmit()
    if (result) this.setState({ isLoading: false })
  }

  render() {
    const {
      data,
      data: {
        status,
        productBrandName,
        productName,
        planName,
        totalAmount,
        name,
        quotationId,
      },
      onAdditionalSubmit
    } = this.props
    const { isLoading } = this.state

    const quotationDetails = JSON.parse(cookie.get(quotationId) || '{}');
    const premium = quotationDetails.premium
      ? idr(Number(quotationDetails.premium))
      : idr(Number(totalAmount));

    return (
      <div className="card-app">
        <header className="bg-dark text-white text-center">
          <h2>{productBrandName}</h2>
          <div style={{ textTransform: 'capitalize' }}>{name}</div>
        </header>

        <ul>
          <li>
            <dl>
              <dt>Status</dt>
              <dd className="text-warning">{statusLib[status].status}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Order Id</dt>
              <dd>{data.orderHumanId || '-'}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Nama Produk</dt>
              <dd>{productName}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Nama Plan</dt>
              <dd>{planName}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Premium</dt>
              <dd>{premium}</dd>
            </dl>
          </li>
        </ul>

        <div className="cta">
          <Button
            type="button"
            color="primary"
            block
            disabled={isLoading}
            onClick={this.handleSubmit}
          >
            {isLoading ? <Loader /> : statusLib[status].action}
          </Button>
          <Button
            type="button"
            outline
            color="primary"
            className="mt-2"
            block
            onClick={onAdditionalSubmit}
          >
            Batalkan Aplikasi
          </Button>
        </div>
      </div>
    )
  }
}

class Policy extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    onAppSubmit: PropTypes.func,
    onAdditionalSubmit: PropTypes.func,
    onHandleNextPayment: PropTypes.func
  }

  render() {
    const {
      item,
      item: {
        status,
        productBrandName,
        productName,
        planName,
        totalAmount,
        name,
        statusOrder,
      },
      onAdditionalSubmit,
      onHandleNextPayment
    } = this.props

    return (
      <div className="card-app">
        <header className="bg-dark text-white text-center">
          <h2>{productBrandName}</h2>
          <div style={{ textTransform: 'capitalize' }}>{name}</div>
        </header>

        <ul>
          <li>
            <dl>
              <dt>Status</dt>
              <dd>{statusLib[status].status}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Order Id</dt>
              <dd>{item.orderHumanId || '-'}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Nama Produk</dt>
              <dd>{productName}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Nama Plan</dt>
              <dd>{planName}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Premium</dt>
              <dd>{idr(Number(totalAmount))}</dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Tanggal Terbit</dt>
              <dd>{Object.keys(item.policy).length > 0
                ? dayjs(item.policy.issueDate)
                  .locale('id')
                  .format('DD MMM YYYY')
                : ''}
              </dd>
            </dl>
          </li>
          <li>
            <dl>
              <dt>Tanggal Berakhir</dt>
              <dd>{Object.keys(item.policy).length > 0
                ? dayjs(item.policy.expireDate)
                  .locale('id')
                  .format('DD MMM YYYY')
                : ''}
              </dd>
            </dl>
          </li>
        </ul>

        {/* CTA */}
        <div className="cta">
          {statusOrder === 'PARTIALLY_PAID' && (
            <Button
              type="button"
              color="primary"
              block
              className="mt-2"
              onClick={() => onHandleNextPayment(item.orderId)}
            >
              Pembayaran selanjutnya
              </Button>
          )}
          {item.hasOwnProperty('policy') && (
            <>
              {item.policy && item.policy.policyFile && (
                <Button
                  type="button"
                  outline
                  color="primary"
                  block
                  className="mt-2"
                  onClick={() => onAdditionalSubmit(item.policy.policyFile)}
                >
                  Lihat Ketentuan
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    )
  }
}

class Package extends PureComponent {
  static propTypes = {
    item: PropTypes.object,
    selectPolicy: PropTypes.func
  }

  state = {
    isOpened: false
  }

  toggleDetail = e => {
    e.preventDefault()
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }))
  }

  render() {
    const { isOpened } = this.state
    const { selectPolicy, item } = this.props
    return (
      <Card className="text-left mb-3">
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col xs={12} lg={6}>
                  <div>
                    <img
                      src={
                        item.logo
                          ? item.logo
                          : `/static/images/pa-plans/${item.brand}.png`
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <Badge color="gray">
                      <em>{item.plan}</em>
                    </Badge>
                  </div>
                </Col>
                <Col xs={12} lg={6} className="mt-3 mt-md-0">
                  <div>Premi/bulan</div>
                  <div style={{ fontSize: 'large', fontWeight: 500 }}>
                    {idr(Math.ceil(item.price / 12), true)}
                  </div>
                  <div>
                    <small>{idr(item.price, true)}/tahun</small>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col xs={12} lg={6}>
                  <div>Tanggungan Maks.</div>
                  <div
                    className="font-large-desktop"
                    style={{ fontWeight: 500 }}
                  >
                    {idr(item.maxCoverages, true)}
                  </div>
                  <div>
                    <a href="/" onClick={this.toggleDetail}>
                      Lihat Detail ↓
                    </a>
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <Button
                    onClick={selectPolicy}
                    color="primary"
                    size="sm"
                    className="d-none d-lg-block"
                    style={{ float: 'right' }}
                  >
                    Beli Polis
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <small>{item.summary}</small>
        </CardFooter>
        <Button
          className="d-block d-lg-none"
          onClick={selectPolicy}
          color="primary"
          block
          size="sm"
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        >
          Beli Polis
        </Button>

        {/* Detail */}
        <Collapse isOpen={isOpened}>
          <CardBody className="border-top">
            {/* Coverages */}
            {map(item.coverage, (row, key) => (
              <div key={key} className="card-coverages">
                <h5>{row.title}</h5>

                <ul>
                  {row.coverages.map((coverage, keyCoverage) =>
                    coverage !== 'x' ? (
                      <li
                        key={keyCoverage}
                        dangerouslySetInnerHTML={{ __html: coverage }}
                      />
                    ) : null
                  )}
                </ul>
              </div>
            ))}

            {/* If has file */}
            {item.spec && (
              <>
                <p style={{ color: '#444' }} className="mt-4">
                  Jika Anda membutuhkan penjelasan lebih detail, silahkan lihat
                  penjelasan di PDF berikut:
                </p>
                <Button
                  size="sm"
                  tag="a"
                  outline
                  color="primary"
                  href={item.spec}
                  target="_blank"
                >
                  Lihat PDF
                </Button>
              </>
            )}
          </CardBody>
        </Collapse>
      </Card>
    )
  }
}

const CardComponent = ({
  type,
  item,
  selectPolicy,
  onAppSubmit,
  onAdditionalSubmit,
  onHandleNextPayment,
  history,
}) => {
  return type !== 'package' ? (
    <Card body style={{ overflow: 'hidden', marginBottom: 20 }}>
      {type === 'application' && (
        <Application
          data={item}
          onAdditionalSubmit={onAdditionalSubmit}
          onAppSubmit={onAppSubmit}
        />
      )}
      {type === 'policy' && (
        <Policy
          item={item}
          onAppSubmit={onAppSubmit}
          onAdditionalSubmit={onAdditionalSubmit}
          onHandleNextPayment={onHandleNextPayment}
        />
      )}
    </Card>
  ) : (
      <Package item={item} selectPolicy={selectPolicy} />
    )
}

CardComponent.propTypes = {
  type: PropTypes.oneOf(['application', 'package', 'policy']),
  item: PropTypes.any,
  onAppSubmit: PropTypes.func,
  onAdditionalSubmit: PropTypes.func,
  onHandleNextPayment: PropTypes.func,
  selectPolicy: PropTypes.func
}

export default CardComponent
