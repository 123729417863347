import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Router from 'next/router'
import { Navbar, Container, Row, Col } from 'reactstrap'

export default class OrderNavbar extends React.Component {
  static propTypes = {
    activeRoute: PropTypes.string,
    _id: PropTypes.string,
    hasDeclaration: PropTypes.bool
  }

  static defaultProps = {
    activeRoute: 'holder',
    _id: '',
    hasDeclaration: true
  }

  renderLogo = status => {
    if (status === 'done') {
      return <i className="icon ion-md-checkmark done-icon" />
    }
    if (status === 'active') {
      return (
        <i className="spinner-border text-light active-icon" role="status" />
      )
    }
    return <i className="process-icon" />
  }

  changeRoute = (route, status) => {
    const { _id } = this.props
    if (route !== 'bayar' && status !== '' && status !== 'active') {
      let query = {}

      if (route === 'holder') {
        query.isEditing = true
      }

      Router.push({
        pathname: `/order/${_id}/${route}`,
        query
      })
    }
  }

  renderStep = (route, status, hasLine) => {
    const { hasDeclaration } = this.props
    let textDisplay = ''
    switch (route) {
      case 'holder':
        textDisplay = 'Penerima'
        break
      case 'declaration':
        textDisplay = 'Deklarasi'
        break
      case 'checkout':
        textDisplay = 'Checkout'
        break
      case 'bayar':
        textDisplay = 'Bayar'
        break
    }

    return (
      <Col xs={hasDeclaration ? 3 : 4}>
        {hasLine && (
          <div
            className={classNames('step-line', {
              done: status === 'done'
            })}
          />
        )}
        <span
          className={classNames('circle', {
            done: status === 'done',
            active: status === 'active',
            isBayar: route === 'bayar',
            notYet: status === ''
          })}
          onClick={() => this.changeRoute(route, status)}
        >
          {this.renderLogo(status)}
        </span>
        <br />
        <span
          className={classNames('text-indicator', {
            done: status === 'done',
            active: status === 'active'
          })}
        >
          {textDisplay}
        </span>
      </Col>
    )
  }

  render() {
    const { activeRoute, hasDeclaration } = this.props

    let holderRouteStatus = ''
    let declarationRouteStatus = ''
    let checkoutRouteStatus = ''
    let bayarRouteStatus = ''

    switch (activeRoute) {
      case 'holder':
        holderRouteStatus = 'active'
        break
      case 'declaration':
        holderRouteStatus = 'done'
        declarationRouteStatus = 'active'
        break
      case 'checkout':
        holderRouteStatus = 'done'
        declarationRouteStatus = 'done'
        checkoutRouteStatus = 'active'
        break
      case 'bayar':
        holderRouteStatus = 'done'
        declarationRouteStatus = 'done'
        checkoutRouteStatus = 'done'
        bayarRouteStatus = 'active'
        break
    }

    return (
      <Navbar className="orderNavbar" color="white" light expand={false}>
        <Container fluid className="p-2">
          <Col
            xs={{ size: 12 }}
            md={{ size: 6, offset: 3 }}
            className="text-center"
          >
            <Row>
              {this.renderStep('holder', holderRouteStatus, true)}
              {hasDeclaration &&
                this.renderStep('declaration', declarationRouteStatus, true)}
              {this.renderStep('checkout', checkoutRouteStatus, true)}
              {this.renderStep('bayar', bayarRouteStatus, false)}
            </Row>
          </Col>
        </Container>
      </Navbar>
    )
  }
}
