import React, { useEffect } from 'react';
import { lazyGetPostalCode } from '../../../services/address';
import { Dropdown } from '..'

export default function PostalCodeField(props) {
  const [getPostalCodes, { data: postalcodes}] = lazyGetPostalCode();

  const postalCodesOptions = postalcodes?.getPostalCodes.map(postalCode => {
    return {
      value: postalCode.postalCode,
      label: postalCode.postalCode
    }
  });

  useEffect(()=>{
    if(!props.subDistrictId) {
      return;
    }

    getPostalCodes({
      variables: {
        villageIds: props.subDistrictId
      }
    });
  }, [props.subDistrictId]);

  return (
    <Dropdown
      useRS
      options={postalCodesOptions || []}
      placeholder="- Kode Posx"
      {...props}
    />
  )
}
