import React from 'react';

import './TableData.scss';
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import classNames from 'classnames';
import TableColumn from './TableColumn';

const customTHead = () => ({
  style: {
    boxShadow: 'none',
  },
});

const generatePage = (currentPage, lastPage) => {
  const deltaRange = 2;
  const leftPage = currentPage - deltaRange;
  const rightPage = currentPage + deltaRange + 1;
  const range = [];
  const dotRange = [];
  let pages;

  range.push(1);
  for (let i = currentPage - deltaRange; i <= currentPage + deltaRange; i++) {
    if (i >= leftPage && i < rightPage && i < lastPage && i > 1) {
      range.push(i);
    }
  }
  range.push(lastPage);

  for (const i of range) {
    if (pages) {
      if (i - pages !== 1) {
        dotRange.push('&hellip;');
      }
    }
    dotRange.push(i);
    pages = i;
  }

  return dotRange;
};

const TableData = (props) => {
  const {
    data,
    pagination: {totalPage, currentPage},
    type,
    handlePagination,
    sendPolicy,
    loading,
    pageSize,
    rowSelection,
    phoneNumber,
    getCallHistoryList,
  } = props;
  const pagination = {
    totalPage: totalPage,
    hasPrevPage: currentPage >= 2,
    hasNextPage: currentPage !== totalPage,
    prevPage: currentPage - 1 ,
    nextPage: currentPage + 1,
    page: currentPage,
  };

  const pageRange = generatePage(pagination.page, pagination.totalPage);

  return (
    <div className="TableData">
      <ReactTable
        {...rowSelection ? { getTrProps: rowSelection } : {}}
        data={data}
        columns={TableColumn({ type, sendPolicy, loading, phoneNumber, getCallHistoryList })}
        className={'Wrapper'}
        getTheadProps={customTHead}
        showPageSizeOptions={false}
        showPageJump={false}
        defaultPageSize={pageSize || 10}
        PaginationComponent={() => {
          if (pagination.totalPage > 1) {
            return (
              <div className={'Pagination'}>
                <nav
                  className={'pagination'}
                  role="pagination"
                  aria-label="pagination"
                >
                  <ul className="pagination-list">
                    {pagination.hasPrevPage && (
                      <li>
                          <a
                            id="current__page"
                            onClick={() => handlePagination(pagination.prevPage)}
                            href="#"
                            className={classNames(
                              'pagination-link',
                              'PaginationLink'
                            )}
                            aria-label="Goto Next Page"
                          >
                            <i className="icon ion-ios-arrow-back" />
                          </a>
                      </li>
                    )}
  
                    {pageRange.map((row, index) => (
                      <li key={index}>
                        {row === '&hellip;' ? (
                          <span className={'PaginationDot'}>&hellip;</span>
                        ) : (
                            <a
                              onClick={() => handlePagination(row)}
                              href="#"
                              className={classNames(
                                'pagination-link',
                                'PaginationLink',
                                pagination.page === row && [
                                  'is-current',
                                  'PaginationCurrent',
                                ]
                              )}
                              aria-label={`Goto page ${row}`}
                            >
                              {row}
                            </a>
                          )}
                      </li>
                    ))}
  
                    {pagination.hasNextPage && (
                      <li>
                          <a
                            id="next__page"
                            onClick={() => handlePagination(pagination.nextPage)}
                            href="#"
                            className={classNames(
                              'pagination-link',
                              'PaginationLink'
                            )}
                            aria-label="Goto Next Page"
                          >
                            <i className="icon ion-ios-arrow-forward" />
                          </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
            );
          } else {
            return null;
          }
        }}
      />
    </div>
  )
}

export default TableData;