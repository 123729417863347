import React, { createRef } from 'react';

import {
  Row,
  Col
} from 'reactstrap';
import { yearList, monthList, dateList, getYearList } from '../../../lib/Date';
import dayjs from 'dayjs';
import './FormUser.sass';
import get from 'lodash/get';
import { Field } from 'formik';

import { getVillagesByDistrictId } from '../../../helper';
import { Input, Picker, Label, Dropdown, Field as FormField, Checkbox, ProvinceField, DistrictField } from '../../Form'
import ImageUpload from '../../ImageUpload';
import { monthlyIncomeList, sourceOfFundOptions } from 'clientUtils';
import PostalCodeField from '../../Form/AddressField/PostalCodeField';

class FormUser extends React.Component {
  state = {
    birthPlaceOptions: [],
    regenciesOptions: [],
    districtOptions: [],
    subDistrictOptions: [],
    isUploadingIdCard: false,
    isUploadingIdVerification: false,
    shouldMask: false,
    regencyId: null,
    subDistrictId: null,
  };

  componentDidMount = async () => {
    const { values } = this.props;
    const birthPlace = [];
    const birthPlaceOptions = birthPlace.map(each => ({
      label: each.name,
      value: each.name,
    }));
    this.setState({
      shouldMask: values.whatsappEqualNumber,
      birthPlaceOptions,
    });
  }

  handleCopy = (e) => {
    const { handleSameHolder, handleSameBuyer } = this.props;
    const { checked } = e.target;

    if (handleSameHolder) {
      handleSameHolder(checked);
    } else {
      handleSameBuyer(checked);
    }
  }

  handleProvinceChange = async (value, defaultValue, setFieldValue) => {
    const { handleRegenciesOptions } = this.props;

    if (defaultValue) {
      setFieldValue('city', '');
      setFieldValue('district', '');
      setFieldValue('village', '');
      setFieldValue('poscode', '');
    }

    // const regencies = await getRegenciesByProvinceName(value);
    const regencies = [];
    const regenciesOptions = regencies.map(each => ({
      label: each.name,
      value: each.id,
    }));

    this.setState({ regenciesOptions });

    // callback to parent
    handleRegenciesOptions(regenciesOptions);
  }

  handleRegencyChange = async (value, defaultValue, setFieldValue) => {
    if (defaultValue) {
      setFieldValue('district', '');
      setFieldValue('village', '');
      setFieldValue('poscode', '');
    }
  }

  handleDistrictChange = async (value, defaultValue, setFieldValue) => {
    const { handleSubDistrictOptions } = this.props;

    if (defaultValue) {
      setFieldValue('village', '');
      setFieldValue('poscode', '');
    }

    // TODO: removed by LT-3767 migrate to graphql getVillages - FormUser is not used anymore on Checkout Page
    const subDistrict = getVillagesByDistrictId(value);
    const subDistrictOptions = subDistrict.map((each) => ({
      label: each.name,
      value: each.id,
    }));

    this.setState({ subDistrictOptions });

    // calback to parent
    handleSubDistrictOptions(subDistrictOptions);
  }

  getPostalCode = async (value, defaultValue, setFieldValue) => {
    if (defaultValue) { setFieldValue('poscode', ''); }
  }

  prefillEntireForm = async (value) => {
    const { name, setFieldValue, fieldKey, isAgent } = this.props;

    if (value) {
      setFieldValue(name ? `${name}.personId` : 'personId', value.id);
      setFieldValue(name ? `${name}.fullName` : 'fullName', value.fullName);
      setFieldValue(name ? `${name}.gender` : 'gender', value.gender);
      setFieldValue(name ? `${name}.birthDate` : 'birthDate', dayjs(value.dob).format('DD'));
      setFieldValue(name ? `${name}.birthMonth` : 'birthMonth', dayjs(value.dob).format('MM'));
      setFieldValue(name ? `${name}.birthYear` : 'birthYear', dayjs(value.dob).format('YYYY'));
      setFieldValue(name ? `${name}.address` : 'address', value.address);
      setFieldValue(name ? `${name}.birthPlace` : 'birthPlace', value.birthPlace);
      setFieldValue(name ? `${name}.citizenship` : 'citizenship', value.citizenship);
      setFieldValue(name ? `${name}.rt` : 'rt', value.rt);
      setFieldValue(name ? `${name}.rw` : 'rw', value.rw);
      setFieldValue(name ? `${name}.province` : 'province', value.province);
      setFieldValue(name ? `${name}.city` : 'city', value.city);
      setFieldValue(name ? `${name}.district` : 'district', value.district);
      setFieldValue(name ? `${name}.village` : 'village', value.subDistrict);
      setFieldValue(name ? `${name}.poscode` : 'poscode', value.postalCode);
      setFieldValue(name ? `${name}.idType` : 'idType', value.idType);
      setFieldValue(name ? `${name}.idNumber` : 'idNumber', value.idNumber);
      setFieldValue(name ? `${name}.identityCardNumber` : 'identityCardNumber', value.identityCardNumber);
      setFieldValue(name ? `${name}.familyCardNumber` : 'familyCardNumber', value.familyCardNumber);
      setFieldValue(name ? `${name}.passportNumber` : 'passportNumber', value.passportNumber);
      setFieldValue(name ? `${name}.monthlyIncome` : 'monthlyIncome', value.monthlyIncome);
      setFieldValue(name ? `${name}.sourceOfFund` : 'sourceOfFund', value.sourceOfFund);

      if (fieldKey !== 'mainInsured' || isAgent) {
        setFieldValue(name ? `${name}.email` : 'email', value.email);
        setFieldValue(name ? `${name}.noEmail` : 'noEmail', !value.email);
        setFieldValue(name ? `${name}.phone` : 'phone', value.phoneNumber);
        setFieldValue(name ? `${name}.whatsapp` : 'whatsapp', value.waPhoneNumber);
        setFieldValue(name ? `${name}.whatsappEqualNumber` : 'whatsappEqualNumber', value.phoneNumber === value.waPhoneNumber);
      }
    } else {
      setFieldValue(name ? `${name}.personId` : 'personId', '');
      setFieldValue(name ? `${name}.address` : 'address', '');
      setFieldValue(name ? `${name}.birthPlace` : 'birthPlace', '');
      setFieldValue(name ? `${name}.rt` : 'rt', '');
      setFieldValue(name ? `${name}.rw` : 'rw', '');
      setFieldValue(name ? `${name}.province` : 'province', '');
      setFieldValue(name ? `${name}.city` : 'city', '');
      setFieldValue(name ? `${name}.district` : 'district', '');
      setFieldValue(name ? `${name}.village` : 'village', '');
      setFieldValue(name ? `${name}.poscode` : 'poscode', '');
      setFieldValue(name ? `${name}.idType` : 'idType', '');
      setFieldValue(name ? `${name}.identityCardNumber` : 'identityCardNumber', '');
      setFieldValue(name ? `${name}.familyCardNumber` : 'familyCardNumber', '');
      setFieldValue(name ? `${name}.passportNumber` : 'passportNumber', '');

      if (fieldKey !== 'mainInsured' || isAgent) {
        setFieldValue(name ? `${name}.email` : 'email', '');
        setFieldValue(name ? `${name}.noEmail` : 'noEmail', true);
        setFieldValue(name ? `${name}.phone` : 'phone', '');
        setFieldValue(name ? `${name}.whatsapp` : 'whatsapp', '');
        setFieldValue(name ? `${name}.whatsappEqualNumber` : 'whatsappEqualNumber', false);
      }
    }
  }

  setUploadingIdCard = (value) => this.setState({ isUploadingIdCard: value });

  setUploadingIdVerification = (value) => this.setState({ isUploadingIdVerification: value });

  render() {
    const {
      touched,
      errors,
      values,
      setFieldValue,
      name,
      fieldKey,
      hasOrderId,
      userOptions,
      itemIsLoading,
      isOtherPerson,
      holderFormStatus,
      buyerFormStatus,
      isAgent,
      buyerDisabled,
      isAuto,
      buyerEqualMainInsured,
      hasPrefilledNumber,
      token,
      useSimpleKyc,
    } = this.props;

    const {
      regenciesOptions,
      districtOptions,
      subDistrictOptions,
      postalCodeOptions,
      birthPlaceOptions,
      shouldMask,
    } = this.state;

    let personList = userOptions.length ? userOptions : [];
    if (fieldKey === 'policyHolder') {
      personList = personList.filter(person => get(person, 'data.age', 18) > 17);
    }

    return (
      <div className="FormUser">
        <p className="color-lp-red mb-4">* Wajib diisi</p>

        {(
          (!isAgent && !isOtherPerson && fieldKey !== 'mainInsured') ||
          (!isAgent && isOtherPerson && fieldKey !== 'buyer') ||
          (isAgent && fieldKey !== 'buyer')
        ) && (
            <Field name={name ? `${name}.selectedUser` : 'selectedUser'}>
              {({ field }) => (
                <FormField>
                  <Label>
                    Pilih Tertanggung{' '}
                    <span className="color-lp-red">*</span>
                  </Label>
                  <div className="custom-dropdown">
                    <Dropdown
                      isSearch={false}
                      useRS
                      id="userOptions"
                      options={personList}
                      placeholder="- Pilih Tertanggung"
                      error={''}
                      setFieldValue={setFieldValue}
                      getData={(value) => this.prefillEntireForm(value)}
                      zIndex={7}
                      disabled={hasOrderId}
                      {...field}
                    />
                  </div>
                </FormField>
              )}
            </Field>
          )}

        {(
          (!isAgent && !isOtherPerson && fieldKey === 'mainInsured') ||
          (!isAgent && isOtherPerson && fieldKey === 'buyer') ||
          (isAgent && fieldKey === 'buyer') ||
          values.selectedUser || get(values, `${name}.selectedUser`)
        ) && (
            <>
              <Field name={name ? `${name}.fullName` : 'fullName'}>
                {({ field }) => (
                  <FormField>
                    <Label>
                      Nama Lengkap{' '}
                      <span className="color-lp-red">*</span>
                    </Label>
                    <Input
                      text
                      {...field}
                      error={
                        (touched && touched.fullName && errors) && errors.fullName
                      }
                      disabled={hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus}
                    />
                  </FormField>
                )}
              </Field>

              <Field name={name ? `${name}.gender` : 'gender'}>
                {({ field }) => (
                  <FormField>
                    <Label>
                      Jenis Kelamin{' '}
                      <span className="color-lp-red">*</span>
                    </Label>
                    <Picker
                      value={field.value}
                      onChange={pickerValue =>
                        setFieldValue(name ? `${name}.gender` : 'gender', pickerValue)
                      }
                      options={[
                        {
                          name: 'gender',
                          value: 'MALE',
                          label: 'Pria',
                          disabled: hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus
                        },
                        {
                          name: 'gender',
                          value: 'FEMALE',
                          label: 'Wanita',
                          disabled: hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus
                        },
                      ]}
                      error={
                        (touched && touched.gender && errors) && errors.gender
                      }
                    />
                  </FormField>
                )}
              </Field>

              <FormField>
                <Label>
                  Tanggal Lahir{' '}
                  <span className="color-lp-red">*</span>
                </Label>
                <Row>
                  <Col sm={4} className="SmallMarginField">
                    <FormField>
                      <Field name={name ? `${name}.birthDate` : 'birthDate'}>
                        {({ field }) => (
                          <Dropdown
                            useRS
                            id="birthDate"
                            options={dateList}
                            placeholder="- Tanggal"
                            error={
                              (touched && touched.birthDate && errors) && errors.birthDate
                            }
                            callback={async (value) => {
                              setFieldValue(name ? `${name}.birthDate` : 'birthDate', value)
                            }}
                            zIndex={6}
                            disabled={hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus}
                            {...field}
                          />
                        )}
                      </Field>
                    </FormField>
                  </Col>

                  <Col sm={4} className="SmallMarginField">
                    <FormField>
                      <Field name={name ? `${name}.birthMonth` : 'birthMonth'}>
                        {({ field }) => (
                          <Dropdown
                            useRS
                            id="birthMonth"
                            options={monthList}
                            placeholder="- Bulan"
                            error={
                              (touched && touched.birthMonth && errors) && errors.birthMonth
                            }
                            callback={async (value) => {
                              setFieldValue(name ? `${name}.birthMonth` : 'birthMonth', value)
                            }}
                            zIndex={5}
                            disabled={hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus}
                            {...field}
                          />
                        )}
                      </Field>
                    </FormField>
                  </Col>

                  <Col sm={4} className="SmallMarginField">
                    <Field name={name ? `${name}.birthYear` : 'birthYear'}>
                      {({ field }) => (
                        <Dropdown
                          useRS
                          id="birthYear"
                          options={fieldKey === 'buyer' ? getYearList('adult') : yearList}
                          placeholder="- Tahun"
                          error={
                            (touched && touched.birthYear && errors) && errors.birthYear
                          }
                          callback={async (value) => {
                            setFieldValue(name ? `${name}.birthYear` : 'birthYear', value)
                          }}
                          zIndex={4}
                          disabled={hasOrderId || itemIsLoading || buyerDisabled || buyerFormStatus}
                          {...field}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>
              </FormField>

              {(!fieldKey || fieldKey === 'buyer') && (
                <>
                  <FormField>
                    <Row>
                      <Col sm={6} className="SmallMarginCol">
                        <Field name={name ? `${name}.phone` : 'phone'}>
                          {({ field }) => (
                            <FormField>
                              <Label>
                                Nomor Telepon{' '}
                                <span className="color-lp-red">*</span>
                              </Label>
                              <Input
                                phone
                                {...field}
                                error={(touched && touched.phone && errors) && errors.phone}
                                disabled={
                                  isAgent || hasPrefilledNumber || itemIsLoading || buyerDisabled || buyerFormStatus
                                }
                                placeholder="Nomor Telepon"
                                onChange={(e) => {
                                  setFieldValue(name ? `${name}.phone` : 'phone', e.target.value);

                                  if (values.whatsappEqualNumber) {
                                    setFieldValue(name ? `${name}.whatsapp` : 'whatsapp', e.target.value);
                                  }
                                }}
                                maskMobileNumber={isAgent}
                              />
                            </FormField>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </FormField>

                  {isAgent && (
                    <Field name={name ? `${name}.email` : 'email'}>
                      {({ field }) => (
                        <FormField>
                          <Label>
                            Email{' '}
                            <span className="color-lp-red">*</span>
                          </Label>
                          <Input
                            email
                            {...field}
                            error={(touched && touched.email && errors) && errors.email}
                            disabled={itemIsLoading || buyerDisabled || buyerFormStatus}
                          />
                        </FormField>
                      )}
                    </Field>
                  )}

                  {fieldKey !== 'buyer' && (
                    <>
                      <FormField>
                        <Row>
                          <Col>
                            <Field name={name ? `${name}.citizenship` : 'citizenship'}>
                              {({ field }) => (
                                <FormField>
                                  <Label>
                                    Kewarganegaraan{' '}
                                    <span className="color-lp-red">*</span>
                                  </Label>
                                  <Picker
                                    value={field.value}
                                    onChange={pickerValue => {
                                      setFieldValue(field.name, pickerValue);
                                      if (field.value && (field.value !== pickerValue)) {
                                        setFieldValue(name ? `${name}.birthPlace` : 'birthPlace', '');
                                      }
                                    }}
                                    options={[
                                      {
                                        name: 'citizenship',
                                        value: 'WNI',
                                        label: 'WNI',
                                        disabled: itemIsLoading || buyerDisabled
                                      },
                                      {
                                        name: 'citizenship',
                                        value: 'WNA',
                                        label: 'WNA',
                                        disabled: itemIsLoading || buyerDisabled
                                      },
                                    ]}
                                    error={
                                      (touched && touched.citizenship && errors) && errors.citizenship
                                    }
                                    disable={hasOrderId || itemIsLoading}
                                  />
                                </FormField>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </FormField>
                    </>
                  )}
                </>
              )}

              {fieldKey !== 'buyer' && (
                <Field name={name ? `${name}.birthPlace` : 'birthPlace'}>
                  {({ field }) => (
                    <FormField>
                      <Label>
                        Tempat Lahir{' '}
                        <span className="color-lp-red">*</span>
                      </Label>
                      {
                        values.citizenship === 'WNA'
                          ? <Input
                            text
                            {...field}
                            error={(
                              touched &&
                              touched.birthPlace &&
                              errors &&
                              errors.birthPlace
                            )}
                            disabled={buyerDisabled}
                          />
                          : <Dropdown
                            useRS
                            id="birthPlace"
                            options={birthPlaceOptions}
                            placeholder="- Pilih Tempat Lahir"
                            error={(
                              touched &&
                              touched.birthPlace &&
                              errors &&
                              errors.birthPlace
                            )}
                            setFieldValue={setFieldValue}
                            zIndex={3}
                            disabled={itemIsLoading || buyerDisabled}
                            {...field}
                          />
                      }
                    </FormField>
                  )}
                </Field>
              )}

              <>
                <Field name={name ? `${name}.${values.identityCardNumber ? 'identityCardNumber' : 'passportNumber'}` : `${identityCardNumber ? 'identityCardNumber' : 'passportNumber'}`}>
                  {({ field }) => (
                    <FormField>
                      <Label>
                        Nomor { values.identityCardNumber ? 'KTP' : values.passportNumber ? 'Passport' : 'Identitas' }
                        <span className="color-lp-red">*</span>
                      </Label>
                      <Input
                        text
                        error={values.identityCardNumber ? ((touched && touched.identityCardNumber && errors) && errors.identityCardNumber) : ((touched && touched.passportNumber && errors) && errors.passportNumber)}
                        disabled={itemIsLoading || buyerDisabled}
                        {...field}
                      />
                    </FormField>
                  )}
                </Field>
                {
                  !useSimpleKyc && (
                    <>
                      <ImageUpload
                        token={token}
                        label={`Foto Anda Memegang ${values.idType}`}
                        fieldName="buyer.idVerificationPicture"
                        placeholder="/static/images/placeholder/id_verification.svg"
                        setFieldValue={setFieldValue}
                        errors={{ buyer: errors }}
                        touched={{ buyer: touched }}
                      />
                      <ImageUpload
                        token={token}
                        label={`Foto ${values.idType}`}
                        fieldName="buyer.idFile"
                        placeholder="/static/images/placeholder/id_card_file.svg"
                        setFieldValue={setFieldValue}
                        errors={{ buyer: errors }}
                        touched={{ buyer: touched }}
                      />
                    </>
                  )
                }

                {fieldKey === 'buyer' && (
                  <>
                    <Field name={name ? `${name}.monthlyIncome` : 'monthlyIncome'}>
                      {({ field }) => (
                        <FormField>
                          <Label>
                            Besar Penghasilan Dalam Sebulan{' '}
                            <span className="color-lp-red">*</span>
                          </Label>
                          <Input
                            value={get(monthlyIncomeList.find(item => item.value === field.value), "label", field.value)}
                            error={touched && touched.monthlyIncome && errors && errors.monthlyIncome}
                            placeholder="Penghasilan dalam satu bulan"
                            disabled={buyerDisabled}
                          />
                        </FormField>
                      )}
                    </Field>
                    <Field name={name ? `${name}.sourceOfFund` : 'sourceOfFund'}>
                      {({ field }) => (
                        <FormField>
                          <Label>
                            Sumber Penghasilan{' '}
                            <span className="color-lp-red">*</span>
                          </Label>
                          <Input
                            text
                            value={get(sourceOfFundOptions.find(item => item.value === field.value), "label", field.value)}
                            error={touched && touched.sourceOfFund && errors && errors.sourceOfFund}
                            placeholder="Sumber penghasilan"
                            disabled={buyerDisabled}
                          />
                        </FormField>
                      )}
                    </Field>
                  </>
                )}

                <Field name={name ? `${name}.address` : 'address'}>
                  {({ field }) => (
                    <FormField>
                      <Label>
                        Nama Jalan{' '}
                        <span className="color-lp-red">*</span>
                      </Label>
                      <Input
                        text
                        {...field}
                        error={(touched && touched.address && errors) && errors.address}
                        disabled={itemIsLoading || buyerDisabled}
                      />
                    </FormField>
                  )}
                </Field>

                <FormField>
                  <Row>
                    <Col xs={6} sm={2}>
                      <Field name={name ? `${name}.rt` : 'rt'}>
                        {({ field }) => (
                          <>
                            <Label>RT</Label>
                            <Input
                              text
                              {...field}
                              error={(touched && touched.rt && errors) && errors.rt}
                              disabled={itemIsLoading || buyerDisabled}
                            />
                          </>
                        )}
                      </Field>
                    </Col>

                    <Col xs={6} sm={2}>
                      <Field name={name ? `${name}.rw` : 'rw'}>
                        {({ field }) => (
                          <>
                            <Label>RW</Label>
                            <Input
                              text
                              {...field}
                              error={(touched && touched.rw && errors) && errors.rw}
                              disabled={itemIsLoading || buyerDisabled}
                            />
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </FormField>

                <FormField>
                  <Row>
                    <Col sm={6} className="SmallMarginCol">
                      <Field name={name ? `${name}.province` : 'province'}>
                        {({ field }) => (
                          <>
                            <Label>
                              Provinsi{' '}
                              <span className="color-lp-red">*</span>
                            </Label>
                            <ProvinceField
                              id="province"
                              callback={(value) => this.handleProvinceChange(value, values.province, setFieldValue)}
                              error={(touched && touched.province && errors) && errors.province}
                              setFieldValue={setFieldValue}
                              zIndex={9}
                              disabled={itemIsLoading || buyerDisabled}
                              {...field}
                            />
                          </>
                        )}
                      </Field>
                    </Col>

                    <Col sm={6}>
                      <Field name={name ? `${name}.city` : 'city'}>
                        {({ field }) => (
                          <>
                            <Label>
                              Kota{' '}
                              <span className="color-lp-red">*</span>
                            </Label>
                            <Dropdown
                              useRS
                              id="city"
                              options={regenciesOptions}
                              placeholder="- Kota"
                              error={(touched && touched.city && errors) && errors.city}
                              callback={(value) => {
                                const { regenciesOptions } = this.state;
                                const cities = regenciesOptions.filter(d => d.value === value);
                                const city = cities[0].label;
                                setFieldValue(name ? `${name}.city` : 'city', city);
                                this.handleRegencyChange(value, values.city, setFieldValue)
                                this.setState({regencyId: value});

                              }}
                              zIndex={8}
                              disabled={itemIsLoading || buyerDisabled}
                              {...field}
                            />
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </FormField>

                <FormField>
                  <Row>
                    <Col sm={6} className="SmallMarginCol">
                      <Field name={name ? `${name}.district` : 'district'}>
                        {({ field }) => (
                          <>
                            <Label>
                              Kecamatan{' '}
                              <span className="color-lp-red">*</span>
                            </Label>
                            <DistrictField
                              useRS
                              id="district"
                              regencyId={this.state.regencyId}
                              error={(touched && touched.district && errors) && errors.district}
                              callback={(value, district) => {
                                setFieldValue(name ? `${name}.district` : 'district', district.label);
                                this.handleDistrictChange(value, values.district, setFieldValue)
                              }}
                              zIndex={7}
                              disabled={itemIsLoading || buyerDisabled}
                              {...field}
                            />
                          </>
                        )}
                      </Field>
                    </Col>

                    <Col sm={6}>
                      <Field name={name ? `${name}.village` : 'village'}>
                        {({ field }) => (
                          <>
                            <Label>
                              Kelurahan{' '}
                              <span className="color-lp-red">*</span>
                            </Label>
                            <Dropdown
                              useRS
                              id="village"
                              options={subDistrictOptions}
                              placeholder="- Kelurahan"
                              error={(touched && touched.village && errors) && errors.village}
                              callback={(value) => {
                                const { subDistrictOptions } = this.state;
                                const villages = subDistrictOptions.filter(d => d.value === value);
                                const village = villages[0].label;
                                setFieldValue(name ? `${name}.village` : 'village', village);
                                this.setState({subDistrictId: value});
                              }}
                              zIndex={6}
                              disabled={itemIsLoading || buyerDisabled}
                              {...field}
                            />
                          </>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </FormField>

                <Field name={name ? `${name}.poscode` : 'poscode'}>
                  {({ field }) => (
                    <FormField>
                      <Row>
                        <Col sm={6} className="SmallMarginCol">
                          <Label>
                            Kode Pos{' '}
                            <span className="color-lp-red">*</span>
                          </Label>
                          <PostalCodeField
                            useRS
                            subDistrictId={this.state.subDistrictId}
                            id="poscode"
                            error={(touched && touched.poscode && errors) && errors.poscode}
                            setFieldValue={setFieldValue}
                            disabled={!values.village || itemIsLoading || buyerDisabled}
                            zIndex={5}
                            {...field}
                          />
                        </Col>
                      </Row>
                    </FormField>
                  )}
                </Field>
              </>
              {(
                !buyerDisabled && !isAgent && isOtherPerson && fieldKey === 'mainInsured' && values.selectedUser ||
                !buyerDisabled && isAgent && isOtherPerson && fieldKey === 'mainInsured' ||
                !buyerDisabled && isAgent && !isOtherPerson && fieldKey === 'buyer'
              ) && (
                  <div className="polisHolderCheckbox">
                    <Checkbox
                      onChange={e => this.handleCopy(e)}
                      {...holderFormStatus ? { checked: holderFormStatus } : {}}
                      {...buyerEqualMainInsured ? { checked: buyerEqualMainInsured } : {}}
                    >
                      {!isAgent
                        ? 'Tertanggung berbeda dengan Pemegang Polis'
                        : fieldKey === 'buyer'
                          ? isAuto ? 'Pembeli adalah Pemegang Polis' : 'Pembeli adalah Tertanggung'
                          : 'Tertanggung berbeda dengan Pemegang Polis'
                      }
                    </Checkbox>
                  </div>
                )}
            </>
          )}
      </div >
    )
  }
}

export default FormUser
