import React, { useEffect, useState } from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { isEqual } from 'lodash';

import { TableData } from '../../components';

import { formatMobileNumber } from '../../lib/phoneNumber';
import { usePrevious } from 'clientUtils';

import './CallListView.module.scss';

export const CallListView = (props) => {

	const { title, callList, getCallHistoryList, shouldOpenModal, toggleModal } = props;
	const [currentPage, setCurrentPage] = useState(1);
	const [paginatedItems, setPaginatedItems] = useState([]);

	const previousCallList = usePrevious(callList);

	const callRecordingSelection = (_, rowInfo) => {
		if (rowInfo) {
			let background = '#ffffff';
			if (rowInfo.index % 2 === 0) background = '#f7faff';
			return {
				style: { background },
			};
		}
		return {};
	};

	useEffect(() => {
		if (callList.length) {
			setPaginatedItems(callList);
		}
	}, []);

	useEffect(() => {
		if (!isEqual(previousCallList, callList) && currentPage > 1) {
			setPaginatedItems(callList.slice((currentPage - 1) * 5, currentPage * 5));
		}
	}, [callList]);

	return (
		<Modal
			isOpen={shouldOpenModal}
			toggle={toggleModal}
			key="call__list--modal"
			className="call__list--modal"
			size="md"
			unmountOnClose
		>
			<ModalHeader toggle={toggleModal} className="heading-lg-b">
				{formatMobileNumber(title)}
			</ModalHeader>
			<ModalBody>
				<TableData
					type="recordingList"
					pageSize={5}
					data={paginatedItems}
					pagination={{
						totalPage: Math.ceil((callList.length / 5)),
						currentPage,
					}}
					handlePagination={(page) => {
						setPaginatedItems(callList.slice((page - 1) * 5, page * 5));
						setCurrentPage(page);
					}}
					rowSelection={callRecordingSelection}
					phoneNumber={formatMobileNumber(title)}
					getCallHistoryList={getCallHistoryList}
				/>
			</ModalBody>
		</Modal>
	)
};
