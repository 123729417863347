import { get } from 'lodash';
import React from 'react';

import { idr } from '../../../../../shared/currency';

import './AgentCommission.module.scss';

const AgentCommission = ({
  plan,
  role,
  totalPremium,
  paymentTerm,
  agentCommissionPercentage,
  isPlanListing
}) => {

  const getAgentCommission = (productCommission) => {
    let total = productCommission * totalPremium;

    if (agentCommissionPercentage) {
      total = total * (agentCommissionPercentage / 100);
    }

    return idr(total);
  };

  const agentPoint = Math.ceil(get(plan, 'product.points', 0) * totalPremium / 1000);

  if (isPlanListing && role.showCommission && plan && plan.product && plan.product.commission) {
    return (
      <>
        <span className="agent__commission heading-xs-r">
          *Komisi hingga <strong className="agent__commission--bold">{getAgentCommission(plan.product.commission )}{agentPoint > 0 && ` + ${agentPoint} poin`} (per {paymentTerm.term})</strong>
          <span className="font-style-italic">
            {paymentTerm.due}
          </span>
        </span>
      </>
    )
  }

  if (role.showCommission && plan && plan.product && plan.product.commission) {
    return (
      <span className="agent__commission heading-xs-r">
        *Komisi hingga <strong className="agent__commission--bold">{getAgentCommission(plan.product.commission )}{agentPoint > 0 && ` + ${agentPoint} poin`} (per {paymentTerm.term})</strong>
      </span>
    );
  }

  return (<></>);
};

export default AgentCommission;
