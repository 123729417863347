import React from 'react';

import './UserInfoTitle.sass';

const UserInfoTitle = ({ title }) => (
  <div className="user__info--container">
    <div className='user__info--title-box'>
      <h2>{title}</h2> 
    </div>
  </div>
);

export default UserInfoTitle;
