import React from 'react'
import './ModalV1.sass'
import classNames from 'classnames'

const ModalV1 = props => {
  return (
    <div
      className={classNames('InsuranceModalV1', {
        'InsuranceModalV1--isOpened': props.isOpened
      })}
    >
      <div className="InsuranceModalV1--Container">
        <div>
          <h2 className="InsuranceModalV1--Header">{props.mainTitle}</h2>
          <div className="InsuranceModalV1--Body">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default ModalV1
